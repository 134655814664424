import styled, { css } from 'styled-components';

export const Container = styled.div`
  overflow: hidden;
  height: calc(100vh - 56px);
  overflow-y: auto;
  overflow-x: hidden;
  @media (max-width: 1024px) {
    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none; /* For Internet Explorer and Edge */
    ::-webkit-scrollbar {
      display: none; /* For Chrome, Safari and Opera */
    }
  }
`;

export const Header = styled.div`
  background-color: var(--color-primary-2);
  height: 68px;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2;
`;

export const Text = styled.div`
  color: var(--color-neutral-2);
  font-family: 'Nunito-Bold', Helvetica;
  font-size: 28px;
  font-weight: 700;
  left: 23px;
  letter-spacing: -0.17px;
  line-height: 18px;
  position: fixed;
  text-align: center;
  top: 25px;
  white-space: nowrap;
`;

export const GroupMessages = styled.div`
  position: relative;
  top: 93px;
  width: 340px;
  margin-inline: auto;
  overflow: hidden;
`;

export const Button = styled.div<{ hide: boolean }>`
  display: block;
  height: 33px;
  padding: 10px;
  margin: 20px;
  background-color: var(--color-primary-2);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 340px;
  text-align: center;
  font-weight: 600;
  font-family: 'Nunito', sans-serif;
  margin-inline: auto !important;
  ${({ hide }) =>
    hide &&
    css`
      display: none !important;
  `}
`;
