import React from 'react';
import MotoSVG from 'assets/images/Moto.svg';
import { Pill } from 'components';
import { Container, MenuContent, Moto } from './styles';

const Home: React.FC = () => {
  return (
    <Container>
      <Moto draggable="false" src={MotoSVG} alt="moto" />
      <MenuContent>
        <Pill imageKey="Church" title="Nossa Igreja" text="" />
        <Pill imageKey="Medias" title="Redes sociais" text="" />
        <Pill imageKey="Events" title="Eventos" text="" />
        <Pill imageKey="Records" title="Gravações" text="" />
        <Pill imageKey="Communion" title="Comunhão" text="" />
        <Pill imageKey="Prayer" title="Pedidos de Oração" text="" />
        <Pill imageKey="Bible" title="Biblia Sagrada" text="" />
        <Pill imageKey="Confession" title="Confissão de Fé" text="" />
      </MenuContent>
    </Container>
  );
};

export default Home;
