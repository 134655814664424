import React, { useEffect, useState } from 'react';
import { useAuth } from 'providers/AuthProvider';
import { 
  PrayerRequestLabel,
  PrayerRequestTitle,
  PrayerRequestButton,
  PrayerRequestForm,
  PrayerRequestInput,
  PrayerRequestDescription,
  MessageText
} from './styles';
import GoogleFormResponses from 'components/FormResponse';

const Prayer: React.FC = () => {
    const { currentUser, isAdmin } = useAuth();

    const [formData, setFormData] = useState({
      name: '',
      email: '',
      contact: '',
      request: '',
      return: false,
    });

    useEffect(() => {
      if (currentUser) {
        setFormData({ ...formData, name: currentUser.displayName, email: currentUser.email });
      }
    }, []);
  
    const handleChange = e => {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
    
      const fieldMappings = {
        name: 'entry.854778651',
        email: 'entry.2104248984',
        contact: 'entry.782196815',
        request: 'entry.1739609454',
        return: 'entry.1383847095',
      };
    
      const url = `https://docs.google.com/forms/d/e/${process.env.REACT_APP_GOOGLE_FORMS}/formResponse`;
      
      // Create a new FormData instance
      const formBody = new FormData();

      // Map formData to Google Form field mappings
      for (const field in formData) {
        if (formData.hasOwnProperty(field) && fieldMappings.hasOwnProperty(field)) {
          if (field === 'return') {
            if (formData.return) formBody.append(fieldMappings[field], 'Retornar');
          } else formBody.append(fieldMappings[field], formData[field]);
        }
      }

      try {
        const response = await fetch(url, {
          method: 'POST',
          body: formBody,
          mode: 'no-cors',
        });
        console.log('Form successfully submitted');
        alert("Seu pedido foi registrado!");
      } catch (error) {
        console.error('Error submitting form', error);
      }
    };    
  
    return (
      !isAdmin ? (
        <PrayerRequestForm onSubmit={handleSubmit}>
          <PrayerRequestTitle>Precisando de oração?</PrayerRequestTitle>
          <MessageText>Preencha o formulário abaixo para enviar seu pedido de oração e um dos nossos Ministros de Oração estará orando por você.</MessageText>
          <PrayerRequestLabel>Nome</PrayerRequestLabel>
          <PrayerRequestInput
            type="text"
            name="name"
            placeholder="Nome"
            value={formData.name}
            onChange={handleChange}
            required
          />
          <PrayerRequestLabel>Email</PrayerRequestLabel>
          <PrayerRequestInput
            type="email"
            name="email"
            placeholder="Email"
            value={formData.email}
            onChange={handleChange}
            required
          />
          <PrayerRequestLabel>Contato</PrayerRequestLabel>
          <PrayerRequestInput
            type="text"
            name="contact"
            placeholder="Contato"
            value={formData.contact}
            onChange={handleChange}
            required
          />
          <PrayerRequestLabel>Pedido</PrayerRequestLabel>
          <PrayerRequestDescription
            name="request"
            placeholder="Pedido"
            value={formData.request}
            onChange={handleChange}
            required
          />
          <PrayerRequestLabel>Retorno</PrayerRequestLabel>
          <div style={{ display: 'flex', justifyContent: 'space-between', paddingTop: '10px' }}>
            <MessageText>
              Gostaria que alguém entrasse em contato?
            </MessageText>
            <input 
              style={{ width: '18px', height: '18px' }}
              type='checkbox'
              onChange={() => setFormData({ ...formData, return: !formData.return })}
            />
          </div>
          <PrayerRequestButton crud={false} type="submit">Enviar Pedido</PrayerRequestButton>
        </PrayerRequestForm>
      ) : (<GoogleFormResponses />)
    );
};

export default Prayer;
