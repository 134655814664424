import React, { useState } from "react";
import { LoadScript, GoogleMap, Marker, InfoWindow } from "@react-google-maps/api";
import { Card, CardContent, Typography, Divider } from "@mui/material";

const Map: React.FC = () => {
  const mapStyles = {
    height: "400px",
    width: "100%",
  };

  const defaultCenter = {
    lat: -18.913664,
    lng: -48.266560,
  };

  const [open, setOpen] = useState(false);

  const mapsApi = process.env.REACT_APP_PLACES_API_KEY;

  return (
    mapsApi && (
      <LoadScript googleMapsApiKey={mapsApi}>
        <GoogleMap
          mapContainerStyle={mapStyles}
          zoom={11}
          center={defaultCenter}
        >
          <Marker
            position={{ lat: -18.905640, lng: -48.270210 }}
            onClick={() => setOpen(true)}
          />

          {open && (
            <InfoWindow
              position={{ lat: -18.905640, lng: -48.270210 }}
              onCloseClick={() => setOpen(false)}
            >
              <Card>
                <CardContent>
                  <Typography component="h3" gutterBottom>
                    <b>Igreja Batista Central de Uberlândia</b>
                  </Typography>
                  <Typography variant="body2" color="textSecondary" gutterBottom>
                    Endereço: <br />
                      <a href="https://www.google.com/maps/search/?api=1&query=-18.905640,-48.270210" target="_blank">
                          Av. João Pinheiro, 2001 - Nossa Sra. Aparecida, Uberlândia - MG, 38400-712
                      </a>
                  </Typography>
                  <Typography variant="body2" color="textSecondary" gutterBottom>
                    Contato: <br /> (34) 3212-4305
                  </Typography>
                  <Typography variant="body2" color="textSecondary" gutterBottom>
                    Horário: <br /> 09:00–11:30, 18:30–20:30 (Domingos)
                  </Typography>
                  <Divider />
                  <Typography variant="body2" color="textSecondary" gutterBottom>
                    Uma igreja batista confessional filiada à Convenção Batista Brasileira
                  </Typography>
                </CardContent>
              </Card>
            </InfoWindow>
          )}
        </GoogleMap>
      </LoadScript>
    )
  );
};

export default Map;
