import styled, { css } from 'styled-components';

export const Container = styled.div`
  overflow: hidden;
  height: calc(100vh - 56px);
  @media (max-width: 1024px) {
    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none; /* For Internet Explorer and Edge */
    ::-webkit-scrollbar {
      display: none; /* For Chrome, Safari and Opera */
    }
  }
  .MuiModal-root {
    top: 204px !important;
    overflow-y: scroll !important;
  }
`;

export const SliderWrapper = styled.div`
  max-width: 1120px;
  width: 100%;
  margin: auto;
  overflow: hidden;
  position: relative;
`;

export const CardsContainer = styled.div<{ top?: string }>`
  position: relative;
  margin-top: ${({ top }) => top || '20px'};
  margin-inline: calc(50% - 175px);
  width: 100%;
  height: calc(100% - 68px);
  overflow-y: auto;
`;

export const Card = styled.div`
  width: 350px;
  height: 300px;
  position: relative;
  background-color: #ffffff;
  border-radius: 16px;
  box-shadow: 0px 8px 20px #8163d626;
  margin-bottom: 25px;
`;

export const Header = styled.div`
  background-color: var(--color-primary-2);
  height: 68px;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2;
`;

export const Text = styled.div`
  color: var(--color-neutral-2);
  font-family: 'Nunito-Bold', Helvetica;
  font-size: 28px;
  font-weight: 700;
  left: 23px;
  letter-spacing: -0.17px;
  line-height: 18px;
  position: fixed;
  text-align: center;
  top: 25px;
  white-space: nowrap;
`;

export const Name = styled.h2`
  color: #000000;
  font-family: 'Poppins-SemiBold', Helvetica;
  letter-spacing: -0.17px;
  line-height: 18px;
  text-align: center;
  position: relative;
  top: 15px;
  margin-bottom: 25px;
  height: 20px;
  width: 350px;
`;

export const Section = styled.section`
  position: relative;
  width: 100%;
  height: 100%;
  padding-inline: 30px;
  margin-inline: auto;
  top: 15px;
`;

export const Title = styled.h3`
  color: #000000;
  font-family: 'Poppins-SemiBold', Helvetica;
  letter-spacing: -0.17px;
  line-height: 18px;
  text-align: center;
`;

export const Div = styled.div`
  width: 300px;
  display: inline-flex;
  justify-content: space-between;
`;

export const P = styled.p<{ weight: string }>`
  color: #484848;
  font-family: 'Nunito-Regular', Helvetica;
  font-size: 16px;
  font-weight: ${({ weight }) => weight || '400'};
  letter-spacing: -0.41px;
  line-height: 22px;
`;

export const Button = styled.div<{ hide: boolean; top?: string }>`
  display: block;
  height: 33px;
  padding: 10px;
  margin-top: ${({ top }) => top || '20px'};
  margin-bottom: 20px;
  background-color: var(--color-primary-2);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 340px;
  text-align: center;
  font-weight: 600;
  font-family: 'Nunito', sans-serif;
  margin-inline: auto !important;
  ${({ hide }) =>
    hide &&
    css`
      display: none !important;
  `}
`;
