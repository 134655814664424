import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: calc(100vh - 56px);
  overflow: hidden;
  @media (max-width: 1024px) {
    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none; /* For Internet Explorer and Edge */
    ::-webkit-scrollbar {
      display: none; /* For Chrome, Safari and Opera */
    }
  }
`;

export const Content = styled.div`
  height: calc(100% - 68px);
  width: 100%;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: 68px;
`;


export const Header = styled.div`
  background-color: var(--color-primary-2);
  height: 68px;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2;
`;

export const Text = styled.div`
  color: var(--color-neutral-2);
  font-family: 'Nunito-Bold', Helvetica;
  font-size: 28px;
  font-weight: 700;
  left: 23px;
  letter-spacing: -0.17px;
  line-height: 18px;
  position: fixed;
  text-align: center;
  top: 25px;
  white-space: nowrap;
`;

export const Month = styled.h2`
  color: #000000;
  font-family: 'Poppins-SemiBold', Helvetica;
  letter-spacing: -0.17px;
  line-height: 18px;
  text-align: center;
  position: relative;
  top: 35px;
  margin-bottom: 25px;
  height: 20px;
  width: 100%;
`;
