import React, { useState, useEffect } from 'react';

export interface Local {
  address: string;
  latitude: number;
  longitude: number;
}

export interface SelectEventData {
  location: Local;
  placeName: string;
}


const LocationAutocomplete: React.FC<{ initialValue?: Local, onSelect: (data: SelectEventData) => void; }> = ({ onSelect, initialValue }) => {
  const [input, setInput] = useState('');
  const [placeholder, setPlaceholder] = useState('Digite para pesquisar o local...');
  const [suggestions, setSuggestions] = useState<any>([]);
  const [placesService, setPlacesService] = useState<any>(null);

  useEffect(() => {
    if (initialValue && initialValue.address) {
      setInput(initialValue.address);
    }
  }, [initialValue]);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_PLACES_API_KEY}&libraries=places`;
    script.async = true;
    script.defer = true;
    document.head.appendChild(script);

    script.onload = () => {
      console.log('Google Maps API script loaded');
      setPlacesService(new window.google.maps.places.PlacesService(document.createElement('div')));
    };

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  useEffect(() => {
    if (input && window.google && window.google.maps && window.google.maps.places) {
      const autocompleteService = new window.google.maps.places.AutocompleteService();
      autocompleteService.getPlacePredictions({ input }, (predictions, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK && predictions) {
          setSuggestions(predictions);
        } else {
          setSuggestions([]);
        }
      });
    } else {
      setSuggestions([]);
    }
  }, [input]);

  const handleChange = (event) => {
    setInput(event.target.value);
  };

  const handleSelect = (placeId) => {
    if (placesService) {
      placesService.getDetails({ placeId }, (place, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK && place) {
          const locationData: Local = {
            address: place.formatted_address,
            latitude: place.geometry.location.lat(),
            longitude: place.geometry.location.lng(),
          };
          const placeName: string = place.name;
          setInput(placeName);
          
          onSelect({
            location: locationData,
            placeName: placeName,
          });

          console.log({
            location: locationData,
            placeName: placeName,
          });
        }
      });
    }
    setInput('');
    setSuggestions([]);
  };
  

  return (
    <div style={{ width: '100%' }}>
      <input
        type="text"
        value={input}
        onChange={handleChange}
        placeholder={placeholder}
        style={{
          width: '100%',
          height: '40px',
          padding: '10px',
          borderRadius: '5px',
          color: '#2a2b31',
          border: '1px solid #ced4da',
        }}
      />
      <ul style={{
        width: '100%',
        borderRadius: '5px',
        border: '1px solid #ced4da',
        marginTop: '5px',
        opacity: '0.8',
        color: '#2a2b31',
        listStyleType: 'none',
      }}>
        {suggestions.map((suggestion) => (
          <li
            key={suggestion.place_id}
            onClick={() => handleSelect(suggestion.place_id)}
            style={{
              padding: '10px',
              border: '1px solid #ced4da',
            }}
          >
            {suggestion.description}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default LocationAutocomplete;
