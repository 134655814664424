import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const Frame = styled.iframe`
  width: 100%;
  overflow: hidden;
  height: calc(100vh - 96px);
  margin-top: 40px; // 37px;
`;

export const Navbar = styled.div`
  overflow: hidden;
  background-color: #333;
  color: var(--color-neutral-2);
  text-align: center;
  font-family: 'Nunito', sans-serif;
  font-size: 28px;
  padding: 5px;
  position: fixed;
  top: 0;
  width: 100%;
  height: 40px;
  cursor: pointer;
`;

export const Overlay = styled.div<{ isOpen: boolean }>`
  height: ${({ isOpen }) => (isOpen ? '100%' : '0%')};
  width: 100%;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0, 0.9);
  overflow-y: hidden;
  transition: 0.5s;
`;

export const OverlayContent = styled.div`
  position: relative;
  top: 25%;
  width: 100%;
  text-align: center;
  margin-top: 30px;
`;

export const StyledLink = styled.a`
  padding: 8px;
  text-decoration: none;
  font-size: 36px;
  color: #818181;
  display: block;
  transition: 0.3s;
  font-family: 'Nunito', sans-serif;

  &:hover,
  &:focus {
    color: #f1f1f1;
  }
`;

export const CloseButton = styled.a`
  position: absolute;
  top: 20px;
  right: 45px;
  font-size: 60px;
  color: var(--color-neutral-2);
  text-decoration: none;
`;
