import React from 'react';
import PlaySVG from 'assets/images/Play.svg';
import TimeSVG from 'assets/images/Time.svg';
import {
  Container,
  Group,
  Overlap,
  Image,
  TextWrapper,
  OverlapGroup,
  OverlapGroupWrapper,
  TextWrapper2,
  Time,
  MaskGroup,
} from './styles';

export interface CardProps {
  title: string;
  duration: string;
  videoUrl: string;
  onVideoSelect: (any) => void;
}

const VideoCard: React.FC<CardProps> = ({
  title,
  duration,
  videoUrl,
  onVideoSelect,
}) => {
  const url = `https://img.youtube.com/vi/${videoUrl.split('=')[1]}/0.jpg`;

  return (
    <Container className="card" onClick={() => onVideoSelect(videoUrl)}>
      <Group>
        <Overlap>
          <MaskGroup url={url} />
          <Image className="play" alt="play" src={PlaySVG} />
          <TextWrapper>{title}</TextWrapper>
          <OverlapGroupWrapper>
            <OverlapGroup>
              <Time alt="time" src={TimeSVG} />
              <TextWrapper2>{duration}</TextWrapper2>
            </OverlapGroup>
          </OverlapGroupWrapper>
        </Overlap>
      </Group>
    </Container>
  );
};

export default VideoCard;
