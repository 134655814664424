import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player';
import VideoCard from 'components/VideoCard';
import Modal from 'react-modal';
import { Container, ContentContainer, SearchBar } from './styles';
import { FaSearch } from 'react-icons/fa';
import { Button } from '@mui/material';

const Records: React.FC = () => {
  const API_KEY = process.env.REACT_APP_YOUTUBE_API_KEY;
  const CHANNEL_ID = process.env.REACT_APP_YOUTUBE_CHANNEL;

  const [videosList, setVideoList] = useState<any[]>([]);
  const [selectedVideo, setSelectedVideo] = useState<string>('');
  const [searchTerm, setSearchTerm] = useState('');
  const [videoCount, setVideoCount] = useState(10); // Number of videos to display
  const [allVideos, setAllVideos] = useState<any[]>([]); // Store all fetched videos

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const fetchVideos = async (maxResults = 50) => {
    try {
      const searchResponse = await fetch(
        `https://www.googleapis.com/youtube/v3/search?part=id&channelId=${CHANNEL_ID}&maxResults=${maxResults}&order=viewCount&type=video&key=${API_KEY}`
      );
      const searchData = await searchResponse.json();
      const videoIds = searchData.items.map((item) => item.id.videoId);
      
      const videoResponse = await fetch(
        `https://www.googleapis.com/youtube/v3/videos?part=contentDetails,snippet,status&id=${videoIds.join(
          ','
        )}&key=${API_KEY}`
      );
      const videoData = await videoResponse.json();

      const allVideoUrls = videoData.items.map((item) => ({
        videoUrl: `https://www.youtube.com/watch?v=${item.id}`,
        title: item.snippet.title,
        description: item.snippet.description,
        duration: item.contentDetails.duration
          .replace('PT', '')
          .replace('M', 'm')
          .replace('S', 's'),
        embeddable: item.status.embeddable, // Indicates if the video is embeddable
      }));

      setAllVideos(allVideoUrls); // Store all fetched videos
      
      // Filter out non-embeddable videos and get the first 10 valid videos
      const validVideos = allVideoUrls.filter((video) => video.embeddable).slice(0, videoCount);
      setVideoList(validVideos);

    } catch (error) {
      console.error('Error fetching videos:', error);
    }
  };

  useEffect(() => {
    fetchVideos(); // Fetch initial set of videos
  }, []);

  const handleLoadMore = () => {
    const newVideoCount = videoCount + 10; // Increment by 10
    setVideoCount(newVideoCount); // Update the video count
    fetchVideos(newVideoCount); // Fetch more videos
  };

  const filteredVideos = videosList.filter((video) =>
    video.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <>
      <SearchBar placeholder="Pesquisar..." value={searchTerm} onChange={handleSearchChange} />
      <FaSearch style={{ position: 'absolute', top: '44px', left: 'calc(50% + 140px)', color: 'var(--color-primary-2)' }} />
      <br />
      <Container>
        <ContentContainer>
          {filteredVideos.map((video, index) => (
            <VideoCard
              key={index}
              videoUrl={video.videoUrl}
              title={video.title}
              duration={video.duration}
              onVideoSelect={setSelectedVideo}
            />
          ))}
        </ContentContainer>
        <Button onClick={handleLoadMore}>Carregar Mais</Button> {/* Load more videos */}
        <Modal
          isOpen={!!selectedVideo}
          onRequestClose={() => setSelectedVideo('')}
          style={{
            content: {
              position: 'absolute',
              border: 'none',
              background: 'transparent',
              overflow: 'auto',
              borderRadius: '0px',
              outline: 'none',
              padding: '0px',
              height: '365px',
              width: '365px',
              top: 'calc(50% - 182.5px)',
              left: 'calc(50% - 182.5px)',
            },
          }}
        >
          <ReactPlayer url={selectedVideo} controls width="100%" aspectRatio="16/9" />
        </Modal>
      </Container>
    </>
  );
};

export default Records;
