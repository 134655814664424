import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Card, CardContent, Typography } from '@mui/material';

const SPREADSHEET_ID = process.env.REACT_APP_SHEETS_ID_FORM;
const RANGE = 'B2:F500';

const GoogleFormResponses: React.FC = () => {
  const [data, setData] = useState<any[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://sheets.googleapis.com/v4/spreadsheets/${SPREADSHEET_ID}/values/${RANGE}?key=${process.env.REACT_APP_SHEETS_API_KEY}`
        );
        setData(response.data.values || []);
      } catch (err) {
        console.error('Error fetching data: ', err);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      {data.length > 0 ? 
        data.map((row, index) => (
            <Card key={index} style={{ width: '350px', marginInline: 'auto', marginBlock: '15px' }}>
                <CardContent>
                    <Typography style={{ fontFamily: 'Nunito, sans-serif' }} variant="h6">
                        <b>Nome:</b> {row[0]}
                    </Typography>
                    <Typography style={{ fontFamily: 'Nunito, sans-serif' }} variant="h6">
                        <b>Email:</b> {row[1]}
                    </Typography>
                    <Typography style={{ fontFamily: 'Nunito, sans-serif' }} variant="h6">
                        <b>contato:</b> {row[2]}
                    </Typography>
                    <Typography style={{ fontFamily: 'Nunito, sans-serif' }} variant="h6">
                        <b>Pedido:</b> {row[3]}
                    </Typography>
                    <Typography style={{ fontFamily: 'Nunito, sans-serif' }} variant="h6">
                        <b>Retorno:</b> {row[4]}
                    </Typography>
                </CardContent>
            </Card>
        ))
      : <div style={{ padding: '20px', fontSize: '28px', fontWeight: '600', textAlign: 'center', width: '100%' }}>Nenhum pedido ainda!</div>
    }
    </div>
  );
};

export default GoogleFormResponses;
