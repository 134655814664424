import React from 'react';
import Dev from 'assets/images/Dev.svg';
import { Container, ImageContainer, Elipse, Image, Text } from './styles';

const UnderDevelopment: React.FC = () => {
  return (
    <Container>
      <ImageContainer>
        <Elipse />
        <Image draggable="false" src={Dev} alt="dev" />
        <Text>Em Desenvolvimento</Text>
      </ImageContainer>
    </Container>
  );
};

export default UnderDevelopment;
