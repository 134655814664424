import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: calc(100vh - 56px);
`;

export const Div = styled.div`
  background-color: var(--color-neutral-2);
  height: calc(100vh - 207px);
  top: 150px;
  bottom: 56px;
  position: relative;
  width: 100%;
  margin-inline: 20px;
  overflow-y: auto;
  @media (max-width: 1024px) {
    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none; /* For Internet Explorer and Edge */
    ::-webkit-scrollbar {
      display: none; /* For Chrome, Safari and Opera */
    }
  }
`;

export const TextWrapper = styled.p`
  width: 100%;
  color: var(--color-neutral-6);
  font-family: 'Nunito-Medium', Helvetica;
  font-size: 17px;
  font-weight: 500;
  height: fit-content;
  letter-spacing: -0.17px;
  line-height: 18px;
  position: absolute;
  text-align: center;
  /* top: 150px; */
  padding-inline: 40px;
  margin-block: 25px;
  h1,
  h2,
  h3 {
    line-height: 1.6;
  }
`;

export const Moto = styled.p`
  color: var(--color-neutral-6);
  /* font-family: 'Nunito-Medium', Helvetica; */
  font-size: 18px;
  /* font-weight: 500; */
  letter-spacing: -0.17px;
  line-height: 36px;
  position: relative;
  text-align: center;
  /* top: 20px; */
  width: 100%;
`;

export const Overlap = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  height: 150px;
  position: fixed;
  border-radius: 50% / 0 0 100% 100%;
  display: block;
  background-color: #e5e3e550;
`;

export const Vector = styled.img`
  height: 65px;
  left: calc(50% - 115px);
  position: fixed;
  top: 23px;
  width: 230px;
`;

export const Name = styled.p`
  color: #0e5ca0;
  font-family: 'Nunito Sans-BoldItalic', Helvetica;
  font-size: 14px;
  font-style: italic;
  font-weight: 700;
  height: 23px;
  /* left: calc(50% - 151px); */
  letter-spacing: 0;
  line-height: normal;
  position: fixed;
  text-align: center;
  top: 95px;
  scale: 0.75;
  width: 100%; /* fit-content; */
`;

export const FAQ = styled.div`
  justify-content: left;
  p {
    margin-left: none !important;
    padding: 0;
  }
`;
