import styled from 'styled-components';

export const Container = styled.div`
  height: calc(100vh - 56px);
  overflow-y: auto;
  overflow-x: hidden;
  /* display: grrid; */
  /* grid-template-columns: repeat(auto-fill, minmax(340px, 1fr)); */
  /* grid-gap: 16px; */
  align-items: stretch;
  padding: 16px;
  justify-content: space-around;
  align-content: center;
  @media (max-width: 1024px) {
    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none; /* For Internet Explorer and Edge */
    ::-webkit-scrollbar {
      display: none; /* For Chrome, Safari and Opera */
    }
  }
`;

export const SocialMediaCard = styled.div`
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-height: fit-content;
  width: fit-content;
  max-width: 352px;
  margin-inline: calc(50% - 175px);
`;
