import React, { ChangeEvent, useEffect, useState } from 'react';
import { db, storage } from '../../firebaseConfig';
import { doc, deleteDoc, getDoc, arrayRemove, arrayUnion, updateDoc } from 'firebase/firestore';
import { uploadBytes, getDownloadURL, ref } from 'firebase/storage';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, IconButton } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import Background from 'assets/images/background.jpg';
import { FaTrash } from 'react-icons/fa';
import { Container, Button, ElementContainer } from './styles';
import { useAuth } from 'providers/AuthProvider';
import PersonComponent from 'components/PersonComponent';

interface Person {
  id: string;
  name: string;
  description: string;
  imageUrl: string;
}

const PersonManagement: React.FC = () => {
  const [persons, setPersons] = useState<Person[]>([]);
  const emptyPerson = {
    id: '',
    name: '',
    description: '',
    imageUrl: '',
  };
  const [selectedPerson, setSelectedPerson] = useState<Person>(emptyPerson);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [imageFile, setImageFile] = useState<File>({} as File);
  const { isAdmin } = useAuth();
  
  useEffect(() => {
    const fetchPersons = async () => {
      const personDocRef = doc(db, 'persons', 'AkLIdaU1AunjQQsaApWz'); // Reference to a specific document
      const personDocSnapshot = await getDoc(personDocRef); // Get the document snapshot
  
      if (personDocSnapshot.exists()) {
        const docData = personDocSnapshot.data(); // Get the document data
        const personsArray = docData.persons || []; // Access the persons array
        setPersons(personsArray as Person[]); // Set the state with the persons array
      } else {
        console.warn('No document found with the specified ID.');
      }
    };
  
    fetchPersons();
  }, []);

  const handleOpenModal = (person: Person) => {
    if (isAdmin){
      setSelectedPerson(person);
      setIsModalOpen(true);
    }
  };

  const handleCloseModal = () => {
    setSelectedPerson(emptyPerson);
    setImageFile({} as File);
    setIsModalOpen(false);
  };

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setImageFile(event.target.files[0]);
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, field: string) => {
    if (!selectedPerson) return;

    const updatedPerson = { ...selectedPerson, [field]: e.target.value };
    setSelectedPerson(updatedPerson);
  };

  const updatePerson = async (personData) => {
    const docRef = doc(db, 'persons', 'AkLIdaU1AunjQQsaApWz'); // The document with the persons array
  
    // Get the current state of the document
    const docSnap = await getDoc(docRef);
  
    if (docSnap.exists()) {
      // Get the current persons array
      let persons = docSnap.data().persons;
  
      // Remove the person with the matching ID
      persons = persons.filter(person => person.id !== selectedPerson.id);
  
      // Add the updated/new person to the array
      persons.push(personData);
  
      // Update the document with the new persons array
      await updateDoc(docRef, { persons });
    }
  };

  const handleSave = async () => {
    if (!selectedPerson) return;
  
    let imageUrl = selectedPerson.imageUrl || '';
  
    if (imageFile) {
      const storageRef = ref(storage, `images/${uuidv4()}`);
      await uploadBytes(storageRef, imageFile);
      imageUrl = await getDownloadURL(storageRef);
    }
  
    const personData = { ...selectedPerson, imageUrl };
    const docRef = doc(db, 'persons', 'AkLIdaU1AunjQQsaApWz');
  
    if (selectedPerson.id) {
      // This logic assumes you're updating an existing person in the "persons" array
      updatePerson(personData);
    } else {
      // This logic is for adding a new person
      await updateDoc(docRef, {
        persons: arrayUnion({ ...personData, id: uuidv4() }) // Add new person with a unique ID
      });
    }
  
    setIsModalOpen(false);
  };

  const handleDelete = async (personId) => {
    const confirmation = window.confirm("Tem certeza de que deseja excluir este item?");
  
    if (confirmation) {
      try {
        const docRef = doc(db, 'persons', 'AkLIdaU1AunjQQsaApWz'); // Update the correct collection and document
  
        // Get the current state of the document
        const docSnap = await getDoc(docRef);
  
        if (docSnap.exists()) {
          // Get the current persons array
          let persons = docSnap.data().persons;
  
          // Remove the person with the matching ID
          persons = persons.filter(person => person.id !== personId);
  
          // Update the document with the new persons array
          await updateDoc(docRef, { persons });
  
          console.log("Item deleted successfully.");

          setIsModalOpen(false);
        }
      } catch (error) {
        console.error("Error deleting item:", error);
      }
    }
  };
  
  return (
    <Container>
      <Button hide={!isAdmin} onClick={(event) => {
        event.stopPropagation();
        handleOpenModal(emptyPerson);
      }}>
        Adicionar
      </Button>
      <div>
        {persons.map((person) => (
          <div key={person.id}>
              <PersonComponent
                  name={person.name}
                  title={person.description}
                  intern={person.imageUrl}
                  circle={Background}
                  onClick={() => handleOpenModal(person)}
              />
          </div>
        ))}
      </div>

      <Dialog open={isModalOpen} onClose={handleCloseModal}>
        <DialogTitle style={{ display: 'flex', justifyContent: 'space-between' }}>
            <p style={{ transform: 'translate(0px, 5px)' }}>{selectedPerson.id ? 'Editar' : 'Adicionar'}</p>
            {selectedPerson.id && (
                <IconButton color="error" onClick={() => handleDelete(selectedPerson.id)}>
                    <FaTrash color='#C11E0F' size='24px' />
                </IconButton>
            )}
        </DialogTitle>
        <DialogContent style={{ width: '326px', padding: '40px', overflow: 'hidden' }}>
            <TextField
            label="Name"
            value={selectedPerson?.name || ''}
            onChange={(e) => handleChange(e, 'name')}
            style={{ marginBottom: '15px' }}
            required
            helperText="Esse campo é obrigatório"
            />
            <TextField
            label="Description"
            value={selectedPerson?.description || ''}
            onChange={(e) => handleChange(e, 'description')}
            style={{ marginBottom: '15px' }}
            required
            helperText="Esse campo é obrigatório"
            />
            <ElementContainer>
              <input type="file" onChange={handleImageChange} accept="image/png, image/jpeg" />
            </ElementContainer>
        </DialogContent>
        <DialogActions style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button onClick={handleCloseModal}>Cancel</Button>
            <Button onClick={handleSave}>Save</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default PersonManagement;
