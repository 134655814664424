import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { db } from '../../firebaseConfig';
import { collection, getDocs, addDoc, updateDoc, deleteDoc, doc } from 'firebase/firestore';
import { TextField, Dialog, DialogTitle, DialogContent, DialogActions, Typography, Card, CardContent, Grid, IconButton } from '@mui/material';
import { Container, Header, Text, Button, Content } from './styles';
import { useAuth } from 'providers/AuthProvider';
import { FaTrash } from 'react-icons/fa';
import { BackButton } from 'components';

interface Activity {
  id?: string;
  title: string;
  description: string;
  start: string; // Could be a date string or similar format
  end: string;
}

const ActivityCard = ({ activity, onEdit, onDelete, status }) => {
  return (
    <Card style={{ width: '350px', marginInline: 'auto', marginBlock: '15px' }}>
      <CardContent>
        <Typography style={{ fontFamily: 'Nunito, sans-serif' }} variant="h6"><b>{activity.title}</b></Typography>
        <Typography style={{ fontFamily: 'Nunito, sans-serif' }} variant="body2"><b>Horário:</b> {activity.start} - {activity.end}</Typography>
        <Typography style={{ fontFamily: 'Nunito, sans-serif' }} variant="body2"><b>Detalhes:</b> {activity.description}</Typography>
        {status && (
          <>
            <br />
            <div style={{ display: 'flex' }}>
              <Button onClick={() => onEdit(activity)}>Editar</Button>
              <IconButton style={{ transform: 'translate(10px, -10px)'}} color="error" onClick={() => onDelete(activity.id)}>
                <FaTrash color='#C11E0F' size='24px' />
              </IconButton>
            </div>
          </>
        )}
      </CardContent>
    </Card>
  );
};

const Activityards = ({ activities, handleOpenDialog, handleDelete, isAdmin }) => {
  return (
    <Grid container spacing={2}>
      {activities.length === 0 && <div style={{ padding: '20px', fontSize: '28px', fontWeight: '600', textAlign: 'center', width: '100%' }}>Nenhuma atividade no momento!</div>}
      {activities.map((activity, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <ActivityCard
            activity={activity}
            onEdit={handleOpenDialog}
            onDelete={handleDelete}
            status={isAdmin}
          />
        </Grid>
      ))}
    </Grid>
  );
};

const Activities: React.FC = () => {
  const initialActivity = {
    title: '',
    description: '',
    start: '',
    end: '',
  }
  const { isAdmin } = useAuth();
  const [activities, setActivities] = useState<Activity[]>([]);
  const [selectedActivity, setSelectedActivity] = useState<Activity>(initialActivity);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const navigate = useNavigate();

  // Fetch activities from Firestore
  useEffect(() => {
    const fetchActivities = async () => {
      const eventsCol = collection(db, 'schedules');
      const eventsSnapshot = await getDocs(eventsCol);
      const eventsData = eventsSnapshot.docs.map((doc) => {
        const data = doc.data() as Activity; // Explicitly cast to Activity type
        return {
          id: doc.id,
          title: data.title || '',
          description: data.description || '',
          start: data.start || '',
          end: data.end || '',
        };
      });
      setActivities(eventsData);
    };

    fetchActivities();
  }, []);

  // Open the dialog to add/edit activity
  const handleOpenDialog = (activity: Activity) => {
    setSelectedActivity(activity);
    setIsDialogOpen(true);
  };

  // Close the dialog
  const handleCloseDialog = () => {
    setSelectedActivity({} as Activity);
    setIsDialogOpen(false);
  };

  // Create a new activity
  const addActivity = async (activity: Activity) => {
    const newDocRef = doc(collection(db, 'schedules'));
    await addDoc(collection(db, 'schedules'), activity);
    setActivities([...activities, { id: newDocRef.id, ...activity }]);
    handleCloseDialog();
  };

  // Update an existing activity
  const updateActivity = async (activity: Activity) => {
    if (!activity.id) return;
    const activityDocRef = doc(db, 'schedules', activity.id);
    await updateDoc(activityDocRef, { ...activity });
    setActivities(activities.map((act) => (act.id === activity.id ? activity : act)));
    handleCloseDialog();
  };

  // Delete an activity
  const deleteActivity = async (id: string) => {
    if (id !== '') {
      if (window.confirm("Tem certeza de que deseja deletar essa atividade?")) {
        const activityDocRef = doc(db, 'schedules', id);
        await deleteDoc(activityDocRef);
        setActivities(activities.filter((act) => act.id !== id));
      }
    }
  };

  return (
    <Container>
      <Header>
        <Text>Atividades</Text>
      </Header>
      <Content>
        {isAdmin && <><br /><Button onClick={() => handleOpenDialog({} as Activity)}>Adicionar Atividade</Button></>}
        <Activityards activities={activities} handleOpenDialog={handleOpenDialog} handleDelete={deleteActivity} isAdmin={isAdmin} />
        <Dialog open={isDialogOpen} onClose={handleCloseDialog}>
          <DialogTitle>{selectedActivity.id ? 'Editar Atividade' : 'Adicionar Atividade'}</DialogTitle>
          <br />
          <DialogContent>
            <TextField
              style={{ marginBottom: '15px' }}
              label="Atividade"
              required
              helperText="Esse campo é obrigatório"
              value={selectedActivity?.title || ''}
              onChange={(e) => setSelectedActivity({ ...selectedActivity, title: e.target.value })}
              fullWidth
            />
            <TextField
              style={{ marginBottom: '15px' }}
              label="Descrição"
              required
              helperText="Esse campo é obrigatório"
              multiline
              rows={4}
              value={selectedActivity?.description || ''}
              onChange={(e) => setSelectedActivity({ ...selectedActivity, description: e.target.value })}
              fullWidth
            />
            <TextField
              style={{ marginBottom: '15px' }}
              label="Início"
              required
              helperText="Esse campo é obrigatório"
              value={selectedActivity?.start || ''}
              onChange={(e) => setSelectedActivity({ ...selectedActivity, start: e.target.value })}
              fullWidth
            />
            <TextField
              style={{ marginBottom: '15px' }}
              label="Encerramento"
              required
              helperText="Esse campo é obrigatório"
              value={selectedActivity?.end || ''}
              onChange={(e) => setSelectedActivity({ ...selectedActivity, end: e.target.value })}
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button dialogActions onClick={handleCloseDialog}>Cancelar</Button>
            <Button
              dialogActions
              onClick={() => {
                if (
                  selectedActivity &&
                  selectedActivity.title?.trim() &&
                  selectedActivity.description?.trim() &&
                  selectedActivity.start?.trim() &&
                  selectedActivity.end?.trim()
                ) {
                  console.log(selectedActivity.title);
                  if (selectedActivity.id) {
                    updateActivity(selectedActivity);
                  } else {
                    addActivity(selectedActivity);
                  }
                }
              }}
            >
              Salvar
            </Button>
          </DialogActions>
        </Dialog>
        <BackButton goBack={() =>  navigate(-1)} />
      </Content>
    </Container>
  );
};

export default Activities;
