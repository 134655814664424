import { useState } from 'react';
import { Container, Grid, Typography, Button, Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { Box } from '@mui/system';
import Integra1 from 'assets/videos/integra1.mp4';
import Integra2 from 'assets/videos/integra2.mp4';
import Celulas from 'assets/videos/celulas.mp4';

const VideoPage = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [currentBook, setCurrentBook] = useState('');
  const [bookName, setBookName] = useState('');

  const handleOpenDialog = (book, name) => {
    setCurrentBook(book);
    setOpenDialog(true);
    setBookName(name);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setCurrentBook('');
    setBookName('');
  };

  return (
    <Container style={{ height: 'calc(100% - 56px)', overflow: 'auto' }}>
      <Box my={4}>
        <Typography variant="h4" gutterBottom>
          <b>Formação</b>
        </Typography>
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <Typography variant="h6"><b>Integra 1 (Batismo)</b></Typography>
          <video controls width="100%" height="auto">
            <source src={Integra1} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </Grid>

        <Grid item xs={12} sm={4}>
          <Typography variant="h6"><b>Integra 2 (Membresia)</b></Typography>
          <video controls width="100%" height="auto">
            <source src={Integra2} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </Grid>

        <Grid item xs={12} sm={4}>
          <Typography variant="h6"><b>Células</b></Typography>
          <video controls width="100%" height="auto">
            <source src={Celulas} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6"><b>Apostílas</b></Typography>
          <Button
            variant="outlined"
            onClick={() => handleOpenDialog("https://online.fliphtml5.com/vagfg/qgjo/", 'Preparação para o Batismo')}
          >
            Integra 1
          </Button>
          <Button
            variant="outlined"
            onClick={() => handleOpenDialog("https://online.fliphtml5.com/vagfg/tzze/", 'Novos membros')}
          >
            Integra 2
          </Button>
          <Button
            variant="outlined"
            onClick={() => handleOpenDialog("https://online.fliphtml5.com/vagfg/rgba/", 'Liderança de Células')}
          >
            Células
          </Button>
        </Grid>
      </Grid>
      <Dialog
        fullScreen
        open={openDialog}
        onClose={handleCloseDialog}
      >
        <DialogTitle>
          {bookName}
          <IconButton
            aria-label="close"
            onClick={handleCloseDialog}
            sx={{ position: 'absolute', right: 8, top: 8 }}
          >
            &times;
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ height: '100%', padding: '0', overflow: 'hidden' }}>
          <iframe
            src={currentBook}
            width="100%"
            height="100%"
            allowFullScreen
          ></iframe>
        </DialogContent>
      </Dialog>
    </Container>
  );
};

export default VideoPage;
