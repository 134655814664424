import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const Frame = styled.iframe`
  width: 100%;
  height: calc(100vh - 56px);
  overflow: hidden;
`;
