import styled from 'styled-components';

export const Person = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 350px;
  height: 350px;
  margin-bottom: 25px;
`;

export const Container = styled.div`
  border-radius: 50%;
  height: 312px;
  -webkit-tap-highlight-color: transparent;
  transform: scale(0.48);
  transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1);
  width: 400px;
  margin-top: -50px; /* -70px; */

  &:hover {
    transform: scale(0.54);
  }

  &:after {
    content: '';
    background-color: transparent;
    position: absolute;
    top: 390px;
    height: 10px;
    width: 100%;
  }
`;

export const ContainerInner = styled.div`
  clip-path: path(
    'M 390,400 C 390,504.9341 304.9341,590 200,590 95.065898,590 10,504.9341 10,400 V 10 H 200 390 Z'
  );
  position: relative;
  transform-origin: 50%;
  top: -200px;
`;

export const Circle = styled.img`
  background-color: transparent;
  border-radius: 50%;
  position: absolute;
  top: 210px;
  left: 10px;
  height: 380px;
  width: 380px;
`;

export const Img = styled.img`
  position: relative;
  transform: translateY(20px) scale(1.15);
  transform-origin: 50% bottom;
  transition: transform 300ms cubic-bezier(0.4, 0, 0.2, 1);

  &:hover {
    transform: translateY(0) scale(1.3);
  }
`;

export const ImgIntern = styled(Img)`
  left: 22px;
  top: 240px;
  width: 340px;

  &:hover {
    top: 250px;
  }
`;

export const Divider = styled.div`
  background-color: #ca6060;
  height: 1px;
  width: 160px;
`;

export const Name = styled.div`
  color: #404245;
  font-size: 28px;
  font-weight: 600;
  text-align: center;
  margin-top: 16px;
  height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
`;

export const Title = styled.div`
  color: #6e6e6e;
  font-family: Arial, sans-serif;
  font-size: 14px;
  font-style: italic;
  margin-top: 4px;
`;
