import React from 'react';
import { Link } from 'react-router-dom';
import Church from 'assets/images/Church.svg';
import Medias from 'assets/images/Medias.svg';
import Events from 'assets/images/Events.svg';
import Records from 'assets/images/Records.svg';
import Communion from 'assets/images/Communion.svg';
import Prayer from 'assets/images/Prayer.svg';
import Bible from 'assets/images/Bible.svg';
import Confession from 'assets/images/Confession.svg';
import Services from 'assets/images/Services.svg';
import SBS from 'assets/images/SBS.svg';
import SG from 'assets/images/SG.svg';
import Activities from 'assets/images/Activities.svg';
import Birthdays from 'assets/images/Birthdays.svg';
import EBD from 'assets/images/EBD.svg';
import { Container, Image, Text, Title } from './styles';

// Define the type for the image keys
type ImageKey =
  | 'Church'
  | 'Medias'
  | 'Events'
  | 'Records'
  | 'Communion'
  | 'Prayer'
  | 'Bible'
  | 'Confession'
  | 'Services'
  | 'SBS'
  | 'SG'
  | 'Activities'
  | 'Birthdays'
  | 'EBD';

// Define the type for the component props
interface PillProps {
  imageKey: ImageKey; // Use the defined type here
  title: string;
  text: string;
  mode2?: boolean;
  disableLink?: boolean;
}

// Define the images object outside of the component to avoid redeclaration on each render
const images: Record<ImageKey, string> = {
  Church,
  Medias,
  Events,
  Records,
  Communion,
  Prayer,
  Bible,
  Confession,
  Services,
  SBS,
  SG,
  Activities,
  Birthdays,
  EBD,
};

const Pill: React.FC<PillProps> = ({ imageKey, title, text, mode2, disableLink }) => {
  return (
    <Link style={{ textDecoration: 'none', pointerEvents: disableLink ? "none" : "auto" }} to={`/${imageKey.toLowerCase()}`}>
      <Container mode2={mode2 ? mode2 : false}>
        <Image mode2={mode2 ? mode2 : false} src={images[imageKey]} alt={text} />
        <Title mode2={mode2 ? mode2 : false}>{title}</Title>
        <Text display={mode2 ? 'inline' : 'block'}>{text}</Text>
      </Container>
    </Link>
  );
};

export default Pill;
