import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 277px;
  position: relative;
  background-color: #ffffff;
  border-radius: 16px;
  box-shadow: 0px 8px 8px #8163d626;
  margin-bottom: 25px;
`;

export const StyledSeparator = styled.div`
  background-color: #9f9f9f;
  height: 1px;
  position: relative;
  top: 230px;
  width: 100%;
`;

export const StyledLikeCommentSection = styled.div`
  position: relative;
  top: 240px;
  left: 20px;
  display: flex;
  align-items: center;
`;

export const StyledText = styled.div`
  color: var(--color-primary-2);
  font-family: 'Poppins-Regular', Helvetica;
  font-size: 14px;
  margin-left: 8px;
`;

export const TextContainer = styled.p`
  color: #484848;
  font-family: 'Nunito-Regular', Helvetica;
  font-size: 15px;
  font-weight: 400;
  left: 20px;
  letter-spacing: -0.41px;
  line-height: 22px;
  position: absolute;
  top: 85px;
  width: 335px;
`;

export const Text = styled.span`
  color: #484848;
  font-family: 'Nunito-Regular', Helvetica;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: -0.41px;
  line-height: 22px;
`;

export const Name = styled.div`
  color: #000000;
  font-family: 'Poppins-SemiBold', Helvetica;
  font-size: 18px;
  font-weight: 600;
  left: 80px;
  letter-spacing: -0.17px;
  line-height: 18px;
  position: absolute;
  top: 31px;
  width: 175px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Time = styled.div`
  color: #9f9f9f;
  font-family: 'Poppins-Regular', Helvetica;
  font-size: 14px;
  font-weight: 400;
  left: 80px;
  letter-spacing: -0.17px;
  line-height: 18px;
  position: absolute;
  top: 54px;
  width: 233px;
`;

export const Options = styled.div`
  color: #9f9f9f;
  font-family: 'Poppins-Regular', Helvetica;
  font-size: 36px;
  font-weight: 400;
  right: 25px;
  float: right;
  letter-spacing: -0.17px;
  line-height: 7px;
  position: relative;
  top: 7px;
`;
