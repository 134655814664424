import React, { useEffect } from 'react';
import axios from 'axios';
import { GoogleMap, Marker, InfoWindow, useLoadScript } from '@react-google-maps/api';
import { Container } from '@mui/material';
import { CircularProgress } from '@mui/material';
import { useState } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { db } from "../../firebaseConfig";

const GOOGLE_MAPS_API_KEY = `${process.env.REACT_APP_PLACES_API_KEY}`;

const mapStyles = {
  height: '300px',
  width: '100%',
  padding: '40px',
};

const defaultCenter = {
  lat: -18.913664, // Uberlândia, Brazil
  lng: -48.266560,
};

const geocode = async (address) => {
  if (address === '' || address === undefined) {
    return null;
  }

  const geocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
    address
  )}&key=${GOOGLE_MAPS_API_KEY}`;

  try {
    const response = await axios.get(geocodeUrl);
    const location = response.data.results[0].geometry.location;
    return { lat: location.lat, lng: location.lng };
  } catch (error) {
    console.error('Geocoding error:', error);
    return null;
  }
};

const CellsMap: React.FC = () => {
  const { isLoaded } = useLoadScript({ googleMapsApiKey: GOOGLE_MAPS_API_KEY });
  const [selectedMarker, setSelectedMarker] = useState<any>();

  const [cells, setCells] = useState<any>([]);
  const [coordinates, setCoordinates] = useState<any>([]);

  useEffect(() => {
    const fetchCells = async () => {
      const cellsRef = doc(db, "cells", "pcpdCsp7qxkSl5sfYtLe"); // Adjust document ID
      const cellSnapshot = await getDoc(cellsRef);

      
      if (cellSnapshot.exists()) {
        const getCells = cellSnapshot.data().cells;
        const promises = getCells.map((cell) => geocode(cell.Address));
        const results = await Promise.all(promises);
        setCoordinates(results);
        setCells(getCells);
      }
    };

    fetchCells();
  }, []);

  if (!isLoaded) {
    // Show loading indicator while Google Maps API is loading
    return (
      <Container style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "95vh" }}>
        <CircularProgress />
      </Container>
    );
  }

  return (
    <GoogleMap mapContainerClassName='mapCells' mapContainerStyle={mapStyles} zoom={11} center={defaultCenter}>
      {cells && cells.map((cell, index) => {
        const lat = parseFloat(coordinates[index].lat);
        const lng = parseFloat(coordinates[index].lng);
        
          if (isNaN(lat) || isNaN(lng)) {
            console.error("Invalid position data", { lat, lng });
            return null; // Skip invalid markers
          }

          return (
            <Marker
              key={index}
              clickable
              position={{ lat: lat, lng: lng }}
              onClick={() => setSelectedMarker(cell)}
            />
          );
      })}

      {selectedMarker && (
        <InfoWindow
          position={{ lat: parseFloat(selectedMarker.latitude), lng: parseFloat(selectedMarker.longitude) }}
          onCloseClick={() => setSelectedMarker(null)}
        >
          <div>
            <h5>{selectedMarker.Name}</h5>
            <p>Dia da semana: {selectedMarker.WeekDay}</p>
            <p>Horário: {selectedMarker.Time}</p>
            <p>Líder: {selectedMarker.Leader}</p>
            <p>Contato: {selectedMarker.Contact}</p>
          </div>
        </InfoWindow>
      )}
    </GoogleMap>
  );
};

export default CellsMap;