import React, { ChangeEvent, useState } from 'react';
import { storage } from '../../firebaseConfig';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';

const ImageUpload: React.FC<{ onUpload: (url: string) => void }> = ({ onUpload }) => {
    const [file, setFile] = useState<File>();
    const [uploading, setUploading] = useState(false);
    const [error, setError] = useState('');

    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
      const files = e.target.files;
      if (!files || files.length === 0) return;
      setFile(files[0]);
    };
  
    const handleUpload = async (e) => {
      e.preventDefault(); // Prevent form submission
      if (!file) return;
      setUploading(true);
      try {
        const storageRef = ref(storage, `images/${file.name}`);
        const uploadResult = await uploadBytes(storageRef, file);
        const url = await getDownloadURL(uploadResult.ref);
        onUpload(url);
        setUploading(false);
      } catch (error) {
        console.error("Error uploading file: ", error);
        setError('Failed to upload image.');
        setUploading(false);
      }
    };    

    return (
        <div className='imageInput'>
          <input type="file" onChange={handleFileChange} accept="image/png, image/jpeg" />
          <button type="button" onClick={handleUpload} disabled={uploading}>Upload</button>
          {uploading && <p>Uploading...</p>}
          {error && <p className="error">{error}</p>}
        </div>
    );
  };

export default ImageUpload;
