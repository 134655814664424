import styled from 'styled-components';

export const Container = styled.div`
  height: calc(100vh - 56px);
  overflow: auto;
  @media (max-width: 1024px) {
    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none; /* For Internet Explorer and Edge */
    ::-webkit-scrollbar {
      display: none; /* For Chrome, Safari and Opera */
    }
  }
  width: 100%;
  padding-top: 23px;
  margin-bottom: 81px;
`;

export const Moto = styled.img`
  position: relative;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: calc(100% - 50px);
  max-width: 500px;
  min-width: 366px;
  height: fit-content;
  user-select: none;
`;

export const MenuContent = styled.div`
  position: relative;
  /* height: 60.15%; */
  max-width: 950px;
  margin-inline: auto;
  justify-content: space-between;
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
  justify-content: center;
  margin-bottom: 25px;
  @media only screen and (max-width: 400px) {
    width: calc(100% - 50px);
  }
  div:hover {
    transform: scale(1.05);
    cursor: pointer;
  }
`;
