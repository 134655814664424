import React from "react";
import { Person, Container, Circle, ContainerInner, Divider, ImgIntern, Name, Title } from "./styles";

const PersonComponent: React.FC<{
  name: string,
  title: string,
  circle: string,
  intern: string,
  onClick: () => void
}> = ({ name, title, circle, intern, onClick }) => (
  <Person>
    <Container>
      <ContainerInner>
        <Circle src={circle} />
        <ImgIntern src={intern} />
      </ContainerInner>
    </Container>
    <Divider />
    <Name onClick={onClick}>{name}</Name>
    <Title onClick={onClick}>{title}</Title>
  </Person>
);

export default PersonComponent;
