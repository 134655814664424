import styled, { css } from 'styled-components';

export const Container = styled.div<{ overflow: string }>`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 56px);
  overflow-y: ${({overflow}) => overflow || 'auto'};
  overflow-x: hidden;
`;

export const SearchBar = styled.input`
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-inline: auto;
  margin-block: 20px;
  width: 350px;
`;

export const EventCardContainer = styled.div`
  border-top: 3px solid var(--color-primary-1);
  border-bottom: 3px solid var(--color-primary-1);
  /* border-radius: 5px; */
  overflow: hidden;
  margin-bottom: 15px;
  margin-inline: auto;
  max-width: 350px;
`;

export const EventImage = styled.img`
  width: 100%;
  height: auto;
  display: block;
`;

export const EventInfo = styled.div`
  padding: 10px;
`;

export const EventName = styled.div`
  color: var(--color-neutral-6);
  margin: 0;
  font-size: 15px;
  font-family: 'Nunito', sans-serif;
  font-weight: bolder;
  margin-bottom: 30px; // padding-bottom: 14px;
`;

export const EventTime = styled.div`
  display: inline-flex;
  width: 100%;
  color: var(--color-neutral-6);
  margin: 0;
  font-size: 14px;
  font-weight: bold;
  padding-bottom: 14px;
  justify-content: space-between;
  svg {
    margin-left: 12px;
    margin-top: -10px;
  }
  button {
    margin-top: -15px;
    font-family: 'Nunito', sans-serif !important;
    font-size: 14px;
  }
`;

export const EventDate = styled.div`
  color: var(--color-neutral-6);
  font-size: 20px;
  font-weight: bold;
  padding-top: 10px;
  padding-bottom: 34px;
  /* padding-inline: 30px; */
  width: 350px;
  margin-inline: auto;
`;

export const Button = styled.button<{ hide?: boolean; crud: boolean }>`
  display: block;
  height: 33px;
  padding: 10px;
  margin: 10px;
  background-color: var(--color-primary-2);
  color: var(--color-neutral-2);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  ${({ hide }) =>
    hide &&
    css`
      display: none !important;
  `}
  ${({ crud }) =>
    crud &&
    css`
      width: 350px;
      margin-inline: auto !important;
    `}
`;

export const Select = styled.select`
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ced4da;
  background-color: var(--color-neutral-2);
`;

export const Option = styled.option`
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ced4da;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin: 10px 0;
`;

export const Input = styled.input`
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: var(--color-neutral-2);
`;

export const Ticket = styled.div`
  margin-inline: auto;
  width: 350px;
  -webkit-margin-before: -45px;
  h3 {
    position: absolute;
    top: 0px;
    color: var(--color-neutral-6);
    font-family: 'Nunito', sans-serif;
    font-size: 28px;
    font-weight: bold;
    text-align: center;
    padding-top: 35px;
    margin-inline: 71px; // calc(50% - 120px);
    width: 210px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  p {
    position: absolute;
    top: 0px;
    color: var(--color-neutral-6);
    font-family: 'Nunito', sans-serif;
    font-size: 10px;
    font-weight: bold;
  }
  .name {
    margin-top: 360px;
    margin-left: 53px;
  }
  .date {
    margin-top: 425px;
    margin-left: 53px;
  }
  .time {
    margin-top: 425px;
    margin-left: 213px;
  }
  .location {
    margin-top: 485px;
    margin-left: 53px;
  }
`;

export const QRCodeWrapper = styled.div`
  width: 205px;
  height: 205px;
  position: absolute;
  top: 77px;
  left: calc(50% - 102.5px);
`;

export const EventDetailContainer = styled.div`
  background-color: var(--color-neutral-2);
  border-radius: 8px;
  padding: 18px;
  width: 100%;
  max-width: 480px;
  font-family: 'Nunito', sans-serif;
  margin-inline: auto;
  max-width: 350px;
`;

export const EventDetailImage = styled.img`
  /* Out of container attempt */
  /* width: 100%;
  max-width: 500px;
  height: auto;
  margin-bottom: 20px; */
  /* Inside container */
  width: 420px;
  height: auto;
  margin-bottom: 20px;
  margin-left: -53.5px;
  margin-top: -20px;
  overflow: auto;
`;

export const EventDetailTitle = styled.div`
  color: var(--color-neutral-6);
  font-family: 'Nunito', sans-serif !important;
  font-weight: bolder;
  color: var(--color-neutral-6);
  font-size: 28px;
  text-align: left;
`;

export const EventDetailInfo = styled.div`
  color: var(--color-neutral-6);
  font-family: 'Nunito Sans', sans-serif;
  display: flex;
  align-items: center;
  margin: 20px 0;
  font-size: 16px;
  font-weight: bold;
`;

export const EventRegisterTitle = styled.div`
  color: var(--color-neutral-6);
  font-family: 'Nunito', sans-serif !important;
  font-weight: bolder;
  color: var(--color-neutral-6);
  font-size: 28px;
  text-align: left;
  margin-top: 55px;
`;

export const EventRegister = styled.div`
  color: var(--color-neutral-6);
  display: flex;
  align-items: center;
  margin: 10px 0;
  font-size: 16px;
  font-weight: 600;
`;

export const EventBar = styled.div`
  border-bottom: 1px solid var(--color-primary-1);
`;

export const EventDetailButton = styled(Button)`
  margin-top: 20px;
  width: 205px;
  height: 56px;
  margin-inline: auto;
`;

export const RegistrationForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 8px;
  padding: 20px;
  width: 100%;
  max-width: 480px;
  height: 100%;
  overflow: auto;
  margin-inline: auto;
  font-family: 'Nunito Sans', sans-serif !important;
`;

export const RegistrationInput = styled.input`
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ced4da;
  background-color: var(--color-neutral-2);
`;

export const RegistrationInputDescription = styled.textarea`
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ced4da;
  min-height: 40px;
  min-width: 100%;
  max-width: 100%;
  font-family: 'Nunito Sans', sans-serif !important;
  font-size: 16px;
  background-color: var(--color-neutral-2);
`;

export const RegistrationButton = styled(Button)`
  /* position: absolute; */
  /* bottom: 100px; */
  background-color: var(--color-primary-2);
  height: 41px;
  width: 100%;
  /* margin-top: 100px; */
  margin-inline: 0;
`;

export const Image = styled.img`
  height: 57px;
  width: 57px;
  margin-right: 20px;
`;

export const TicketImage = styled.img`
  aspect-ratio: 9 / 16;
  max-width: 350px;
  margin-top: 20px;
`;

export const ElementContainer = styled.div`
  img {
    width: 100%;
    aspect-ratio: 4 / 3;
    margin-top: 15px;
  }
  .imageInput {
    /* width: 350px !important; */
    display: flex;
    justify-content: space-between;
    input {
      min-width: 130px;
      padding: 13px;
      border: 1px solid #ced4da;
      border-radius: 3px;
      color: var(--color-neutral-6);
      background-color: var(--color-neutral-2);
    }
    button {
      display: block;
      height: 51px;
      width: 81px;
      padding: 10px;
      margin-left: 15px;
      background-color: var(--color-primary-2);
      color: var(--color-neutral-2);
      border: none;
      border-radius: 5px;
      cursor: pointer;
    }
  }
  .dateTimePicker, .deadLinePicker {
      width: 100% !important;
    .react-datetime-picker__wrapper {
      padding: 5px;
      border-radius: 5px;
      border: 1px solid #ced4da;
      background-color: var(--color-neutral-2);
    }
    .react-datetime-picker__inputGroup {
      color: var(--color-neutral-6);
    }
    .react-datetime-picker__clock, .react-datetime-picker__calendar, .react-calendar {
      width: 100% !important;
    }
    .react-clock {
      margin-inline: auto;
    }
  }
`;
