import React from 'react';
import Logo from 'assets/images/Text_Logo.png';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material';
import { MdExpandMore } from 'react-icons/md';
import {
  Container,
  Div,
  Moto,
  Name,
  TextWrapper,
  Overlap,
  Vector,
  FAQ,
} from './styles';

const Church: React.FC = () => {
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <Container>
      <Div>
        <TextWrapper>
          <Moto>
            <p style={{ fontFamily: 'Monotype Corsiva', fontWeight: '600' }}><i>&ldquo;Unidos na Fé, Crescendo em Amor&rdquo;</i><p></p>Filipenses 1:9</p>
          </Moto>
          <br />
          <h1>Bem-vindo à Igreja Batista Central de Uberlândia</h1>
          <br />
          <h3 style={{ textAlign: 'start' }}>
          Somos uma congregação vibrante e acolhedora, dedicada a viver os ensinamentos de Jesus Cristo.
          <br />
          Nossa missão é ser uma luz na comunidade, oferecendo um lugar de adoração, aprendizado e comunhão para todos que buscam um relacionamento mais profundo com Deus.
          <br />
          Como uma Igreja Batista, valorizamos a pregação da Bíblia, a adoração significativa e a comunidade fraterna.
          </h3>
          <br />
          <h2 style={{ textAlign: 'start' }}>Nossos Serviços e Atividades:</h2>
          <br />
          <h3>
            <ul style={{ textAlign: 'start', width: '100%' }}>
              <li>
                Cultos dominicais com mensagens inspiradoras e música
                contemporânea.
              </li>
              <li>Escola Bíblica Dominical para todas as idades.</li>
              <li>
                Pequenos grupos para estudo bíblico e comunhão durante a semana.
              </li>
              <li>Ministérios de apoio e serviço à comunidade.</li>
            </ul>
          </h3>
          <br />
          <h2>Associações:</h2>
          <br />
          <div style={{ textAlign: 'start' }}>
            Como parte da família Batista maior, estamos afiliados à <a href="http://batistasmineiros.org.br/">Convenção Batista Mineira</a>, parte da <a href="https://convencaobatista.com.br/siteNovo/index.php">Conveção Batista Brasileira</a>, integrante da <a href="https://baptistworld.org/pt/history/">Aliança Batista Mundial</a> o que nos conecta a uma rede mais ampla de igrejas com crenças e práticas similares.
          </div>
          <br />
          <h2 style={{ textAlign: 'start' }}>Perguntas e Respostas</h2>
          <br />
          <FAQ>
            <Accordion
              expanded={expanded === 'panel1'}
              onChange={handleChange('panel1')}
            >
              <AccordionSummary
                expandIcon={<MdExpandMore />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography sx={{ width: '100%', flexShrink: 0, textAlign: 'start' }}>
                  Em que a igreja acredita?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography sx={{ textAlign: 'start' }}>
                  Como Batistas, seguimos a Bíblia como única autoridade em fé e
                  prática, enfatizamos a salvação pela fé em Jesus Cristo, o
                  batismo por imersão após a conversão e valorizamos a autonomia
                  da igreja local.
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={expanded === 'panel2'}
              onChange={handleChange('panel2')}
            >
              <AccordionSummary
                expandIcon={<MdExpandMore />}
                aria-controls="panel2bh-content"
                id="panel2bh-header"
              >
                <Typography sx={{ width: '100%', flexShrink: 0, textAlign: 'start' }}>
                  Como são os cultos na igreja?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography sx={{ textAlign: 'start' }}>
                  Nossos cultos incluem louvor com música, orações, leituras
                  bíblicas e sermões baseados na Bíblia. Valorizamos uma
                  adoração que é ao mesmo tempo reverente e acessível.<br></br><br></br>
                  <a href='https://centraludia.com/services'>Cultos semanais!</a>
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={expanded === 'panel3'}
              onChange={handleChange('panel3')}
            >
              <AccordionSummary
                expandIcon={<MdExpandMore />}
                aria-controls="panel3bh-content"
                id="panel3bh-header"
              >
                <Typography sx={{ width: '100%', flexShrink: 0, textAlign: 'start' }}>
                  Como é a gestão da igreja?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography sx={{ textAlign: 'start' }}>
                  Nossa igreja pratica uma gestão democrática e transparente. Os
                  membros participam ativamente em assembleias mensais,
                  discutindo e votando em questões orçamentárias e
                  administrativas, garantindo uma liderança aberta e
                  responsável.
                </Typography>
              </AccordionDetails>
            </Accordion>

            {/* <Accordion
              expanded={expanded === 'panel4'}
              onChange={handleChange('panel4')}
            >
              <AccordionSummary
                expandIcon={<MdExpandMore />}
                aria-controls="panel4bh-content"
                id="panel4bh-header"
              >
                <Typography sx={{ width: '100%', flexShrink: 0 }}>
                  Sua igreja é Calvinista ou Arminiana?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Nossa convenção abriga uma diversidade teológica, sem adotar
                  oficialmente o Calvinismo ou o Arminianismo. Particularmente,
                  buscamos adotar uma perspectiva que valoriza tanto a soberania
                  divina quanto a responsabilidade humana, focando na nossa
                  identidade como Batistas.
                </Typography>
              </AccordionDetails>
            </Accordion> */}

            <Accordion
              expanded={expanded === 'panel5'}
              onChange={handleChange('panel5')}
            >
              <AccordionSummary
                expandIcon={<MdExpandMore />}
                aria-controls="panel5bh-content"
                id="panel5bh-header"
              >
                <Typography sx={{ width: '100%', flexShrink: 0, textAlign: 'start' }}>
                  Sua igreja tem um credo ou declaração de fé?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography sx={{ textAlign: 'start' }}>
                  Sim, temos uma declaração de fé da Convenção Batista
                  Brasileira que resume nossas crenças fundamentais, baseadas na
                  Bíblia. Esta declaração está alinhada com os ensinamentos
                  gerais das Igrejas Batistas e pode ser encontrada em nosso
                  site. Também estão disponíveis confissões de fé que abrangem
                  tanto perspectivas calvinistas quanto arminianas, refletindo a
                  diversidade teológica dentro da denominação.
                  <br></br><br></br>
                  <a href='https://centraludia.com/confession'>Nossa doutrina!</a>
                </Typography>
              </AccordionDetails>
            </Accordion>

            {/* <Accordion
              expanded={expanded === 'panel6'}
              onChange={handleChange('panel6')}
            >
              <AccordionSummary
                expandIcon={<MdExpandMore />}
                aria-controls="panel6bh-content"
                id="panel6bh-header"
              >
                <Typography sx={{ width: '100%', flexShrink: 0 }}>
                  Vocês são uma igreja renovada?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Não! Mantemos uma postura tradicional em nossa fé e prática,
                  diferenciando-nos das igrejas renovdas e pentecostais. Não
                  promovemos práticas carismáticas como glossolalia ou curas
                  durante os cultos, enfatizando a pregação bíblica e a adoração
                  reverente.
                </Typography>
              </AccordionDetails>
            </Accordion> */}

            <Accordion
              expanded={expanded === 'panel7'}
              onChange={handleChange('panel7')}
            >
              <AccordionSummary
                expandIcon={<MdExpandMore />}
                aria-controls="panel7bh-content"
                id="panel7bh-header"
              >
                <Typography sx={{ width: '100%', flexShrink: 0, textAlign: 'start' }}>
                  Crianças e adolescentes têm espaço na igreja?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography sx={{ textAlign: 'start' }}>
                  Sim, temos programas específicos para crianças, adolescentes e
                  jovens, incluindo cultos, células, escola bíblica e atividades
                  recreativas.<br></br><br></br>
                  <a href='https://centraludia.com/communion'>Programação da Igreja!</a>
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={expanded === 'panel8'}
              onChange={handleChange('panel8')}
            >
              <AccordionSummary
                expandIcon={<MdExpandMore />}
                aria-controls="panel8bh-content"
                id="panel8bh-header"
              >
                <Typography sx={{ width: '100%', flexShrink: 0, textAlign: 'start' }}>
                  É necessário ser membro para participar das atividades?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography sx={{ textAlign: 'start' }}>
                  Não, nossas portas estão abertas a todos, independentemente de
                  serem membros ou não. Encorajamos a participação em nossos
                  cultos, estudos bíblicos e eventos.
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={expanded === 'panel9'}
              onChange={handleChange('panel9')}
            >
              <AccordionSummary
                expandIcon={<MdExpandMore />}
                aria-controls="panel9bh-content"
                id="panel9bh-header"
              >
                <Typography sx={{ width: '100%', flexShrink: 0, textAlign: 'start' }}>
                  Como posso me tornar membro da igreja?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography sx={{ textAlign: 'start' }}>
                  Oferecemos dois cursos para recepção de membros: um para novos
                  convertidos e outro para membros de outras denominações.
                  Detalhes, lições e inscrições estão disponíveis no site.<br></br><br></br>
                  <a href='https://centraludia.com/sbs?tab=1'>Preparação para batismo, membresia e gestão de células!</a>
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={expanded === 'panel10'}
              onChange={handleChange('panel10')}
            >
              <AccordionSummary
                expandIcon={<MdExpandMore />}
                aria-controls="panel10bh-content"
                id="panel10bh-header"
              >
                <Typography sx={{ width: '100%', flexShrink: 0, textAlign: 'start' }}>
                  A igreja está envolvida com a comunidade local?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography sx={{ textAlign: 'start' }}>
                  Sim, estamos envolvidos em atividades de serviço comunitário,
                  como programas de alimentação, tratamento de dependentes
                  químicos, recepção de refugiados, amparo a pessoas carentes e
                  educação secular. Isso, através de orgãos e instituições
                  ligados à convenção e ou diretamente à nossa igreja local.
                  Temos ainda, anualmente, um ação de evangelhismo junto a
                  população em situação de rua.
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={expanded === 'panel11'}
              onChange={handleChange('panel11')}
            >
              <AccordionSummary
                expandIcon={<MdExpandMore />}
                aria-controls="panel11bh-content"
                id="panel11bh-header"
              >
                <Typography sx={{ width: '100%', flexShrink: 0, textAlign: 'start' }}>
                  Como a igreja lida com outras religiões e cristãos não
                  protestantes?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography sx={{ textAlign: 'start' }}>
                  Ensinamos que a conversão à fé evangélica é essencial para a
                  salvação. Respeitamos outras crenças, mas permanecemos firmes
                  em nossa convicção evangélica, tendo por missão compartilhar o
                  Evangelho de Cristo com todos sem distinção.
                  <br />
                  <i>[Gálatas 3:28; Apocalípse 7:9]</i>
                </Typography>
              </AccordionDetails>
            </Accordion>

            {/* <Accordion
              expanded={expanded === 'panel12'}
              onChange={handleChange('panel12')}
            >
              <AccordionSummary
                expandIcon={<MdExpandMore />}
                aria-controls="panel12bh-content"
                id="panel12bh-header"
              >
                <Typography sx={{ width: '100%', flexShrink: 0 }}>
                  Qual é a postura da igreja em relação à comunidade LGBTQIAPN+?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Mantemos uma visão tradicional quanto à sexualidade e
                  casamento. Pessoas LGBTQIAPN+ são bem-vindas à igreja, mas não
                  participam dos memoriais nem são elegíveis para ordenações ou
                  matrimônio.
                </Typography>
              </AccordionDetails>
            </Accordion> */}
            <br />
            <h3>
              Seja bem-vindo para nos visitar e fazer parte da nossa comunidade!
            </h3>
          </FAQ>
        </TextWrapper>
      </Div>
      <Overlap>
        <Vector src={Logo} />
        <Name>IGREJA BATISTA CENTRAL DE UBERLÂNDIA</Name>
      </Overlap>
    </Container>
  );
};

export default Church;
