import styled from "styled-components";

export const Container = styled.div`
    body {
        color: var(--color-neutral-6) !important;
    }
    .fullName, .infoStyle {
        color: var(--color-neutral-6) !important;
    }
    .signBox {
        margin-top: 10px;
        border-color: var(--color-primary-2) !important;
    }
    .loginBtn {
        color: var(--color-primary-2) !important;
        border-color: var(--color-primary-2) !important;
        @media screen and (max-width: 578px) {
            transform: translate(10px, 10px);
        }
        @media screen and (max-width: 396px) {
            transform: translate(10px, 20px);
        }
    }
    .signBtn {
        display: none;
    }
    .replyBtn {
        width: 110px !important;
    }
    .replySection {
        .replyBtn {
            opacity: 0;
            pointer-events: none;
        }
    }
    .rdw-editor-main {
        overflow-y: auto;
        overflow-x: hidden;
    }
    .advanced-border {
        max-width: 256px;
    }
`;
