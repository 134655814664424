import styled from 'styled-components';

export const Container = styled.div`
    margin-block: 20px;
`;

export const Event = styled.div`
  display: flex;
  justify-content: space-between;
  margin-block: 20px;
`;

export const EventTitle = styled.div`
  color: var(--color-neutral-6);
  display: flex;
  font-size: 16px;
  font-weight: 600;
`;

export const Input = styled.input`
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ced4da;
`;

export const Select = styled.select`
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ced4da;
`;

export const Option = styled.option`
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ced4da;
`;

export const OptionButton = styled.button`
  display: block;
  width: 100%;
  height: 41px;
  padding: 10px;
  margin-inline: 0;
  background-color: var(--color-primary-2);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
`;

export const IconButton = styled.button`
  display: block;
  background-color: transparent;
  color: var(--color-system-1);
  border: none;
  cursor: pointer;
`;

