import { createGlobalStyle } from 'styled-components';

// eslint-disable-next-line no-shadow
export enum ColorPallete {
  PRIMARY_1 = 'rgba(154, 177, 200, 1)',
  PRIMARY_2 = 'rgba(9, 65, 108, 1)',
  PRIMARY_3 = 'rgba(230, 231, 231, 1)',
  PRIMARY_4 = 'rgba(155, 178, 202, 1)',
  NEUTRAL_1 = 'rgba(24, 25, 29, 1)',
  NEUTRAL_2 = 'rgba(252, 252, 252, 1)',
  NEUTRAL_3 = 'rgba(128, 128, 128, 1)',
  NEUTRAL_4 = 'rgba(214, 214, 214, 1)',
  NEUTRAL_5 = 'rgba(29, 32, 36, 1)',
  NEUTRAL_6 = 'rgba(42, 43, 49, 1)',
  SYSTEM_1 = 'rgba(193, 30, 15, 1)',
  SYSTEM_2 = 'rgba(242, 146, 27, 1)',
  SYSTEM_3 = 'rgba(74, 144, 226, 1)',
  SYSTEM_4 = 'rgba(73, 74, 167, 1)',
  SYSTEM_5 = 'rgba(117, 184, 49, 1)',
  THEME_1 = 'rgba(177, 40, 65, 1)',
  THEME_2 = 'rgba(229, 227, 229, 1)',
  THEME_3 = 'rgba(168, 167, 167, 1)',
  THEME_4 = 'rgba(204, 135, 151, 1)',
  THEME_5 = 'rgba(134, 112, 120, 1)',
  THEME_6 = 'rgba(135, 135, 135, 1)',
  THEME_7 = 'rgba(216, 178, 190, 1)',
  THEME_8 = 'rgba(196, 100, 124, 1)',
  THEME_9 = 'rgba(152, 154, 136, 1)',
  THEME_10 = 'rgba(254, 121, 64, 1)',
}

export function applyAlpha(color: ColorPallete, alpha: number): string {
  return color + Math.round(alpha * 255).toString(16);
}

export default createGlobalStyle`
  :root {
    color-scheme: light;
    --color-primary-1: ${ColorPallete.PRIMARY_1};
    --color-primary-2: ${ColorPallete.PRIMARY_2};
    --color-primary-3: ${ColorPallete.PRIMARY_3};
    --color-primary-4: ${ColorPallete.PRIMARY_4};
    --color-neutral-1: ${ColorPallete.NEUTRAL_1};
    --color-neutral-2: ${ColorPallete.NEUTRAL_2};
    --color-neutral-3: ${ColorPallete.NEUTRAL_3};
    --color-neutral-4: ${ColorPallete.NEUTRAL_4};
    --color-neutral-5: ${ColorPallete.NEUTRAL_5};
    --color-neutral-6: ${ColorPallete.NEUTRAL_6};
    --color-system-1: ${ColorPallete.SYSTEM_1};
    --color-system-2: ${ColorPallete.SYSTEM_2};
    --color-system-3: ${ColorPallete.SYSTEM_3};
    --color-system-4: ${ColorPallete.SYSTEM_4};
    --color-system-5: ${ColorPallete.SYSTEM_5};
    --color-theme-1: ${ColorPallete.THEME_1};
    --color-theme-2: ${ColorPallete.THEME_2};
    --color-theme-3: ${ColorPallete.THEME_3};
    --color-theme-4: ${ColorPallete.THEME_4};
    --color-theme-5: ${ColorPallete.THEME_5};
    --color-theme-6: ${ColorPallete.THEME_6};
    --color-theme-7: ${ColorPallete.THEME_7};
    --color-theme-8: ${ColorPallete.THEME_8};
    --color-theme-9: ${ColorPallete.THEME_9};
    --color-theme-10: ${ColorPallete.THEME_1};
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
  }

  body {
    background: var(--color-neutral-2);
    /* color: #fff; */
    -webkit-font-smoothing: antialiased;
  }

  html, body {
    height: 100%;
  }

  body,
  input,
  button {
    font-family: 'Nunito', Arial, Helvetica, sans-serif;
    font-size: 16px;
  }

  .no-user-select {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  ::-webkit-scrollbar{
    width: 10px;
    height: 10px;
    border-radius: 50px;
    background-color: var(--color-neutral-3);
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 50px;
    background-color: var(--color-system-3)
  }

  ::-webkit-scrollbar-corner {
    background: var(--color-neutral-1);
  }

  .react-responsive-modal-modal h2, .react-responsive-modal-modal p {
    margin-bottom: 10px;
  }
  .react-responsive-modal-container div {
    border-radius: 6px !important;
    max-width: fit-content !important;
  }
  .deleteBtns {
    margin-inline: auto;
  }
`;
