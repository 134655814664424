import React from 'react';
import { Pill } from 'components';
import Dove from 'assets/images/Dove.svg';
import { Container, MenuContent, Image, ImageContainer, Title, ContentContainer } from './styles';

const Communion: React.FC = () => {

  return (
    <Container>
     <Title>Comunhão</Title>
     <ImageContainer>
       <Image draggable="false" src={Dove} alt="dove" />
     </ImageContainer>
      <ContentContainer>
        <MenuContent>
          <Pill
            mode2
            imageKey="Services"
            title="Cultos"
            text="Horários de Culto, Menssagem, Pregadores."
          />
          <Pill
            mode2
            imageKey="SBS"
            title="Escola Discipuladora"
            text="Horários, Turmas, Estudos."
          />
          <Pill
            mode2
            imageKey="SG"
            title="Células"
            text="Encontre uma célula perto de você!"
          />
          <Pill
            mode2
            imageKey="Activities"
            title="Atividades"
            text="Agenda de atividades!"
          />
          <Pill
            mode2
            imageKey="Birthdays"
            title="Aniversários"
            text="Aniversariantes do mês!"
          />
        </MenuContent>
      </ContentContainer>
    </Container>
  );
};

export default Communion;
