import React, { useState, useEffect } from 'react';
import { db } from '../../firebaseConfig';
import { doc, updateDoc, arrayUnion, getDoc } from 'firebase/firestore';
import { CommentSection } from 'react-comments-section-pt-br';
import 'react-comments-section-pt-br/dist/index.css';
import { useAuth } from 'providers/AuthProvider';
import { Container } from './styles';

const NotificationCommentsModal: React.FC<{ 
  notificationId: string,
  isOpen: boolean,
}> = ({ notificationId, isOpen }) => {
  const { currentUser } = useAuth();
  const [comments, setComments] = useState<any>([]);

  const fetchComments = async () => {
    const docRef = doc(db, "notifications", notificationId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      setComments(docSnap.data().comments || []);
    } else {
      console.log("No such document!");
    }
  };

  useEffect(() => {
    if (isOpen) {
      fetchComments();
    }
  }, [isOpen, notificationId]);

  const handleNewComment = async (newCommentData) => {
    const docRef = doc(db, "notifications", notificationId);
    await updateDoc(docRef, {
      comments: arrayUnion(newCommentData),
    });

    setComments(prev => [...prev, newCommentData]);
  };

  const handleReply = async (text, commentId) => {
    if (!currentUser || !currentUser.displayName) {
      console.error('Current user or displayName is undefined');
      return;
    }

    const reply = {
        avatarUrl: currentUser.photoURL || '',
        comId: new Date().getTime().toString(),
        fullName: currentUser.displayName || 'Anonymous',
        parentOfRepliedCommentId: notificationId,
        repliedToCommentId: commentId,
        text,
        userId: currentUser.uid,
        userProfile: null
    };
  
    const notificationRef = doc(db, 'notifications', notificationId);
    const notificationSnap = await getDoc(notificationRef);
  
    if (notificationSnap.exists()) {
      const notificationData = notificationSnap.data();
      const comments = notificationData.comments || [];

      const commentIndex = comments.findIndex(comment => comment.comId === commentId);

      if (commentIndex !== -1) {
        const comment = comments[commentIndex];
        comment.replies = [...(comment.replies || []), reply];
  
        comments[commentIndex] = comment; // Ensure the comment is updated
  
        try {
          await updateDoc(notificationRef, { comments });
          fetchComments();
          console.log('Reply added successfully');
        } catch (error) {
          console.error('Error adding reply:', error);
        }
      } else {
        console.error('Comment not found');
      }
    } else {
      console.error('Notification not found');
    }
  };

  const handleEdit = async (newText, itemId) => {
    let type = '';

    if (typeof itemId === 'string') {
        type = itemId.includes('-') ? 'comment' : /^\d+$/.test(itemId) ? 'reply' : '';
    }

    const notificationRef = doc(db, 'notifications', notificationId);
  
    try {
      const snapshot = await getDoc(notificationRef);
      if (!snapshot.exists()) {
        console.error('Notification not found');
        return;
      }
  
      let data = snapshot.data();
      if (type === 'comment') {
        // Update the targeted comment
        const updatedComments = data.comments.map(comment => {
          return comment.comId === itemId ? {...comment, text: newText} : comment;
        });
        await updateDoc(notificationRef, { comments: updatedComments });
        console.log('Comment updated successfully');
      } else if (type === 'reply') {
        // Update the targeted reply within the correct comment
        const updatedComments = data.comments.map(comment => {
          const updatedReplies = comment.replies.map(reply => {
            return reply.comId === itemId ? {...reply, text: newText} : reply;
          });
          return {...comment, replies: updatedReplies};
        });
        await updateDoc(notificationRef, { comments: updatedComments });
        console.log('Reply updated successfully');
      }
    } catch (error) {
      console.error('Error updating document:', error);
    }
  };  
  
  const handleDelete = async (itemId) => {
    const notificationRef = doc(db, 'notifications', notificationId);
  
    try {
      const snapshot = await getDoc(notificationRef);
      if (!snapshot.exists()) {
        console.error('Notification not found');
        return;
      }
  
      let data = snapshot.data();
      let comments = data.comments || [];
      let isDeleted = false;
  
      // Attempt to delete as a reply first
      const updatedComments = comments.map(comment => {
        if (comment.replies) {
          const initialRepliesLength = comment.replies.length;
          const filteredReplies = comment.replies.filter(reply => reply.comId !== itemId);
          if (initialRepliesLength > filteredReplies.length) {
            isDeleted = true; // Mark as deleted if changes were made
            return {...comment, replies: filteredReplies};
          }
        }
        return comment;
      });
  
      if (!isDeleted) {
        // If no reply was deleted, try deleting as a comment
        const filteredComments = comments.filter(comment => comment.comId !== itemId);
        if (comments.length > filteredComments.length) {
          isDeleted = true;
        }
        await updateDoc(notificationRef, { comments: filteredComments });
      } else {
        // Update with possibly modified replies
        await updateDoc(notificationRef, { comments: updatedComments });
      }
  
      console.log(isDeleted ? 'Item deleted successfully' : 'No item found with given ID');
    } catch (error) {
      console.error('Error deleting item:', error);
    }
  };

  return isOpen ? (
    <Container>
        <CommentSection
          currentUser={currentUser ? {
            currentUserId: currentUser?.uid,
            currentUserImg: currentUser?.photoURL,
            currentUserProfile: '',
            currentUserFullName: currentUser?.displayName,
          } : null}
          onReplyAction={(data) => handleReply(data.text, data.repliedToCommentId)}
          onEditAction={(data) => handleEdit(data.text, data.comId)}
          onDeleteAction={(data) => handleDelete(data.comIdToDelete)}
          logIn={{
            loginLink: './config',
            signupLink: '',
          }}
          commentData={comments}
          onSubmitAction={(data) => handleNewComment(data)}
          hrStyle={{ border: '0.5px solid var(--color-system-4)' }}
          inputStyle={{ border: '1px solid rgb(208 208 208)' }}
          formStyle={{ color: 'black', backgroundColor: 'transparent' }}
          submitBtnStyle={{
            border: '1px solid black',
            backgroundColor: 'black',
            padding: '7px 15px'
          }}
          cancelBtnStyle={{
            border: '1px solid gray',
            backgroundColor: 'gray',
            color: 'white',
            padding: '7px 15px'
          }}
          advancedInput
          replyInputStyle={{ borderBottom: '1px solid black', color: 'var(--color-neutral-6)' }}
        />
    </Container>
  ) : null;
};

export default NotificationCommentsModal;
