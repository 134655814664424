import styled from 'styled-components';

export const Container = styled.div`
  height: 220px;
  width: 170px;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0px 8px 8px #8163d626;
  min-height: 116px;
  position: relative;
  min-width: 170px;
  display: block;
  user-select: none;
  object-fit: contain;
`;

export const Group = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
`;

export const Overlap = styled.div`
  height: 100%;
  position: relative;
  width: 100%;
`;

export const MaskGroup = styled.div<{ url: string }>`
  background-image: url(${({ url }) => url || ''});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 12px 12px 0 0;
  position: relative;
  width: 100%;
  height: 170px;
  left: 0;
  top: 0;
`;

export const Image = styled.img`
  height: 57px;
  left: 56.5px;
  position: relative;
  top: -113.5px;
  width: 57px;
`;

export const TextWrapper = styled.div`
  position: relative;
  top: -56px;
  left: 7px;
  max-width: 110px;
  height: 40px;
  max-height: 40px;
  color: var(--color-neutral-1);
  font-family: 'Roboto-Medium', Helvetica;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: -0.17px;
  line-height: 13.5px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const OverlapGroupWrapper = styled.div`
  height: 18px;
  left: 125px;
  position: relative;
  top: -94px;
  width: 39px;
  background-color: var(--color-neutral-2);
`;

export const OverlapGroup = styled.div`
  height: 25px;
  left: -4px;
  position: relative;
  width: 45px;
`;

export const Time = styled.img`
  height: 24px;
  width: 45px;
`;

export const TextWrapper2 = styled.div`
  color: #ffffff;
  font-family: 'Poppins-Medium', Helvetica;
  font-size: 9px;
  font-weight: bold;
  left: 5px;
  letter-spacing: -0.17px;
  line-height: 18px;
  text-align: center;
  position: relative;
  top: -28px;
  width: 35px;
  white-space: nowrap;
`;
