import React, { useEffect, useState } from 'react';
import { FaPlus, FaTrash } from 'react-icons/fa';
import { Container, Event, EventTitle, IconButton, Input, Select, Option, OptionButton  } from './styles';

interface Field {
  id: string;
  name: string;
  type: 'text' | 'checkbox' | 'select';
  options?: string[];
}

interface FormField {
    fields: Field[]; 
    onFieldsChange: (fields: Field[]) => void;
}

const CustomFormField: React.FC<FormField> = ({ fields, onFieldsChange }) => {
  const [localFields, setLocalFields] = useState<Field[]>(fields);

  useEffect(() => {
    onFieldsChange(localFields);
  }, [localFields]);
    
  const addField = () => {
    setLocalFields([
      ...localFields,
      { id: Math.random().toString(36).substring(7), name: '', type: 'text', options: [] }
    ]);
  };

  const removeField = (id: string) => {
    setLocalFields(localFields.filter(field => field.id !== id));
  };

  const handleFieldChange = (index: number, field: Partial<Field>) => {
    const newFields = [...localFields];
    newFields[index] = { ...newFields[index], ...field };
    setLocalFields(newFields);
  };

  const handleOptionChange = (fieldIndex: number, optionIndex: number, value: string) => {
    const newFields = [...localFields];
    if (newFields[fieldIndex].options) {
      const updatedOptions = [...newFields[fieldIndex].options!];
      updatedOptions[optionIndex] = value;  // Atualiza o valor da opção com o que o usuário digitar
      newFields[fieldIndex].options = updatedOptions;
  
      setLocalFields(newFields);  // Atualiza o estado com as novas opções
    }
  };

  const addOption = (index: number) => {
    const newFields = [...localFields];
    if (newFields[index].options) {
      const updatedOptions = [...newFields[index].options!];
      
      // Adiciona uma nova opção vazia e aguarda o usuário digitar um valor
      updatedOptions.push('');
      newFields[index].options = updatedOptions;
  
      setLocalFields(newFields);
    } else {
      // Inicializa o array de opções caso ele ainda não exista
      newFields[index].options = [''];
      setLocalFields(newFields);
    }
  };  

  return (
    <Container>
      {localFields.map((field, index) => (
        <div key={field.id}>
          <EventTitle>{field.name === '' ? `Campo ${index + 1}` : field.name}</EventTitle>
          <Event style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Input
              style={{ width: '50%' }}
              type="text"
              placeholder="Nome do campo"
              value={field.name}
              onChange={(e) => handleFieldChange(index, { name: e.target.value })}
              required
            />

            <Select
              value={field.type}
              onChange={(e) => handleFieldChange(index, { type: e.target.value as Field['type'] })}
            >
              <Option value="text">Texto Curto</Option>
              <Option value="checkbox">Checkbox</Option>
              <Option value="select">Select</Option>
            </Select>

            <IconButton onClick={() => removeField(field.id)}>
              <FaTrash size={24} />
            </IconButton>
          </Event>

          {field.type === 'select' && (
            <div style={{ marginBottom: '10px' }}>
              {field.options?.map((option, optionIndex) => (
                <div key={optionIndex} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                  <Input
                    type="text"
                    placeholder="Opção"
                    value={option}
                    onChange={(e) => handleOptionChange(index, optionIndex, e.target.value)}
                  />
                </div>
              ))}
              <OptionButton type='button' onClick={() => addOption(index)}>
                <FaPlus /> Adicionar Opção
              </OptionButton>
            </div>
          )}
        </div>
      ))}

      <OptionButton type='button' onClick={addField}>
        <FaPlus /> Adicionar Campo
      </OptionButton>
    </Container>
  );
};

export default CustomFormField;
