import React, { useEffect, useState } from 'react';
import { db } from '../../firebaseConfig';
import { useAuth } from 'providers/AuthProvider';
import { collection, query, onSnapshot, Timestamp, addDoc, deleteDoc, doc, updateDoc, writeBatch, where, arrayUnion, getDoc } from 'firebase/firestore';
import { Notification as Note } from 'components';
import { Button, Container, GroupMessages, Header, Text } from './styles';
import NotificationModal from 'components/NotificationModal';

const Notification: React.FC = () => {
  const [notifications, setNotifications] = useState<any[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedNotification, setSelectedNotification] = useState<any>();

  const { currentUser, isAdmin } = useAuth();

  useEffect(() => {
    const q = query(collection(db, 'notifications'));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const notificationsData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setNotifications(notificationsData);
    });

    return () => unsubscribe();
  }, []);

  const createNotification = async (message) => {
    try {
      await addDoc(collection(db, 'notifications'), {
        profileURL: currentUser?.photoURL,
        name: currentUser?.displayName,
        message: message,
        creationTime: Timestamp.fromDate(new Date()),
        likes: [],
        comments: [],
        seen: [] // Initialize as empty array
      });
      console.log("Notification created successfully");
    } catch (error) {
      console.error("Error creating notification: ", error);
    }
  };

  const updateNotification = async (notificationId, newMessage) => {
    try {
      const notificationRef = doc(db, 'notifications', notificationId);
      await updateDoc(notificationRef, {
        message: newMessage,
      });
      console.log("Notification updated successfully");
    } catch (error) {
      console.error("Error updating notification: ", error);
    }
  };

  const deleteNotification = async (notificationId) => {
    try {
      const notificationRef = doc(db, 'notifications', notificationId);
      await deleteDoc(notificationRef);
      console.log("Notification deleted successfully");
    } catch (error) {
      console.error("Error deleting notification: ", error);
    }
  };

  const markNotificationsAsSeen = async (notificationIds) => {
    const userUid = currentUser?.uid;
    if (!userUid) {
      console.error("No user logged in");
      return;
    }
  
    try {
      const batch = writeBatch(db);
      const notifications = await Promise.all(
        notificationIds.map(id => getDoc(doc(db, 'notifications', id)))
      );
  
      notifications.forEach((notificationSnapshot, index) => {
        if (notificationSnapshot.exists()) {
          const notificationData = notificationSnapshot.data();
          if (!notificationData.seen || !notificationData.seen.includes(userUid)) {
            const notificationRef = doc(db, 'notifications', notificationIds[index]);
            batch.update(notificationRef, { seen: arrayUnion(userUid) });
            console.log("Notifications marked as seen successfully");
          }
        }
      });
  
      await batch.commit();
    } catch (error) {
      console.error("Error marking notifications as seen: ", error);
    }
  };
  
  
  useEffect(() => {
    const q = query(collection(db, 'notifications'));

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const loadedNotifications = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      const ids = loadedNotifications.map(notification => notification.id);
      if (ids.length > 0) {
        markNotificationsAsSeen(ids);
      }
    });

    return () => unsubscribe();
  }, []);

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedNotification(null);
  };

  const handleSaveNotification = (message) => {
    if (selectedNotification) {
      updateNotification(selectedNotification.id, message);
      handleCloseModal();
    } else {
      createNotification(message);
      handleCloseModal();
    }
  };

  const handleDeleteNotification = () => {
    deleteNotification(selectedNotification.id);
    handleCloseModal();
  };
  
  const handleOptionsClick = (notificationId) => {
    if (isAdmin) {
      const notification = notifications.find(n => n.id === notificationId);
      setSelectedNotification(notification);
      setShowModal(true);
    }
  };


  return (
    <Container>
      <Header>
        <Text>Avisos</Text>
      </Header>
      <GroupMessages>
        <Button onClick={() => setShowModal(true)} hide={!isAdmin}>Adicionar Aviso</Button>
        {notifications.length === 0 && <div style={{ padding: '20px', fontSize: '28px', fontWeight: '600', textAlign: 'center', width: '100%' }}>Nenhuma notificação no momento!</div>}
        {notifications.map((note) => (
          <Note
            key={note.id}
            id={note.id}
            profileURL={note.profileURL}
            name={note.name}
            creationTime={note.creationTime}
            message={note.message}
            likes={note.likes}
            comments={note.comments}
            onOptionsClick={handleOptionsClick}
          />
        ))}
      </GroupMessages>
      {showModal && (
        <NotificationModal
          open={showModal}
          onClose={handleCloseModal}
          notificationData={selectedNotification}
          onSave={handleSaveNotification}
          onDelete={handleDeleteNotification}
        />
      )}
    </Container>
  );
};

export default Notification;
