import styled, { css } from 'styled-components';

export const PrayerRequestForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 8px;
  padding: 20px;
  width: 100%;
  max-width: 480px;
  margin-inline: auto;
  font-family: 'Nunito Sans', sans-serif !important;
`;

export const PrayerRequestInput = styled.input`
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ced4da;
`;

export const Button = styled.button<{ hide?: boolean; crud: boolean }>`
  display: block;
  height: 33px;
  padding: 10px;
  margin: 10px;
  background-color: var(--color-primary-2);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  ${({ hide }) =>
    hide &&
    css`
      display: none !important;
  `}
  ${({ crud }) =>
    crud &&
    css`
      width: 350px;
      margin-inline: auto !important;
    `}
`;

export const PrayerRequestButton = styled(Button)`
  background-color: var(--color-primary-2);
  height: 41px;
  width: 100%;
  margin-inline: 0;
  margin-bottom: 50px;
`;

export const PrayerRequestTitle = styled.div`
  color: var(--color-neutral-6);
  font-family: 'Nunito', sans-serif !important;
  font-weight: bolder;
  color: var(--color-neutral-6);
  font-size: 28px;
  text-align: left;
  margin-top: 15px; // 35px;
`;

export const PrayerRequestLabel = styled.div`
  color: var(--color-neutral-6);
  display: flex;
  align-items: center;
  margin: 10px 0;
  font-size: 16px;
  font-weight: 600;
`;

export const PrayerRequestDescription = styled.textarea`
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ced4da;
  min-height: 100px;
  min-width: 100%;
  max-width: 100%;
  font-family: 'Nunito Sans', sans-serif !important;
  font-size: 16px;
`;

export const MessageText = styled.div`
  color: var(--color-neutral-6);
  font-family: 'Popins', sans-serif;
  font-size: 15px;
  font-weight: 400;
  padding-top: 2px;
  span {
    color: var(--color-neutral-2) !important;
  }
`
