import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Content, Header, Month, Text } from './styles';
import { Grid, Card, CardContent, Typography, IconButton } from '@mui/material';
import axios from 'axios';
import { FaWhatsapp } from 'react-icons/fa';
import { BackButton } from 'components';

interface Birthday {
  name: string;
  day: string; // Expected format: dd/MM
  phoneNumbers: string[]; // List of valid phone numbers
}

const formattedDate = (dateString: string): Date => {
  const [day, month] = dateString.split('/');
  const year = new Date().getFullYear();

  return new Date(year, Number(month) - 1, Number(day));
};

const isPhoneNumberValid = (phone: string): boolean => {
  // Remove non-numeric characters and check if it starts with '(xx) 9'
  return /^\(\d{2}\)\s9/.test(phone.trim());
};

// Process phone numbers and ensure they are valid
const processPhoneNumbers = (phoneNumbers: string[]): string[] => {
  return phoneNumbers
    .map((phone) => phone.trim())
    .filter(isPhoneNumberValid); // Keep only valid phone numbers
};


const BirthdayCard = ({ birthday }: { birthday: Birthday }) => {
  const sendBirthdayMessage = (phone: string) => {
    const message = `Querido(a) ${birthday.name},
      Hoje celebramos não apenas mais um ano de sua vida, mas também a graça divina que tem sido tão evidente em seu caminhar. Dentro da nossa congregação batista confessional, vemos o amor de Cristo refletido em suas ações e no seu compromisso com a fé.
      Que este aniversário traga um novo ano de crescimento espiritual, alegrias e realizações no Senhor. Que a Palavra de Deus continue sendo a lâmpada para os seus pés e a luz para o seu caminho.
      Feliz aniversário! Que a paz do Senhor esteja sempre com você.
    `;
    const url = `https://wa.me/55${phone.replace(/[^0-9]/g, '')}?text=${encodeURIComponent(message)}`;
    window.open(url, '_blank');
  };

  return (
    <Card style={{ width: '350px', marginInline: 'auto', marginBlock: '15px' }}>
      <CardContent>
        <Typography style={{ fontFamily: 'Nunito, sans-serif' }} variant="h6">
          <b>{birthday.name}</b>
        </Typography>
        <Typography style={{ fontFamily: 'Nunito, sans-serif' }} variant="body1">
          <b>Aniversário:</b> {birthday.day}
        </Typography>
        <Typography style={{ fontFamily: 'Nunito, sans-serif' }} variant="body1">
          <b>*</b> Envie uma mensagem comemorativa:
        </Typography>
        {birthday.phoneNumbers.map((phone, index) => (
          <IconButton key={index} onClick={() => sendBirthdayMessage(phone)}>
            <FaWhatsapp color='var(--color-system-5)'/>
            &nbsp;<a>{phone}</a>
          </IconButton>
        ))}
      </CardContent>
    </Card>
  );
};

const BirthdayCards = ({ birthdays }: { birthdays: Birthday[] }) => {
  const currentMonth = new Date().getMonth();
  const filteredBirthdays = birthdays.filter(
    (birthday) => formattedDate(birthday.day).getMonth() === currentMonth
  );

  const monthNumberToLabelMap = {
    [1]: 'Janeiro',
    [2]: 'fevereiro',
    [3]: 'Março',
    [4]: 'Abril',
    [5]: 'Maio',
    [6]: 'Junho',
    [7]: 'Julho',
    [8]: 'Agosto',
    [9]: 'Setembro',
    [10]: 'Outubro',
    [11]: 'Novembro',
    [12]: 'Dezembro',
  }

  return (
    <Grid container spacing={2}>
      <Month>Aniversariantes de {monthNumberToLabelMap[currentMonth + 1]}</Month>
      {filteredBirthdays.sort((a, b) => Number(a.day.split('/')[0]) - Number(b.day.split('/')[0])).map((birthday, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <BirthdayCard birthday={birthday} />
        </Grid>
      ))}
    </Grid>
  );
};

const Birthdays: React.FC = () => {
  const navigate = useNavigate();
  const [birthdays, setBirthdays] = useState<Birthday[]>([]);
  const RANGE = 'A2:C137';

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://sheets.googleapis.com/v4/spreadsheets/${process.env.REACT_APP_SHEETS_ID_BIRTHDAYS}/values/${RANGE}?key=${process.env.REACT_APP_SHEETS_API_KEY}`
        );
        const data = response.data.values;

        const processedData = data.map((item: any) => {
          const rawPhoneNumbers = item[1].split('/');
          const validPhoneNumbers = processPhoneNumbers(rawPhoneNumbers);
    
          return {
            name: item[0],
            day: item[2],
            phoneNumbers: validPhoneNumbers,
          };
        });
    
        setBirthdays(processedData);
      } catch (error) {
        console.error('Error fetching data:', error);
        return [];
      }
    };

    fetchData();
  }, []);

  return (
    <Container>
      <Header>
        <Text>Aniversariantes</Text>
      </Header>
      <Content>
        <BirthdayCards birthdays={birthdays} />
      </Content>
      <BackButton goBack={() =>  navigate(-1)} />
    </Container>
  );
};

export default Birthdays;
