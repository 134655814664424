import styled, { css } from 'styled-components';

export const Container = styled.div`
  overflow: hidden;
  height: calc(100vh - 56px);
  overflow: hidden;
  @media (max-width: 1024px) {
    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none; /* For Internet Explorer and Edge */
    ::-webkit-scrollbar {
      display: none; /* For Chrome, Safari and Opera */
    }
  }
  .mapCells {
    position: fixed !important;
    bottom: 56px;
  }
`;

export const Content = styled.div<{ isAdmin: boolean }>`
  height: 100%;
  width: 100%;
  position: relative;
  margin-top: 68px;
  .MuiGrid-container {
    height: calc(100% - 370px);
    position: relative;
    margin-top: 68px;
    overflow-y: auto;
    overflow-x: hidden;
    ${({ isAdmin }) =>
      isAdmin &&
      css`
        margin-top: 0px !important;
        height: calc(100% - 443px) !important;
    `}
  }
`

export const Button = styled.div<{ dialogActions?: boolean }>`
  display: block;
  height: 33px;
  padding: 10px;
  margin-bottom: 20px;
  background-color: var(--color-primary-2);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 300px;
  text-align: center;
  font-weight: 600;
  font-family: 'Nunito', sans-serif;
  margin-inline: auto !important;
  line-height: 1;
  ${({ dialogActions }) =>
    dialogActions &&
    css`
      width: 120px;
  `}
`;

export const Header = styled.div`
  background-color: var(--color-primary-2);
  height: 68px;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2;
`;

export const Text = styled.div`
  color: var(--color-neutral-2);
  font-family: 'Nunito-Bold', Helvetica;
  font-size: 28px;
  font-weight: 700;
  left: 23px;
  letter-spacing: -0.17px;
  line-height: 18px;
  position: fixed;
  text-align: center;
  top: 25px;
  white-space: nowrap;
`;
