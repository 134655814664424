import React, { useEffect, useState } from "react";
import { db } from "../../firebaseConfig";
import {
  getDoc,
  updateDoc,
  doc,
} from "firebase/firestore";
import {
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Card,
  CardContent,
  Grid,
  Typography,
  IconButton,
} from "@mui/material";
import CellsMap from "components/CellsMap"; // Reference your map component
import { Container, Button, Header, Text, Content } from "./styles";
import { useAuth } from "providers/AuthProvider";
import { v4 as uuidv4 } from "uuid";
import { FaTrash } from "react-icons/fa";
import BackButton from "components/BackButton";
import { useNavigate } from "react-router-dom";

interface Cell {
  id?: string;
  Name: string;
  WeekDay: string;
  Time: string;
  Address: string;
  Leader: string;
  Contact: string;
}

interface Cells {
  cells: Cell[];
}

const initialCell: Cell = {
  Name: "",
  WeekDay: "",
  Time: "",
  Address: "",
  Leader: "",
  Contact: "",
};

const CellCard = ({ cell, onEdit, onDelete, status }) => {
  return (
    <Card style={{ width: '350px', marginInline: 'auto', marginBlock: '15px' }}>
      <CardContent>
        <Typography style={{ fontFamily: 'Nunito, sans-serif' }} variant="h6"><b>{cell.Name}</b></Typography>
        <Typography style={{ fontFamily: 'Nunito, sans-serif' }} variant="body2"><b>Dia da Semana:</b> {cell.WeekDay}</Typography>
        <Typography style={{ fontFamily: 'Nunito, sans-serif' }} variant="body2"><b>Horário:</b> {cell.Time}</Typography>
        <Typography style={{ fontFamily: 'Nunito, sans-serif' }} variant="body2"><b>Endereço:</b> {cell.Address}</Typography>
        <Typography style={{ fontFamily: 'Nunito, sans-serif' }} variant="body2"><b>Líder:</b> {cell.Leader}</Typography>
        <Typography style={{ fontFamily: 'Nunito, sans-serif' }} variant="body2"><b>Contato:</b> {cell.Contact}</Typography>
        {status && (
          <>
            <br />
            <Button onClick={() => onEdit(cell)}>Editar</Button>
            <IconButton color="error" onClick={() => onDelete(cell.id)}>
              <FaTrash color='#C11E0F' size='24px' />
            </IconButton>
            {/* <Button onClick={() => onDelete(cell.id)}>Deletar</Button> */}
          </>
        )}
      </CardContent>
    </Card>
  );
};

const CellCards = ({ cells, handleOpenDialog, handleDeleteCell, isAdmin }) => {
  return (
    <Grid container spacing={2}>
      {cells.cells?.map((cell, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <CellCard
            cell={cell}
            onEdit={handleOpenDialog}
            onDelete={handleDeleteCell}
            status={isAdmin}
          />
        </Grid>
      ))}
    </Grid>
  );
};

const SG: React.FC = () => {
  const { isAdmin } = useAuth();
  const navigate = useNavigate();
  const [cells, setCells] = useState<Cells>({ cells: [initialCell] });
  const [selectedCell, setSelectedCell] = useState<any>();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  // Fetching cells from Firestore
  useEffect(() => {
    const fetchCells = async () => {
      const cellsRef = doc(db, "cells", "pcpdCsp7qxkSl5sfYtLe"); // Adjust document ID
      const cellSnapshot = await getDoc(cellsRef);

      if (cellSnapshot.exists()) {
        setCells(cellSnapshot.data() as Cells);
      }
    };

    fetchCells();
  }, []);

  // Open dialog to edit or create a cell
  const handleOpenDialog = (cell: Cell | null) => {
    setSelectedCell(cell || { ...initialCell });
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleAddOrUpdateCell = async (cell: Cell) => {
    const updatedCells = { ...cells };
    const existingCellIndex = updatedCells.cells.findIndex(
      (c) => c.Name === cell.Name
    );

    if (existingCellIndex !== undefined && existingCellIndex > -1) {
      updatedCells.cells[existingCellIndex] = { id: uuidv4(), ...cell };
    } else {
      updatedCells.cells.push({ id: uuidv4(), ...cell });
    }

    const verify = updatedCells.cells.map(c => {
      if (
        c &&
        c.Address?.trim() &&
        c.Contact?.trim() &&
        c.Leader?.trim() &&
        c.Name?.trim() &&
        c.Time?.trim() &&
        c.WeekDay?.trim()
      ) {
        return true;
      } else return false;
    })

    if (!verify.includes(false)) {
      try {
        const cellsRef = doc(db, 'cells', 'pcpdCsp7qxkSl5sfYtLe');
        await updateDoc(cellsRef, updatedCells);

        setCells(updatedCells);
        handleCloseDialog();
      } catch (error) {
        console.error("Error updating document:", error);
      }
    }
  };

  const handleDeleteCell = async (cellId?: string) => {
    const confirmation = window.confirm("Tem certeza de que deseja excluir essa célula?");
  
    if (confirmation) {
      try {
        const docRef = doc(db, 'cells', 'pcpdCsp7qxkSl5sfYtLe'); // Update the correct collection and document
  
        // Get the current state of the document
        const docSnap = await getDoc(docRef);
  
        if (docSnap.exists()) {
          // Get the current cells array
          let cells = docSnap.data().cells;
  
          // Remove the cell with the matching ID
          cells = cells.filter(cell => cell.id !== cellId);
  
          // Update the document with the new cells array
          await updateDoc(docRef, { cells });
  
          console.log("Item deleted successfully.");
        }
      } catch (error) {
        console.error("Error deleting item:", error);
      }
    }
  };

  return (
    <Container>
      <Header>
        <Text>Células</Text>
      </Header>
      <Content isAdmin={isAdmin}>
        {isAdmin && <><br /><Button onClick={() => handleOpenDialog(null)}>Adicionar nova Célula</Button></>}
        <CellCards cells={cells} handleOpenDialog={handleOpenDialog} handleDeleteCell={handleDeleteCell} isAdmin={isAdmin} />
        {isDialogOpen && (
          <Dialog open={isDialogOpen} onClose={handleCloseDialog}>
            <DialogTitle>
              {selectedCell?.id ? "Editar Célula" : "Adicionar nova Célula"}
            </DialogTitle>
            <DialogContent>
              <TextField
                style={{ marginBottom: '15px' }}
                required
                helperText="Esse campo é obrigatório"
                fullWidth
                label="Nome"
                value={selectedCell?.Name || ""}
                onChange={(e) => {
                  setSelectedCell({ ...selectedCell, Name: e.target.value });
                }}
              />
              <TextField
                style={{ marginBottom: '15px' }}
                required
                helperText="Esse campo é obrigatório"
                fullWidth
                label="Dia da Semana"
                value={selectedCell?.WeekDay || ""}
                onChange={(e) => {
                  setSelectedCell({ ...selectedCell, WeekDay: e.target.value });
                }}
              />
              <TextField
                style={{ marginBottom: '15px' }}
                required
                helperText="Esse campo é obrigatório"
                fullWidth
                label="Horário"
                value={selectedCell?.Time || ""}
                onChange={(e) => {
                  setSelectedCell({ ...selectedCell, Time: e.target.value });
                }}
              />
              <TextField
                style={{ marginBottom: '15px' }}
                required
                helperText="Esse campo é obrigatório"
                fullWidth
                label="Endereço"
                multiline
                rows={2}
                value={selectedCell?.Address || ""}
                onChange={(e) => {
                  setSelectedCell({ ...selectedCell, Address: e.target.value });
                }}
              />
              <TextField
                style={{ marginBottom: '15px' }}
                required
                helperText="Esse campo é obrigatório"
                fullWidth
                label="Líder"
                value={selectedCell?.Leader || ""}
                onChange={(e) => {
                  setSelectedCell({ ...selectedCell, Leader: e.target.value });
                }}
              />
              <TextField
                style={{ marginBottom: '15px' }}
                required
                helperText="Esse campo é obrigatório"
                fullWidth
                label="Contato"
                value={selectedCell?.Contact || ""}
                onChange={(e) => {
                  setSelectedCell({ ...selectedCell, Contact: e.target.value });
                }}
              />
            </DialogContent>
            <DialogActions>
              <Button dialogActions onClick={handleCloseDialog}>Cancelar</Button>
              <Button dialogActions
                onClick={() => handleAddOrUpdateCell(selectedCell)}
              >
                {selectedCell?.id ? "Atualizar" : "Adicionar"}
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </Content>
      <CellsMap />
      <BackButton goBack={() =>  navigate(-1)} />
    </Container>
  );
};

export default SG;
