import { useState, useEffect } from 'react';
import { db } from '../firebaseConfig';
import { collection, onSnapshot } from 'firebase/firestore';
import { useAuth } from 'providers/AuthProvider';

const useUnseenNotifications = () => {
  const { currentUser } = useAuth();
  const [unseenCount, setUnseenCount] = useState(0);
  
  useEffect(() => {
    if (currentUser?.uid) {
      const notificationsRef = collection(db, 'notifications');
    
      const unsubscribe = onSnapshot(notificationsRef, (snapshot) => {
        const unseenNotifications = snapshot.docs.filter(doc => 
          !(doc.data().seen || []).includes(currentUser.uid)
        );
        setUnseenCount(unseenNotifications.length);
      }, (error) => {
        console.error("Error fetching unseen notifications:", error);
      });
    
      return () => unsubscribe();
    }
  }, [currentUser?.uid]);

  return unseenCount;
};

export default useUnseenNotifications;
