/* eslint-disable react/prop-types */
import React, { useState, useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { Dialog, DialogTitle, DialogContent, Typography, DialogActions, Divider, Checkbox } from '@mui/material';
import { BackButton, CustomFormField, ImageUpload, MarkdownModal, SubscriberCount, Tooltip } from 'components';
import { saveAs } from 'file-saver';
import { PieChart } from '@mui/x-charts/PieChart';
import { useAuth } from 'providers/AuthProvider';
import LocationAutocomplete, { Local as LocalType } from 'components/LocationAutocomplete';
import { db, Timestamp, GeoPoint } from '../../firebaseConfig';
import { doc, addDoc, updateDoc, deleteDoc, arrayUnion, collection, onSnapshot, query, orderBy, where, getDoc } from 'firebase/firestore';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import DateTimePicker from 'react-datetime-picker';
import DurationPicker from 'react-duration-picker';
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import { DateTime } from 'luxon';
import QRCode from 'qrcode.react';
import Barcode from 'react-barcode';
import html2canvas from 'html2canvas';
import { FaClock, FaSearch, FaTrash } from 'react-icons/fa';
import TicketSVG from 'assets/images/Ticket.svg';
import Clock from 'assets/images/Date.svg';
import Info from 'assets/images/Info.svg';
import Local from 'assets/images/Local.svg';
import {
  Button,
  Container,
  ElementContainer,
  EventBar,
  EventCardContainer,
  EventDate,
  EventDetailButton,
  EventDetailContainer,
  EventDetailImage,
  EventDetailInfo,
  EventDetailTitle,
  EventInfo,
  EventName,
  EventRegister,
  EventRegisterTitle,
  EventTime,
  Image,
  Option,
  QRCodeWrapper,
  RegistrationButton,
  RegistrationForm,
  RegistrationInput,
  RegistrationInputDescription,
  SearchBar,
  Select,
  Ticket,
  TicketImage,
} from './styles';
import { MdOutlineContentCopy } from 'react-icons/md';

interface Field {
  id: string;
  name: string;
  type: 'text' | 'checkbox' | 'select';
  options?: string[];
}

export interface Subscriber {
  id: string;
  name: string;
  email: string;
  phone: string;
  church?: string;
  customFields?: Field[];
  totalPayment?: number; // Total payment based on group selections
  groupCounts?: number[]; // Array of counts for the groups
}

export interface Group {
  label: string; // Name of the group (e.g., 'Group 1', 'Group 2')
  price: number; // Price for this group
}

export interface Event {
  id: string;
  name: string;
  dateTime: Timestamp;
  duration: { hours: number; minutes: number; seconds: number };
  deadLine: Timestamp;
  expireAt: Timestamp;
  placeName: string;
  location: LocalType; 
  image: string;
  description: string;
  subscribers: Subscriber[]; // List of event subscribers
  customFields?: Field[]; // Custom fields for additional data
  paidEvent?: boolean;
  fixedPrice?: number; // Fixed price for the event, if applicable
  pixKey?: string; // PIX key for payment processing
  churchRequired?: boolean;
  paymentType?: 'fixed' | 'ageBased'; // Type of payment ('fixed' or 'age-based')
  groups?: Group[]; // Dynamic array of groups, each with label and price
}

const usePlaceDetails = async (latitude: number, longitude: number): Promise<string> => {
  try {
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&sensor=false&key=${process.env.REACT_APP_PLACES_API_KEY}`;
    const response = await axios.get(url);
    if (response.data.status === 'OK') {
      const address = response.data.results[0].formatted_address;
      return address;
    }
    return '';
  } catch (error) {
    console.error(error);
    return '';
  }
};


const EventList = ({ onSelectEvent, onEditEvent, isAuth, searchTerm, enrolTo, user }) => {
  const renderedDates = new Set();
  const [events, setEvents] = useState<Event[]>([]);

  const filteredEvents = user ? 
  events
    .filter((event) =>
      event.name.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .filter((event) => 
      !enrolTo || event.subscribers.some(subscriber => subscriber.email === user.email)
    )
  : events.filter((event) =>
    event.name.toLowerCase().includes(searchTerm.toLowerCase())
  );
  
  useEffect(() => {
    const q = query(
      collection(db, 'events'),
      where('deadLine', '>=', new Date()),
      orderBy('deadLine', 'asc')
    );
    
    const unsubscribe = onSnapshot(q, async (snapshot) => {
      const addressPromises = snapshot.docs.map(doc => {
        const data = doc.data() as Omit<Event, 'id'>;
        return usePlaceDetails(data.location.latitude, data.location.longitude);
      });
  
      const addresses = await Promise.all(addressPromises);

      const eventsData = snapshot.docs.map((doc, i) => {
        const data = doc.data() as Omit<Event, 'id'>;
        return {
          id: doc.id,
          ...data,
          location: {
            address: addresses[i],
            latitude: data.location.latitude,
            longitude: data.location.longitude,
          },
          dateTime: data.dateTime.toDate(),
          deadLine: data.deadLine.toDate(),
        };
      });
      setEvents(eventsData);
    });

    return unsubscribe;
  }, []);

  const deleteEvent = async (eventId) => {
    if (window.confirm("Tem certeza de que deseja deletar esse evento?")) {
      try {
        await deleteDoc(doc(db, "events", eventId));
        console.log("Event deleted with ID:", eventId);
      } catch (error) {
        console.error("Error deleting event:", error);
      }
    }
  };

  return (
    <div>
      {filteredEvents.map(event => {
        const formattedDate = format(event.dateTime, 'dd/MM/yyyy');
        const formattedTime = format(event.dateTime, 'HH:mm');

        const shouldRenderDate = !renderedDates.has(formattedDate);
        if (shouldRenderDate) {
          renderedDates.add(formattedDate);
        }
        
        return (
          <React.Fragment key={event.id}>
            {shouldRenderDate && <EventDate>{formattedDate}</EventDate>}
            <EventCardContainer>
              <EventInfo>
                <EventName>{event.name}</EventName>
                <EventTime>
                  <div>
                    {formattedTime}
                    <FaClock size="12px" color="#09416C" />
                  </div>
                  <div style={{ width: isAuth ? '205px' : '', display: 'flex' }}>
                    <Button crud={false} onClick={() => onSelectEvent(event)}>
                      Visualizar
                    </Button>
                    <Button crud={false} onClick={() => onEditEvent(event)} hide={!isAuth}>
                      Editar
                    </Button>
                    <FaTrash onClick={() => deleteEvent(event.id)} color='#C11E0F' size='24px' display={isAuth ? 'block' : 'none'} />
                  </div>
                </EventTime>
              </EventInfo>
            </EventCardContainer>
          </React.Fragment>
        );
      })}
    </div>
  );
};

const EventCreation = ({ onSelectEvent }) => {
  const [fields, setFields] = useState<Field[]>([]);
  
  const [formData, setFormData] = useState({
    name: '',
    dateTime: new Date(),
    duration: {},
    deadLine: new Date(),
    expireAt: new Date(),
    placeName: '',
    location: {
      name: '',
      latitude: 0,
      longitude: 0,
    },
    image: '',
    description: '',
    subscribers: [],
    customFields: fields,
    churchRequired: true,
    paidEvent: false,
    paymentType: 'fixed' || 'ageBased',
    fixedPrice: '',
    pixKey: '',
    groups: [{ label: '', price: 0 }],
  });

  const [dateTime, setDateTime] = useState(new Date());
  const [deadLine, setDeadLine] = useState(new Date());
  
  const onChangeDateTime = (value: Date | [Date, Date] | null) => {
    if (value instanceof Date) {
      const zonedDateTime = DateTime.fromJSDate(value).setZone('America/Sao_Paulo');
      setDateTime(zonedDateTime.toJSDate());
    }
  };

  const onChangeDeadLine = (value: Date | [Date, Date] | null) => {
    if (value instanceof Date) {
      const zonedDateTime = DateTime.fromJSDate(value).setZone('America/Sao_Paulo');
      setDeadLine(zonedDateTime.toJSDate());
    }
  };

  const [duration, setDuration] = useState<{ hours: number, minutes: number, seconds: number }>({ hours: 1, minutes: 0, seconds: 0 });

  const onChangeDuration = newDuration => {
    setDuration(prevDuration => {
      const hasChanged = ['hours', 'minutes', 'seconds'].some(key => newDuration[key] !== prevDuration[key]);
      return hasChanged ? newDuration : prevDuration;
    });
  };

  const [imageUrl, setImageUrl] = useState<string | null>(null);

  const handleUpload = (url: string) => {
    setImageUrl(url);
  };
  const togglePaidEvent = () => {
    setFormData((prevData) => ({ ...prevData, paidEvent: !prevData.paidEvent }));
  };

  const handleGroupChange = (index, field, value) => {
    const updatedGroups = [...formData.groups];
    updatedGroups[index][field] = value;
    setFormData((prevData) => ({ ...prevData, groups: updatedGroups }));
  };

  const toggleChurchRequired = () => {
    setFormData((prevData) => ({ ...prevData, churchRequired: !prevData.churchRequired }));
  };
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCustomFieldsChange = (fields: Field[]) => {
    setFields(fields);
    setFormData({ ...formData, customFields: fields });
  };

  const changePlace = (data) => {
    console.log(data);
    if (data && data.location && data.placeName) {
      console.log(data);
      setFormData({ ...formData, placeName: data.placeName, location: data.location });
    }
  };
  
  const handleSubmit = (e) => {
    e.preventDefault();
    const newEvent = {
      name: formData.name,
      dateTime: Timestamp.fromDate(new Date(dateTime)),
      duration: duration,
      deadLine: Timestamp.fromDate(new Date(deadLine)),
      expireAt: Timestamp.fromDate(new Date(Date.now() + 60 * 24 * 60 * 60 * 1000)), // 60 days from now
      placeName: formData.placeName,
      location: new GeoPoint(formData.location.latitude, formData.location.longitude),
      image: imageUrl || '',
      description: formData.description,
      subscribers: [],
      customFields: fields,
      paidEvent: formData.paidEvent,
      pixKey: formData.pixKey,
      paymentType: formData.paymentType,
      fixedPrice: formData.fixedPrice,
      groups: formData.groups,
    };
    
    const addNewEvent = async (newEvent) => {
      try {
        const docRef = await addDoc(collection(db, "events"), newEvent);
        console.log("Evento criado com ID: ", docRef.id);
        onSelectEvent();
      } catch (error) {
        console.error("Erro ao criar o evento:", error);
      }
    };
    
    addNewEvent(newEvent);
  };
  return (
    <RegistrationForm onSubmit={handleSubmit}>
      <EventRegisterTitle>Adicionar Novo Evento</EventRegisterTitle>
      <EventRegister>Nome</EventRegister>
      <RegistrationInput
        type="text"
        name="name"
        placeholder="Nome"
        value={formData.name}
        onChange={handleChange}
        required
      />
      <EventRegister>Data e Hora</EventRegister>
      <ElementContainer>
        <DateTimePicker
          className="dateTimePicker"
          onChange={onChangeDateTime}
          value={dateTime}
          locale="pt-BR"
        />
      </ElementContainer>
      <EventRegister>Duração Estimada</EventRegister>
      <ElementContainer>
        <DurationPicker 
          onChange={onChangeDuration}
          initialDuration={{ hours: 1, minutes: 0, seconds: 0 }}
        />
      </ElementContainer>
      <EventRegister>Prazo de Inscrição</EventRegister>
      <ElementContainer>
        <DateTimePicker
          className="deadLinePicker"
          onChange={onChangeDeadLine}
          value={deadLine}
          locale="pt-BR"
        />
      </ElementContainer>
      <EventRegister>Local</EventRegister>
      <ElementContainer>
        <LocationAutocomplete onSelect={(data) => changePlace(data)} />
      </ElementContainer>
      <EventRegister>Imagem</EventRegister>
      <ElementContainer>
        <ImageUpload onUpload={handleUpload} />
        {imageUrl && <img src={imageUrl} alt="Uploaded" />}
      </ElementContainer>
      <EventRegister>Descrição</EventRegister>
      <RegistrationInputDescription
        name="description"
        placeholder="Descrição"
        value={formData.description}
        onChange={handleChange}
        required
      />
      <ElementContainer>
        <Checkbox
          checked={formData.churchRequired}
          onChange={toggleChurchRequired}
        />
        Incluir campo de igreja
      </ElementContainer>
      <ElementContainer>
        <Checkbox
          checked={formData.paidEvent}
          onChange={togglePaidEvent}
        />
        Este evento é pago
      </ElementContainer>
      {formData.paidEvent && (
        <>
          <ElementContainer>
            <EventRegister>Tipo de Pagamento</EventRegister>
            <Select
              name="paymentType"
              value={formData.paymentType}
              onChange={handleChange}
            >
              <Option value="fixed">Valor Fixo</Option>
              <Option value="ageBased">Por Grupo</Option>
            </Select>
          </ElementContainer>

          <EventRegister>Chave PIX (Telefone)</EventRegister>
          <RegistrationInput
          type="number"
          name="pixKey"
          value={formData.pixKey}
          onChange={handleChange}
          placeholder="Chave PIX"
          required />

          {formData.paymentType === 'fixed' && (
              <>
                <EventRegister>Preço Fixo (R$)</EventRegister>
                <RegistrationInput
                type="number"
                name="fixedPrice"
                value={formData.fixedPrice}
                onChange={handleChange}
                placeholder="Preço Fixo do Evento"
                required />
              </>
          )}

          {formData.paymentType === 'ageBased' && (
            <>
              <EventRegister>Grupos de Pagamento</EventRegister>
              {formData.groups.map((group, index) => (
                <div key={index}>
                  <RegistrationInput
                    style={{ marginBottom: '15px' }}
                    type="text"
                    placeholder="Nome do Grupo"
                    value={group.label}
                    onChange={(e) => handleGroupChange(index, 'label', e.target.value)}
                  />
                  <br />
                  <RegistrationInput
                    type="number"
                    placeholder="Preço do Grupo (R$)"
                    value={group.price}
                    onChange={(e) => handleGroupChange(index, 'price', e.target.value)}
                  />
                </div>
              ))}
              <RegistrationButton crud type="button" onClick={() => setFormData(prev => ({
                ...prev,
                groups: [...prev.groups, { label: '', price: 0 }]
              }))}>
                Adicionar Grupo
              </RegistrationButton>
            </>
          )}
        </>
      )}
      <CustomFormField fields={fields} onFieldsChange={handleCustomFieldsChange} />
      <RegistrationButton crud type="submit">Registrar</RegistrationButton>
      <RegistrationButton crud onClick={onSelectEvent}>Cancelar</RegistrationButton>
    </RegistrationForm>
  );
};

const EventEdit = ({ eventToEdit, changePage }) => {
  const [formData, setFormData] = useState({ ...eventToEdit });
  const [fields, setFields] = useState<Field[]>(eventToEdit.customFields || []);  // Carrega os campos personalizados
  const [groups, setGroups] = useState(eventToEdit.groups || []); // Carrega os grupos de pagamento

  const handleCustomFieldsChange = (fields: Field[]) => {
    setFields(fields); // Atualiza os campos personalizados conforme o usuário edita
    setFormData({ ...formData, customFields: fields });
  };

  const handleGroupChange = (index, field, value) => {
    const updatedGroups = [...groups];
    updatedGroups[index][field] = value;
    setGroups(updatedGroups);
    setFormData((prevData) => ({ ...prevData, groups: updatedGroups }));
  };

  const addGroup = () => {
    setGroups([...groups, { label: '', price: '' }]);
  };

  const removeGroup = (index) => {
    setGroups(groups.filter((_, i) => i !== index));
  };

  const handlePixKeyChange = (e) => {
    const { value } = e.target;
    setFormData({ ...formData, pixKey: value });
  };

  const [dateTime, setDateTime] = useState(formData.dateTime);
  const [deadLine, setDeadLine] = useState(formData.deadLine);

  const onChangeDateTime = (value: Date | [Date, Date] | null) => {
    if (value instanceof Date) {
      const zonedDateTime = DateTime.fromJSDate(value).setZone('America/Sao_Paulo');
      setDateTime(zonedDateTime.toJSDate());
    }
  };

  const onChangeDeadLine = (value: Date | [Date, Date] | null) => {
    if (value instanceof Date) {
      const zonedDateTime = DateTime.fromJSDate(value).setZone('America/Sao_Paulo');
      setDeadLine(zonedDateTime.toJSDate());
    }
  };

  const [duration, setDuration] = useState<{ hours: number, minutes: number, seconds: number }>(formData.duration);

  const onChangeDuration = newDuration => {
    setDuration(prevDuration => {
      const hasChanged = ['hours', 'minutes', 'seconds'].some(key => newDuration[key] !== prevDuration[key]);
      return hasChanged ? newDuration : prevDuration;
    });
  };

  const [imageUrl, setImageUrl] = useState<string>(formData.image);

  const handleUpload = (url: string) => {
    setImageUrl(url);
  };

  const togglePaidEvent = () => {
    setFormData((prevData) => ({ ...prevData, paidEvent: !prevData.paidEvent }));
  };

  const toggleChurchRequired = () => {
    setFormData((prevData) => ({ ...prevData, churchRequired: !prevData.churchRequired }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  
  const changePlace = (data) => {
    if (typeof data === 'object' && data.location.latitude && data.location.longitude) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        placeName: data.name,
        location: {
          address: data.location.address,
          latitude: data.location.latitude,
          longitude: data.location.longitude,
        },
      }));
    }
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData);
    try {
      const eventRef = doc(db, "events", formData.id);
      await updateDoc(eventRef, {
        ...formData,
        dateTime: Timestamp.fromDate(formData.dateTime),
        duration: duration,
        deadLine: Timestamp.fromDate(formData.deadLine),
        location: new GeoPoint(formData.location.latitude, formData.location.longitude),
        image: imageUrl,
        customFields: fields,
        groups: groups,
      });
      console.log("Document updated with ID: ", formData.id);
      changePage();
    } catch (error) {
      console.error("Error updating document: ", error);
    }
  };

  return (
    <RegistrationForm onSubmit={handleSubmit}>
      <EventRegisterTitle>Editar Evento</EventRegisterTitle>
      <EventRegister>Nome</EventRegister>
      <RegistrationInput
        type="text"
        name="name"
        placeholder="Nome"
        value={formData.name}
        onChange={handleChange}
        required
      />
      <EventRegister>Data e Hora</EventRegister>
      <ElementContainer>
        <DateTimePicker
          className="dateTimePicker"
          onChange={onChangeDateTime}
          value={dateTime}
          locale="pt-BR"
        />
      </ElementContainer>
      <EventRegister>Duração Estimada</EventRegister>
      <ElementContainer>
        <DurationPicker 
          onChange={onChangeDuration}
          initialDuration={formData.duration}
        />
      </ElementContainer>
      <EventRegister>Prazo de Inscrição</EventRegister>
      <ElementContainer>
        <DateTimePicker
          className="deadLinePicker"
          onChange={onChangeDeadLine}
          value={deadLine}
          locale="pt-BR"
        />
      </ElementContainer>
      <EventRegister>Local</EventRegister>
      <ElementContainer>
        <LocationAutocomplete initialValue={formData.location} onSelect={(data) => changePlace(data)} />
      </ElementContainer>
      <EventRegister>Imagem</EventRegister>
      <ElementContainer>
        <ImageUpload onUpload={handleUpload} />
        {imageUrl && <img src={imageUrl} alt="Uploaded" />}
      </ElementContainer>
      <EventRegister>Descrição</EventRegister>
      <RegistrationInputDescription
        name="description"
        placeholder="Descrição"
        value={formData.description}
        onChange={handleChange}
        required
      />
      <ElementContainer>
        <Checkbox
          checked={formData.churchRequired}
          onChange={toggleChurchRequired}
        />
        Incluir campo de igreja
      </ElementContainer>
      <EventRegister>Este evento é pago</EventRegister>
      <Checkbox
        checked={formData.paidEvent}
        onChange={togglePaidEvent}
      />

      {formData.paidEvent && (
        <>
          <EventRegister>Tipo de Pagamento</EventRegister>
          <Select
            name="paymentType"
            value={formData.paymentType}
            onChange={(e) => setFormData({ ...formData, paymentType: e.target.value })}
          >
            <Option value="fixed">Valor Fixo</Option>
            <Option value="ageBased">Por Grupo</Option>
          </Select>

          <EventRegister>Chave PIX (telefone)</EventRegister>
          <RegistrationInput
            type="text"
            name="pixKey"
            placeholder="Chave PIX"
            value={formData.pixKey}
            onChange={handlePixKeyChange}
            required={formData.paidEvent}
          />

          {formData.paymentType === 'fixed' && (
            <>
              <EventRegister>Valor Fixo (R$)</EventRegister>
              <RegistrationInput
                type="number"
                name="fixedPrice"
                placeholder="Valor do Evento"
                value={formData.fixedPrice}
                onChange={(e) => setFormData({ ...formData, fixedPrice: e.target.value })}
                required
              />
            </>
          )}

          {formData.paymentType === 'ageBased' && (
            <>
              <EventRegister>Editar Grupos e Preços</EventRegister>
              {groups.map((group, index) => (
                <div key={index}>
                  <EventRegister>Nome do Grupo</EventRegister>
                  <RegistrationInput
                    type="text"
                    placeholder="Nome do Grupo"
                    value={group.EventRegister}
                    onChange={(e) => handleGroupChange(index, 'label', e.target.value)}
                  />
                  <EventRegister>Preço (R$)</EventRegister>
                  <RegistrationInput
                    type="number"
                    placeholder="Preço do Grupo"
                    value={group.price}
                    onChange={(e) => handleGroupChange(index, 'price', e.target.value)}
                  />
                  <RegistrationButton crud type="button" onClick={() => removeGroup(index)}>Remover Grupo</RegistrationButton>
                </div>
              ))}
              <RegistrationButton crud type="button" onClick={addGroup}>Adicionar Novo Grupo</RegistrationButton>
            </>
          )}
        </>
      )}
      <CustomFormField fields={fields} onFieldsChange={handleCustomFieldsChange} />
      <RegistrationButton crud type="submit">Atualizar</RegistrationButton>
      <RegistrationButton crud onClick={changePage}>Cancelar</RegistrationButton>
    </RegistrationForm>
  );
};

const SubscriberListModal = ({ open, onClose, subscribers }) => {
  const groupByChurch = (subscribers) => {
    const grouped = subscribers.reduce((acc, subscriber) => {
      const church = subscriber.church || 'Unknown';
      if (!acc[church]) {
        acc[church] = 0;
      }
      acc[church]++;
      return acc;
    }, {});

    return Object.keys(grouped).map((church, index) => ({
      id: index,
      value: grouped[church],
      label: church,
    }));
  };

  const pieData = groupByChurch(subscribers);
  
  const downloadCSV = () => {
    const headers = ['Nome', 'Email', 'Whatsapp', 'Igreja'];
    const rows = subscribers.map(subscriber => [
      subscriber.name,
      subscriber.email,
      subscriber.phone,
      subscriber.church,
    ]);

    let csvContent = 'data:text/csv;charset=utf-8,';
    csvContent += headers.join(',') + '\n';
    rows.forEach(row => {
      csvContent += row.join(',') + '\n';
    });

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, 'subscribers.csv');
  };

  return (
    <Dialog style={{ maxHeight: 'calc(100% - 220px)' }} open={open} onClose={onClose}>
      <DialogTitle>Inscritos</DialogTitle>
      <DialogContent>
      {subscribers.length > 0 ? (
          <>
            <div style={{ display: 'flex', padding: 'auto' }}>              
              <PieChart
                series={[
                  {
                    data: pieData,
                    cx: 195,
                    cy: 120,
                    highlightScope: { faded: 'global', highlighted: 'item' },
                    faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
                  },
                ]}
                height={250}
                width={400}
                slotProps={{
                  legend: {
                    hidden: true,
                    position: { vertical: 'bottom', horizontal: 'middle' }, 
                  },
                }}
              />
            </div>
            {subscribers.map((subscriber, index) => (
              <div key={index}>
                <Typography>
                  Nome: {subscriber.name} <br />
                  Email: {subscriber.email} <br />
                  Contato: {subscriber.phone} <br />
                  Igreja: {subscriber.church} <br />
                </Typography>
                <Divider />
              </div>
            ))}
          </>
        ) : (
          <Typography>Nenhum inscrito ainda.</Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button crud={false} hide={subscribers.length === 0} onClick={downloadCSV}>Download CSV</Button>
        <Button crud={false} hide={false} onClick={onClose}>Fechar</Button>
      </DialogActions>
    </Dialog>
  );
};

const EventDetail = ({ event, isAuth, onRegister }) => {
  const [isTruncated, setIsTruncated] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selected, setSelected] = useState(false);

  const openSubscriberModal = () => {
    setSelected(true);
    setIsModalOpen(true);
  };

  const closeSubscriberModal = () => {
    setIsModalOpen(false);
    setSelected(false);
  };

  const handleReadMoreClick = () => setIsTruncated(!isTruncated);

  const text = isTruncated ? event.description.slice(0, 70) + "..." : event.description;

  const date = event.dateTime instanceof Timestamp ? event.dateTime.toDate() : event.dateTime;

  const formattedDate = format(date, "dd 'de' MMMM, yyyy", { locale: ptBR });

  const endTime = new Date(date.getTime() + event.duration.hours*3600*1000 + event.duration.minutes*60*1000 + event.duration.seconds*1000);

  const formattedTime = format(
    event.dateTime instanceof Timestamp ? event.dateTime.toDate() : event.dateTime,
    "EEEE, HH:mm '-' ",
    { locale: ptBR }
  );
  
  const capitalizedLetter = value => {
    return value
    .toLowerCase()
    .split(' ')
    .map((word, index) => {
      if (index === 0 || index === 2) {
        return word.charAt(0).toUpperCase() + word.slice(1);
      } else {
        return word;
      }
    })
    .join(' ');
  }

  const formattedDeadLine = format(
    event.deadLine instanceof Timestamp ? event.deadLine.toDate() : event.deadLine,
    'dd/MM/yyyy HH:mm'
  );
  return (
      <EventDetailContainer>
        <EventDetailImage src={event.image} alt={event.name} />
        <SubscriberCount onClick={() => isAuth && openSubscriberModal()} count={event.subscribers.length} />
        <EventDetailTitle>{event.name}</EventDetailTitle>
        <EventDetailInfo>
          <Image className="Date" alt="Date" src={Clock} />
          <div style={{ width: '100%', display: 'grid' }}>
            <span style={{ marginBottom: '-18px' }}>{capitalizedLetter(formattedDate)}</span>
            <br />
            <span>{capitalizedLetter(formattedTime) + format(endTime, "HH:mm")}</span>
          </div>
          <Tooltip width="300px" transform="translate(-220px, -10px)" text={`Prazo de inscrição: ${formattedDeadLine}`}/>
        </EventDetailInfo>
        <EventDetailInfo>
          <Image className="Local" alt="Local" src={Local} />
          <div style={{ width: '100%', display: 'grid' }}>
            <span>{event.placeName}</span>
            <br />
            <span>
              <a href={`https://www.google.com/maps/search/?api=1&query=${event.location.address}`} target="_blank" rel="noopener noreferrer">
                {event.location.address}
              </a>
            </span>
          </div>
        </EventDetailInfo>
        <EventDetailInfo>
          <Image className="Info" alt="Info" src={Info} />
          <div className="content" style={{ maxHeight: '60px', overflowX: 'hidden', overflowY: isTruncated ? "hidden" : "auto" }}>
            Informações "{event.name}"
            &nbsp;
            <a style={{
              color: '#4A90E2',
              fontWeight: 'lighter'
            }} onClick={handleReadMoreClick}>
              {isTruncated ? "Ler mais" : "Ler menos"}
            </a>
          </div>
        </EventDetailInfo>
        <EventBar />
        <EventDetailButton crud={false} onClick={onRegister}>Inscrever-se</EventDetailButton>
        {selected && (
          <SubscriberListModal
            open={isModalOpen}
            onClose={closeSubscriberModal}
            subscribers={event.subscribers}
          />
        )}
         <MarkdownModal 
          open={!isTruncated} 
          handleClose={handleReadMoreClick}
          text={text} 
        />
      </EventDetailContainer>
  );
};

const EventRegistration = ({ onSubmit, event, subscribers, customFields, churchRequired, isPaid }) => {
  const initialFormData = {
    name: '',
    email: '',
    contact: '',
    church: '',
    custom: {},
    totalPayment: 0,
    groupCounts: isPaid && event.paymentType === 'ageBased' ? event.groups.map(() => 0) : [],
  };

  const [formData, setFormData] = useState(initialFormData);

  const formatPhoneNumber = (value) => {
    const phone = value.replace(/[^\d]/g, "");

    if (phone.length <= 11) {
      if (phone.length === 0) {
        return phone;
      }
      // Area code (34)
      if (phone.length <= 2) {
        return `(${phone}`;
      }
      // (34) 9
      if (phone.length <= 3) {
        return `(${phone.slice(0, 2)}) ${phone.slice(2)}`;
      }
      // (34) 9 XXXX
      if (phone.length <= 7) {
        return `(${phone.slice(0, 2)}) ${phone.slice(2, 3)} ${phone.slice(3)}`;
      }
      // (34) 9 XXXX-XXXX
      return `(${phone.slice(0, 2)}) ${phone.slice(2, 3)} ${phone.slice(3, 7)}-${phone.slice(7, 11)}`;
    }
  };

  const handleChange = e => {
    const { name, value } = e.target;
    const regexName = /^[a-zA-Z ~´^]*$/;
    const regexEmail = /^[a-zA-Z0-9._%+-]{1,64}@[a-zA-Z0-9.-]{1,255}\.[a-zA-Z]{2,}$/;

    if (name === 'name' && regexName.test(value)) {
      setFormData({ ...formData, [name]: value });
    } else if (name === 'email' && regexEmail.test(value)) {
      setFormData({ ...formData, [name]: value });
    } else if (name === 'contact') {
      setFormData({ ...formData, [name]: formatPhoneNumber(value) });
    } else if (name === 'church') {
      setFormData({ ...formData, [name]: value.toUpperCase() });
    }
  };

  const handleGroupCountChange = (index, value) => {
    const updatedGroups = [...formData.groupCounts];
    updatedGroups[index] = parseInt(value, 10);
    setFormData({ ...formData, groupCounts: updatedGroups });
  };

  const calculateTotal = () => {
    let total = 0;
    if (event.paymentType === 'fixed') {
      total = event.fixedPrice;
    } else if (event.paymentType === 'ageBased') {
      total = formData.groupCounts.reduce((acc, count, index) => acc + count * event.groups[index].price, 0);
    }
    return total;
  };

  useEffect(() => {
    if (isPaid && event.paymentType === 'ageBased') {
      const totalPayment = calculateTotal();
      setFormData((prevData) => ({ ...prevData, totalPayment }));
    }
  }, [formData.groupCounts]);

  const handleCustomFieldChange = (name, value) => {
    setFormData((prevData) => ({
      ...prevData,
      custom: { ...prevData.custom, [name]: value },
    }));
  };

  const addSubscriberToEvent = async (eventId, formData) => {
    try {
      const eventRef = doc(db, "events", eventId);
  
      await updateDoc(eventRef, {
        subscribers: arrayUnion({
          name: formData.name,
          email: formData.email,
          phone: formData.contact,
          church: formData.church,
          custom: formData.custom,
          totalPayment: formData.totalPayment,
          groupCounts: formData.groupCounts,
        })
      });
  
      console.log("Subscriber added to event: ", eventId);
    } catch (error) {
      console.error("Error adding subscriber to event: ", error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const duplicate = subscribers.some(
      (s) => s.email === formData.email || s.contact === formData.contact
    );
    if (!duplicate) {
      onSubmit({ ...formData });
      addSubscriberToEvent(event.id, formData);
    } else {
      alert(
        `O participante ${formData.name}, com email "${formData.email}" e contato "${formData.contact}" já está cadastro!`
      );
    }
  };

  return (
    <RegistrationForm onSubmit={handleSubmit}>
      <EventRegisterTitle>Ficha de Inscrição</EventRegisterTitle>
      <EventRegister>Nome</EventRegister>
      <RegistrationInput
        type="text"
        name="name"
        placeholder="José Silva Santos"
        value={formData.name}
        onChange={handleChange}
        required
      />
      <EventRegister>Email</EventRegister>
      <RegistrationInput
        type="email"
        name="email"
        placeholder="exemplo@email.com"
        value={formData.email}
        onChange={handleChange}
        required
      />
      <EventRegister>Contato (+DDD Whatsapp)</EventRegister>
      <RegistrationInput
        type="text"
        name="contact"
        placeholder="(34) 9 0000-0000"
        value={formData.contact}
        onChange={handleChange}
        maxLength={16}
        required
      />
      {churchRequired && (
        <>
          <EventRegister>Igreja do Participante</EventRegister>
          <RegistrationInput
            type="text"
            name="church"
            placeholder="IGREJA BATISTA CENTRAL DE UBERLÂNDIA"
            value={formData.church}
            onChange={handleChange}
          />
        </>
      )}
      {customFields && customFields.map((field) => (
        <div key={field.id}>
          <EventRegister>{field.name}</EventRegister>
          {field.type === 'text' && (
            <RegistrationInput
              type="text"
              name={field.name}
              placeholder={`Insira ${field.name}`}
              onChange={(e) => handleCustomFieldChange(field.name, e.target.value)}
              required={field.required}
            />
          )}
        </div>
      ))}
      {event.paymentType === 'fixed' && (
        <>
          <EventRegister>Preço (Valor: R$ {event.fixedPrice})</EventRegister>
        </>
      )}
      {event.paymentType === 'ageBased' && (
        <>
          <EventRegister>Selecione as Quantidades para cada Grupo</EventRegister>
          {event.groups.map((group, index) => (
            <div key={index}>
              <EventRegister>
                {group.label} (Preço: R$ {group.price})
              </EventRegister>
              <Select
                value={formData.groupCounts[index]}
                onChange={(e) => handleGroupCountChange(index, e.target.value)}
              >
                {[...Array(10)].map((_, i) => (
                  <Option key={i} value={i}>
                    {i}
                  </Option>
                ))}
              </Select>
            </div>
          ))}
        </>
      )}

      <RegistrationButton crud={false} type="submit">
        Registrar
      </RegistrationButton>
    </RegistrationForm>
  );
};

const PaymentSection = ({ event, registrationData, onPaymentComplete }) => {
  const [totalAmount, setTotalAmount] = useState(0);
  console.log(registrationData);

  useEffect(() => {
    const calculateTotal = () => {
      let total = 0;
      if (event.paymentType === 'fixed') {
        total = parseFloat(event.fixedPrice);
      } else if (event.paymentType === 'ageBased') {
        total = event.groups.reduce((sum, group, index) => {
          return sum + group.price * registrationData.groupCounts[index];
        }, 0);
      }
      return total;
    };
    setTotalAmount(calculateTotal());
  }, [event, registrationData]);

  const codePay = `00020126330014BR.GOV.BCB.PIX011455${event.pixKey}5204000053039865405${totalAmount * 100}5802BR5925${registrationData.name.replace(/\s/g, '')}6009Uberlandia62070503***6304ABCD`;
  
  const handleCopy = () => {
    const element: any = document.querySelector('#codePay');
    element?.select();
    element?.setSelectionRange(0, 99999);
    document.execCommand('copy');
    alert('Chave PIX copiada com sucesso!')
  };

  return (
    <>
      <Ticket id="Ticket">
        <h4 style={{ position: 'relative', top: '90px', textAlign: 'center' }}>{event.name}</h4>
        <h4 style={{ marginTop: '370px', marginBottom: '50px', textAlign: 'center' }}>Total a Pagar: R$ {totalAmount.toFixed(2)}</h4>
        <QRCodeWrapper>
          {/* <div style={{ width: '205px', height: '100px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Barcode
              value={codePay}
              displayValue={false}
              width={2}
              height={50}
              fontSize={12}
              margin={10}
            />
          </div> */}
          <QRCode size={205} value={codePay} />
          <div style={{ display: 'flex', width: '205px', marginTop: '20px' }}>
            <MdOutlineContentCopy fontSize={18} size={18} onClick={handleCopy} />
            &nbsp;
            <div style={{ width: '185px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }} id="codePay">
              {codePay}
            </div>
          </div>
        </QRCodeWrapper>
      </Ticket>
      <Button crud onClick={() => window.print()}>Download</Button>
      <Button crud onClick={onPaymentComplete}>Ticket</Button>
    </>
  );
};

const EventTicket = ({ event, registration }) => {
  const divRef = useRef<HTMLDivElement>(null);

  const downloadDivAsJPEG = async (filename: string) => {
    if (divRef.current) {
      const canvas = await html2canvas(divRef.current);
      const imgData = canvas.toDataURL('image/jpeg');
      const link = document.createElement('a');
      link.href = imgData;
      link.download = filename;
      link.click();
    }
  };

  const formattedDate = format(
    event.dateTime instanceof Timestamp ? event.dateTime.toDate() : event.dateTime,
    'dd/MM/yyyy'
  );
  const formattedTime = format(
    event.dateTime instanceof Timestamp ? event.dateTime.toDate() : event.dateTime,
    'HH:mm'
  );
  return (
    <>
      <Ticket id="Ticket" ref={divRef}>
        <TicketImage
          className="TicketImage"
          alt="TicketImage"
          src={TicketSVG}
        />
        <QRCodeWrapper>
          <QRCode
            size={205}
            value={`event-${event.id}-user-${registration.email}`}
          />
        </QRCodeWrapper>
        <h3>{(event.name).substring(0, (event.name).indexOf(' '))}</h3>
        <p className="name">{registration.name}</p>
        <p className="date">{formattedDate}</p>
        <p className="time">{formattedTime}</p>
        <p className="location">{event.placeName}</p>
      </Ticket>
      <Button crud onClick={() => downloadDivAsJPEG('Ticket.jpeg')}>Download</Button>
    </>
  );
};

const Events: React.FC = () => {
  const [currentPage, setCurrentPage] = useState('list');
  const [selectedEvent, setSelectedEvent] = useState<Event>();
  const [registrationData, setRegistrationData] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const { isAdmin, currentUser } = useAuth();

  const [showSubscribed, setShowSubscribed] = useState(false);

  const toggleShowSubscribed = () => {
    setShowSubscribed(!showSubscribed);
  };

  const checkEvent = async () => {
    const queryParams = new URLSearchParams(location.search);
    const eventId = queryParams.get('eventId');

    if (eventId) {
      const docRef = doc(db, "events", eventId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const eventData = docSnap.data();
        console.log(eventData);

        if (eventData) {
          const event: Event = {
            id: eventId,
            name: eventData.name ?? '',
            dateTime: eventData.dateTime ?? '',
            duration: eventData.duration ?? '',
            deadLine: eventData.deadLine ?? '',
            expireAt: eventData.expireAt ?? '',
            placeName: eventData.placeName ?? '',
            location: eventData.location ?? '',
            image: eventData.image ?? '',
            description: eventData.description ?? '',
            subscribers: eventData.subscribers ?? 0,
            paidEvent: eventData.paidEvent,
            pixKey: eventData.pixKey,
            churchRequired: eventData.churchRequired,
          };

          setSelectedEvent(event);
          setCurrentPage('detail');
        }
      } else {
        console.log("No such document!");
      }
    }
  };

  useEffect(() => {
    checkEvent();
  }, [location]);

  switch (currentPage) {
    case 'list':
      return (
        <Container overflow='auto'>
          <SearchBar placeholder="Pesquisar..." value={searchTerm} onChange={handleSearchChange} />
          <FaSearch style={{
            position: "relative",
            top: "-48px",
            left: "calc(50% + 140px)",
            color: "var(--color-primary-2)",
          }} />
          <Button crud onClick={() => setCurrentPage('add')} hide={!isAdmin}>Adicionar</Button>
          <Button crud onClick={toggleShowSubscribed} hide={isAdmin || !currentUser}>Meus Eventos</Button>
          <EventList
            user={currentUser}
            enrolTo={showSubscribed}
            searchTerm={searchTerm}
            isAuth={isAdmin}
            onSelectEvent={event => {
              setSelectedEvent(event);
              setCurrentPage('detail');
            }}
            onEditEvent={event => {
              setSelectedEvent(event);
              setCurrentPage('edit');
            }}
          />
        </Container>
      );
    case 'add':
      return (
        <Container overflow='auto'>
          <BackButton goBack={() => setCurrentPage('list')} />
          <EventCreation onSelectEvent={() => setCurrentPage('list')} />
        </Container>
      );
    case 'edit':
      return (
        <Container overflow='auto'>
          <BackButton goBack={() => setCurrentPage('list')} />
          <EventEdit eventToEdit={selectedEvent} changePage={() => setCurrentPage('list')} />
        </Container>
      );
    case 'detail':
      return (
        <Container overflow='hidden'>
          <BackButton goBack={() => setCurrentPage('list')} />
          <EventDetail
            event={selectedEvent}
            isAuth={isAdmin}
            onRegister={() => setCurrentPage('register')}
          />
        </Container>
      );
    case 'register':
      return (
        <Container overflow='hidden'>
          <BackButton goBack={() => setCurrentPage('detail')} />
          <EventRegistration
            onSubmit={(data) => {
              setRegistrationData(data);
              if (selectedEvent?.paidEvent) {
                setCurrentPage('payment');
              } else {
                setCurrentPage('ticket');
              }
            }}
            event={selectedEvent}
            subscribers={selectedEvent?.subscribers}
            customFields={selectedEvent?.customFields}
            churchRequired={selectedEvent?.churchRequired}
            isPaid={selectedEvent?.paidEvent}
          />
        </Container>
      );
    case 'payment':
      return (
        <Container overflow='autoRnVcrUOSehulPPIvct3Q'>
          <PaymentSection
            event={selectedEvent}
            registrationData={registrationData}
            onPaymentComplete={() => setCurrentPage('ticket')}  // Aqui é onde você define a ação ao concluir o pagamento
          />
        </Container>
      );
    case 'ticket':
      return (
        <Container overflow='autoRnVcrUOSehulPPIvct3Q'>
          <EventTicket event={selectedEvent} registration={registrationData} />
        </Container>
      );
    default:
      return <div>Página não encontrada</div>;
  }
};

export default Events;
