import React, { useState, useEffect } from "react";
import { db } from "../../firebaseConfig"; // Adjust the import path as necessary
import { doc, updateDoc, getDoc } from "firebase/firestore";
import { Container, Card, CardsContainer, Header, Text, Name, Section, Title, Div, P, Button } from "./styles";
import { useAuth } from "providers/AuthProvider";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import { IoClose } from "react-icons/io5";
import { FormControlLabel, Checkbox } from "@mui/material";
import { BackButton, PersonManagement } from "components";
import { useNavigate } from "react-router-dom";

interface ExtraordinaryService {
  reason: string;
  time: string;
  message: string;
  pastor: string;
}

interface WeeklyService {
  time: string;
  message: string;
  pastor: string;
  timeNight: string;
  messageNigth: string;
  pastorNigth: string;
}

interface Services {
  week: WeeklyService;
  extraordinary: {
    hide: boolean;
    services: ExtraordinaryService[];
  }
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  height: '100%',
  overflow: 'auto',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const Services: React.FC = () => {
  const intialValue = {
    week: {
      time: '9h00 - 10h00',
      message: '',
      pastor: '',
      timeNight: '18h30 - 20h30',
      messageNigth: '',
      pastorNigth: '',
    },
    extraordinary: {
      hide: true,
      services: [
        {
          reason: '',
          time: '',
          message: '',
          pastor: '',
        }
      ]
    }
  }

  const [services, setServices] = useState<Services>(intialValue);
  const [showModal, setShowModal] = useState(false);
  const { isAdmin } = useAuth();
  const navigate = useNavigate();
  
  useEffect(() => {
    const fetchServices = async () => {
      const serviceRef = doc(db, "services", "CXry9KqLmC4PIHKuF8ei");
      const docSnap = await getDoc(serviceRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        
        if ("week" in data && "extraordinary" in data) {
          setServices(data as Services);
        }
      }
    };

    fetchServices();
  }, []);

  // Update services in Firestore
  const updateServices = async (updatedServices) => {
    const serviceRef = doc(db, "services", "CXry9KqLmC4PIHKuF8ei");
    await updateDoc(serviceRef, updatedServices);
    setShowModal(false); // Hide the modal after update
  };

  // Handle changes in the input fields
  const handleInputChange = (event, field, subfield, index?, key?) => {
    const isCheckbox = event.target.type === 'checkbox';
    const value = isCheckbox ? event.target.checked : event.target.value;
  
    const updated = { ...services };
  
    if (subfield !== '') {
      if (subfield === 'hide') {
        updated[field][subfield] = value;
      } else {
        updated[field][subfield] = value;
      }
    } else {
      updated[field].services[index][key] = value;
    }
  
    setServices(updated);
  };
  

  const renderTextField = (label, name, value, onChange) => (
    <TextField
      margin="normal"
      required
      fullWidth
      label={label}
      name={name}
      autoComplete={name}
      value={value}
      onChange={onChange}
    />
  );

  // Render service details or a form based on `showModal`
  return (
    <Container>
      <Header>
        <Text>Cultos</Text>
      </Header>
      <Button onClick={() => setShowModal(true)} hide={!isAdmin} top="90px">Editar</Button>
      <CardsContainer top={!isAdmin ? '90px' : '0px'}>
        {services && (
          <>
            <Name>Cultos Semanais</Name>
            <Card>
              <Section>
                <Title>Domingo de manhã</Title>
                <br />
                <Div>
                  <P weight="600">Horário:</P>
                  <P weight="400">{services.week.time}</P>
                </Div>
                <Div>
                  <P weight="600">Mensagem: </P>
                  <P weight="400">{services.week.message}</P>
                </Div>
                <Div>
                  <P weight="600">Preletor: </P>
                  <P weight="400">{services.week.pastor}</P>
                </Div>
                <br />
                <Title>Domingo a noite</Title>
                <br />
                <Div>
                  <P weight="600">Horário: </P>
                  <P weight="400">{services.week.timeNight}</P>
                </Div>
                <Div>
                  <P weight="600">Mensagem: </P>
                  <P weight="400">{services.week.messageNigth}</P>
                </Div>
                <Div>
                  <P weight="600">Preletor: </P>
                  <P weight="400">{services.week.pastorNigth}</P>
                </Div>
              </Section>
            </Card>
              <div style={{ display: services.extraordinary.hide ? 'none' : 'block'}}>
                <Name>Celebrações Extraordinárias</Name>
                <Card>
                  {services.extraordinary.services.map((s, index) => (
                    <Section key={index}>
                      <Title>{s.reason || 'Intenção'}</Title>
                      <br />
                      <Div>
                        <P weight="600">Horário: </P>
                        <P weight="400">{s.time}</P>
                      </Div>
                      <Div>
                        <P weight="600">Mensagem: </P>
                        <P weight="400">{s.message}</P>
                      </Div>
                      <Div>
                        <P weight="600">Preletor: </P>
                        <P weight="400">{s.pastor}</P>
                      </Div>
                    </Section>
                  ))}
                </Card>
              </div>
          </>
        )}
      <br />
      <Name>Pastores e Preletores</Name>
      <br />
      <PersonManagement />
      <br />
      </CardsContainer>
      <Modal
        open={showModal}
        onClose={() => setShowModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <IconButton
            aria-label="close"
            onClick={() => setShowModal(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <IoClose />
          </IconButton>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Editar Seção
          </Typography>
          <Grid container spacing={2}>
          {services && (
            <>
              <Grid item xs={12}>
                {renderTextField('Domingo de Manhã Horário', 'time', services.week?.time, (e) => handleInputChange(e, 'week', 'time'))}
                {renderTextField('Domingo de Manhã Mensagem', 'message', services.week?.message, (e) => handleInputChange(e, 'week', 'message'))}
                {renderTextField('Domingo de Manhã Preletor', 'pastor', services.week?.pastor, (e) => handleInputChange(e, 'week', 'pastor'))}
              </Grid>
              <Grid item xs={12}>
                  {renderTextField('Domingo a Noite Horário', 'timeNight', services.week?.timeNight, (e) => handleInputChange(e, 'week', 'timeNight'))}
                  {renderTextField('Domingo a Noite Mensagem', 'messageNigth', services.week?.messageNigth, (e) => handleInputChange(e, 'week', 'messageNigth'))}
                  {renderTextField('Domingo a Noite Preletor', 'pastorNigth', services.week?.pastorNigth, (e) => handleInputChange(e, 'week', 'pastorNigth'))}
              </Grid>
            </>
          )}
          {services && services.extraordinary.services.map((extraService, index) => (
            <React.Fragment key={index}>
              <FormControlLabel
                style={{ marginLeft: '10px', marginTop: '16px' }}
                control={<Checkbox checked={services.extraordinary.hide} onChange={(e) => handleInputChange(e, 'extraordinary', 'hide')} />}
                label="Ocultar cultos extraordinários"
              />
              <Grid item xs={12}>
                {renderTextField('Intenção', `reason-${index}`, extraService.reason, (e) => handleInputChange(e, 'extraordinary', '', index, 'reason'))}
                {renderTextField('Horário', `time-${index}`, extraService.time, (e) => handleInputChange(e, 'extraordinary', '', index, 'time'))}
                {renderTextField('Mensagem', `message-${index}`, extraService.message, (e) => handleInputChange(e, 'extraordinary', '', index, 'message'))}
                {renderTextField('Preletor', `pastor-${index}`, extraService.pastor, (e) => handleInputChange(e, 'extraordinary', '', index, 'pastor'))}
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
          <Button hide={false} onClick={() => updateServices(services)}>
            Salvar Mudança
          </Button>
        </Box>
      </Modal>
      <BackButton goBack={() =>  navigate(-1)} />
    </Container>
  );
};

export default Services;
