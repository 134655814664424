import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: calc(100vh - 56px);
  overflow: hidden;
  @media (max-width: 1024px) {
    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none; /* For Internet Explorer and Edge */
    ::-webkit-scrollbar {
      display: none; /* For Chrome, Safari and Opera */
    }
  }
  iframe {
    width: 100%;
    height: calc(100vh - 133px);
    /* height: 945px;
    @media (max-height: 850px) {
      height: 710px !important;
    }
    @media (min-height: 850px) and (max-height: 1184px) {
      height: 810px !important;
    } */
  }
`;

export const ContentContainer = styled.div`
  width: 100%;
  height: calc(100vh - 240px);
  padding-block: 23px;
  overflow-y: auto;
  margin-top: 35px;
  @media (max-width: 1024px) {
    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none; /* For Internet Explorer and Edge */
    ::-webkit-scrollbar {
      display: none; /* For Chrome, Safari and Opera */
    }
  }
`;

export const MenuContent = styled.div`
  position: relative;
  max-width: 950px;
  margin-inline: auto;
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
  justify-content: center;
  div:hover {
    transform: scale(1.05);
    cursor: pointer;
  }
  img {
    height: 126px !important;
    top: 10px !important;
  }
`;

export const Header = styled.div`
  background-color: var(--color-primary-2);
  height: 68px;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2;
`;

export const Text = styled.div`
  color: var(--color-neutral-2);
  font-family: 'Nunito-Bold', Helvetica;
  font-size: 28px;
  font-weight: 700;
  left: 23px;
  letter-spacing: -0.17px;
  line-height: 18px;
  position: fixed;
  text-align: center;
  top: 25px;
  white-space: nowrap;
`;

export const Name = styled.h2`
  color: #000000;
  font-family: 'Poppins-SemiBold', Helvetica;
  letter-spacing: -0.17px;
  line-height: 18px;
  text-align: center;
  position: relative;
  top: 35px;
  width: 350px;
  display: inline-flex;
  margin-inline: calc(50% - 175px);
  justify-content: space-between;
`;

export const Button = styled.div`
  display: block;
  height: 33px;
  padding: 10px;
  margin-bottom: 20px;
  background-color: var(--color-primary-2);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 340px;
  text-align: center;
  font-weight: 600;
  font-family: 'Nunito', sans-serif;
  margin-inline: auto !important;
  line-height: 1;
`;