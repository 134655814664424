import styled, { css } from 'styled-components';

export const Container = styled.div<{ mode2: boolean }>`
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0px 8px 8px #8163d626;
  min-height: 116px;
  position: relative;
  min-width: 155px; // 170px;
  display: block;
  user-select: none;
  object-fit: contain;
  @media (hover: none) {
    &:active {
      transform: scale(1.05);
    }
  }
  ${({ mode2 }) =>
    mode2 &&
    css`
      min-height: 137px;
      min-width: 359px;
    `}
`;

export const Image = styled.img<{ mode2: boolean }>`
  height: 60px;
  left: 47.5px; // 55px;
  position: absolute;
  top: 14px;
  width: 60px;
  object-fit: contain;
  ${({ mode2 }) =>
    mode2 &&
    css`
      height: 141px;
      width: 147.07px;
      left: calc(100% - 162px);
      top: -5px;
    `}
`;

export const Title = styled.p<{ mode2: boolean }>`
  position: relative;
  top: 75px;
  width: 100%;
  text-align: center;
  color: #4a4a4a;
  font-family: 'Poppins-Regular', Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
  ${({ mode2 }) =>
    mode2 &&
    css`
      left: 18px;
      top: 18px;
      width: fit-content;
      text-align: left;
      font-weight: bold;
      font-size: 17px;
      font-family: 'Roboto-Medium', Helvetica;
      letter-spacing: -0.17px;
      line-height: 18px;
    `}
`;

export const Text = styled.p<{ display: string }>`
  display: ${({ display }) => display || 'none'};
  color: #4a4a4a;
  font-family: 'Roboto-Light', Helvetica;
  font-size: 13px;
  font-weight: 300;
  left: 18px;
  letter-spacing: -0.17px;
  line-height: 18px;
  position: absolute;
  top: 52px;
  max-width: 188px;
`;
