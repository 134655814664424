import React from "react";
import { FaArrowLeft } from "react-icons/fa";
import { Float } from "./styles";

const BackButton: React.FC<{ goBack: () => void }> = ({ goBack }) => {
    return (
      <Float onClick={goBack}><FaArrowLeft /></Float>
    );
  };

export default BackButton;
