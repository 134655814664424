import styled from 'styled-components';

export const Container = styled.div`
  height: calc(100vh - 136px);
  overflow: auto;
    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none; /* For Internet Explorer and Edge */
    ::-webkit-scrollbar {
      display: none; /* For Chrome, Safari and Opera */
    }
  width: 100%;
  padding-top: 23px;
  padding-inline: 50px;
  /* max-width: 950px; */
  margin-inline: auto;
  justify-content: space-between;
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
  justify-content: center;
  /* margin-top: 125px; */
  @media only screen and (max-width: 400px) {
    width: calc(100% - 50px);
  }
  .card:hover,
  .play:hover {
    transform: scale(1.05);
    cursor: pointer;
  }
`;

export const SearchBar = styled.input`
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-inline: calc(50% - 175px);
  margin-block: 20px;
  width: 350px;
  height: 40px;
  position: relative;
  top: 12px;
`;

export const ContentContainer = styled.div`
  width: 100%;
  /* height: calc(100vh - 240px); */
  padding-block: 23px;
  overflow-y: auto;
  margin-top: 35px;
  display: contents;
  overflow: hidden;
  @media (max-width: 1024px) {
    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none; /* For Internet Explorer and Edge */
    ::-webkit-scrollbar {
      display: none; /* For Chrome, Safari and Opera */
    }
  }
`;