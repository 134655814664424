import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, IconButton } from '@mui/material';
import { Map } from 'components';
import { ElfsightWidget } from 'react-elfsight-widget';
import ChurchImage from 'assets/images/ChurchImage.png';
import VectorImg from 'assets/images/Vector.svg';
import {
  ClickMe,
  Container,
  Group,
  MainImg,
  MapBox,
  Overlap,
  ReviewContainer,
  StyledDiv,
  TextWrapper,
  Vector,
} from './styles';
// import axios from 'axios';

const Maps: React.FC = () => {
  const [fullscreen, setFullScreen] = useState(false);

  useEffect(() => {
    const handleEsc = event => {
      if (event.key === 'Escape') {
        setFullScreen(true);
      }
    };

    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, []);

  // const [reviews, setReviews] = useState<any[]>([]);
  
  // const GOOGLE_MAPS_API_KEY = `${process.env.REACT_APP_PLACES_API_KEY}`;

  // useEffect(() => {
  //   const fetchReviews = async () => {
  //     try {
  //       const response = await axios.get(`https://maps.googleapis.com/maps/api/place/details/json?latlng=-18.905640,-48.270210&key=${GOOGLE_MAPS_API_KEY}`);
  //       setReviews(response.data.result.reviews);
  //     } catch (error) {
  //       console.error('Failed to fetch reviews:', error);
  //     }
  //   };
  
  //   fetchReviews();
  // }, []);
  
  return (
    <Container>
        <MapBox onClick={() => setFullScreen(!fullscreen)}>
          <Group>
            <Overlap>
              <TextWrapper>
                Av. João Pinheiro, 2001 - Nossa Sra. Aparecida, Uberlândia - MG,
                38400-712
              </TextWrapper>
              <Vector alt="Vector" src={VectorImg} />
              <StyledDiv>Igreja Batista Central de Uberlândia</StyledDiv>
            </Overlap>
            <ClickMe>Clique!</ClickMe>
            <MainImg src={ChurchImage} alt="church" />
          </Group>
        </MapBox>
        <Dialog fullWidth open={fullscreen} onClose={() => setFullScreen(!fullscreen)}>
          <DialogTitle>
            Informações
            <IconButton
              aria-label="close"
              onClick={() => setFullScreen(!fullscreen)}
              sx={{ position: 'absolute', right: 8, top: 8 }}
            >
              &times;
            </IconButton>
          </DialogTitle>
          {fullscreen && <Map />}
        </Dialog>
      <ReviewContainer id="alleatory">
        {/* {reviews.map((review, index) => (
          <div key={index}>
            <h5>{review?.author_name}</h5>
            <p>{review?.text}</p>
            <p>Rating: {review?.rating}</p>
          </div>
        ))} */}
        <ElfsightWidget widgetId="887bb8e0-79da-4f8b-8725-272619a526fb" />
      </ReviewContainer>
    </Container>
  );
};

export default Maps;
