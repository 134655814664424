import React from 'react';
import { ElfsightWidget } from 'react-elfsight-widget';
import { Container, SocialMediaCard } from './styles';

const Medias: React.FC = () => {
  const fbMedia =
    'https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fibcudi%2F&tabs=timeline&width=350&height=967&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId';

  return (
    <Container>
      <SocialMediaCard>
        <iframe
          title="facebook-ibcudia"
          src={fbMedia}
          width="350"
          height="967"
          style={{ border: 'none', overflow: 'hidden', padding: 'none' }}
          scrolling="no"
          frameBorder="0"
          allowFullScreen
          allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
        />
      </SocialMediaCard>
      <br />
      <SocialMediaCard>
      <ElfsightWidget widgetId="82b63b7c-5221-4a82-992e-e921688ed6c7" />
      </SocialMediaCard>
    </Container>
  );
};

export default Medias;
