import React from 'react';
import NF404 from 'assets/images/404.svg';
import { Container, ImageContainer, Elipse, Image, Text } from './styles';

const NotFound: React.FC = () => {
  return (
    <Container>
      <ImageContainer>
        <Elipse />
        <Image draggable="false" src={NF404} alt="404" />
        <Text>Não Encontrado</Text>
      </ImageContainer>
    </Container>
  );
};

export default NotFound;
