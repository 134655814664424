import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: calc(100vh - 56px);
  overflow: hidden;
`;

export const ContentContainer = styled.div`
  width: 100%;
  height: calc(100vh - 275px);
  padding-block: 23px;
  overflow-y: auto;
  @media (max-width: 1024px) {
    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none; /* For Internet Explorer and Edge */
    ::-webkit-scrollbar {
      display: none; /* For Chrome, Safari and Opera */
    }
  }
`;

export const MenuContent = styled.div`
  position: relative;
  height: calc(100vh - 56px);
  max-width: 950px;
  margin-inline: auto;
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
  justify-content: center;
  div:hover {
    transform: scale(1.05);
    cursor: pointer;
  }
  a:last-child {
    div {
      margin-bottom: 30px;
    }
  }
`;

export const ImageContainer = styled.div`
  position: relative;
  width: 220px;
  height: 220px;
  top: -20px;
  left: calc(100% - 220px);
  overflow: hidden;
`;

export const Image = styled.img`
  position: relative;
  top: -93px;
  left: 20px;
  width: 300px;
  height: fit-content;
  user-select: none;
`;

export const Title = styled.div`
  position: relative;
  left: 4.246%;
  top: 56px;
  color: #4a4a4a;
  width: fit-content;
  text-align: left;
  font-weight: bold;
  font-size: 36px;
  font-family: 'Roboto-Medium', Helvetica;
  letter-spacing: -0.17px;
  line-height: 18px;
`;
