import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Badge from '@mui/material/Badge';
import Home from 'assets/images/Home.svg';
import Notification from 'assets/images/Notification.svg';
import Maps from 'assets/images/Maps.svg';
import Settings from 'assets/images/Settings.svg';
import { Container, Options, Option } from './styles';
import { IconButton, Snackbar } from '@mui/material';
import { RiCloseFill } from 'react-icons/ri';
import useUnseenNotifications from 'utils/useUnseenNotifications';

const Footer: React.FC = () => {
  const [open, setOpen] = useState(true);

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const action = (
    <IconButton
      size="small"
      aria-label="close"
      color="inherit"
      onClick={handleClose}
    >
      <RiCloseFill fontSize="small" />
    </IconButton>
  );

  return (
    <Container>
      <Options>
        <Link to="/" style={{ textDecoration: 'none' }}>
          <Option
            className="church-footer-option"
            draggable="false"
            src={Home}
            alt="nav-option"
          />
        </Link>
        <Link
          to="/notification"
          style={{ textDecoration: 'none' }}
        >
          <Badge
            badgeContent={useUnseenNotifications()}
            color="error"
            invisible={useUnseenNotifications() === 0}
          >
            <Option
              className="church-footer-option"
              draggable="false"
              src={Notification}
              alt="nav-option"
            />
          </Badge>
        </Link>
        <Link to="/maps" style={{ textDecoration: 'none' }}>
          <Option
            className="church-footer-option"
            draggable="false"
            src={Maps}
            alt="nav-option"
          />
        </Link>
        <Link to="/config" style={{ textDecoration: 'none' }}>
          <Option
            className="church-footer-option"
            draggable="false"
            src={Settings}
            alt="nav-option"
          />
        </Link>
      </Options>
      <Snackbar
        open={useUnseenNotifications() > 0 && open}
        autoHideDuration={6000}
        onClose={handleClose}
        message="Você possui avisos não vistos!"
        action={action}
        sx={{
          zIndex: 99999,
        }}
      />
    </Container>
  );
};

export default Footer;
