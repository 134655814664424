import React, { createContext, useContext, useState, useEffect, useMemo } from 'react';
import { auth } from '../firebaseConfig';
import { WithChildren } from 'utils';

const adminEmails = [
  "gustavobmachado105@gmail.com",
  "ibcudiaonline@gmail.com",
];

interface ProviderContextData {
  isAdmin: boolean;
  setIsAdmin: React.Dispatch<React.SetStateAction<boolean>>;
  currentUser: any;
  setCurrentUser: React.Dispatch<React.SetStateAction<any>>;
}

const AuthContext = createContext<ProviderContextData>({
  isAdmin: false,
  setIsAdmin: () => {},
  currentUser: null,
  setCurrentUser: () => {},
});

export const AuthProvider: React.FC<WithChildren> = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      if (user) {
        setIsAdmin(adminEmails.includes(user.email));
      } else {
        setIsAdmin(false);
      }
    });

    return () => unsubscribe();  // Clean up the subscription
  }, []);


  const value = useMemo(() => ({
    currentUser,
    setCurrentUser,
    isAdmin,
    setIsAdmin,
  }), [currentUser, isAdmin]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = (): ProviderContextData => {
  return useContext(AuthContext);
};
