import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
`;

export const ImageContainer = styled.div`
  position: relative;
  width: 416px;
  aspect-ratio: 1 / 1;
`;

export const Elipse = styled.div`
  background-color: #eff3f8;
  border-radius: 50%;
  box-shadow: 0px 4px 4px #00000040;
  position: absolute;
  top: 10px;
  left: 0;
  width: 100%;
  aspect-ratio: 1 / 1;
`;

export const Image = styled.img`
  position: relative;
  height: 100%;
  top: 0;
  left: 0;
  width: 100%;
  user-select: none;
`;

export const Text = styled.div`
  color: #09416c;
  font-family: 'Poppins-Bold', Helvetica;
  font-size: 36px;
  font-weight: 700;
  position: absolute;
  width: 100%;
  height: fit-content;
  margin-top: 75px;
  padding-inline: 25px;
  letter-spacing: 0;
  line-height: 55px;
  text-align: center;
  text-shadow: 0px 4px 4px #00000040;
`;
