import { Dialog, Button } from "@mui/material";
import Textarea from '@mui/joy/Textarea';
import { useState } from "react";

const NotificationModal = ({ open, onClose, notificationData, onSave, onDelete }) => {
    const [message, setMessage] = useState(notificationData?.message || '');
  
    const handleSubmit = () => {
      onSave(message);
    };

    const handleDelete = () => {
      if (window.confirm("Tem certeza de que deseja deletar esse aviso?")) {
        onDelete();
      }
    };
  
    return (
      <Dialog open={open} onClose={onClose}>
        <Textarea
          color="primary"
          disabled={false}
          minRows={2}
          placeholder="Insira sua mensagem"
          variant="outlined"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
        <Button onClick={handleSubmit}>Salvar</Button>
        <Button onClick={handleDelete}>Deletar</Button>
      </Dialog>
    );
  };

export default NotificationModal;