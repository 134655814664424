import React, { useState } from 'react';
import { CloseButton, Container, Frame, Navbar, Overlay, OverlayContent, StyledLink } from './styles';

const Confession: React.FC = () => {
  const urls = [
    `${process.env.PUBLIC_URL}/confessions/ddcbb.html`,
    `${process.env.PUBLIC_URL}/confessions/cfb1689.html`,
    `${process.env.PUBLIC_URL}/confessions/cfnh.html`,
    `${process.env.PUBLIC_URL}/confessions/ocgb.html`,
  ];

  const [selectedUrl, setSelectedUrl] = useState(urls[0]);
  const [isOpen, setIsOpen] = useState(false);

  const openNav = () => {
    setIsOpen(true);
  };

  const closeNav = () => {
    setIsOpen(false);
  };

  return (
    <Container>
      <Overlay isOpen={isOpen}>
        <CloseButton href="javascript:void(0)" onClick={closeNav}>
          &times;
        </CloseButton>
        <OverlayContent>
          <StyledLink onClick={() => {
            setSelectedUrl(urls[0]);
            closeNav();
          }}
          >Declaração Doutrinária CBB</StyledLink>
          <StyledLink onClick={() => {
            setSelectedUrl(urls[1]);
            closeNav();
          }}
          >2° Confissão de Fé de Londres (1689)</StyledLink>
          <StyledLink onClick={() => {
            setSelectedUrl(urls[2]);
            closeNav();
          }}
          >Confissão de Fé de New Hampshire</StyledLink>
          <StyledLink onClick={() => {
            setSelectedUrl(urls[3]);
            closeNav();
          }}
          >Credo Ortodoxo</StyledLink>
        </OverlayContent>
      </Overlay>
      <Navbar onClick={openNav}>
        &#9776; Confissões
      </Navbar>
      <Frame src={selectedUrl} title="Confessions" />
    </Container>
  );
};

export default Confession;
