import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  position: fixed;
  height: calc(100vh - 56px);
  overflow-y: auto;
  overflow-x: hidden;
  @media (max-width: 1024px) {
    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none; /* For Internet Explorer and Edge */
    ::-webkit-scrollbar {
      display: none; /* For Chrome, Safari and Opera */
    }
  }
`;

export const MapBox = styled.div`
  position: relative;
  width: 100%;
  padding-inline: 10px;
  /* aspect-ratio: 16 / 9; */
  margin-top: -30px; // 15px;
  display: flex;
  margin-inline: auto;
  scale: 0.85;
  justify-content: center;
  cursor: pointer;
`;

export const Group = styled.div`
  height: 408px;
  left: 0;
  position: relative;
  top: 0;
  /* width: 425px; */
`;

export const Overlap = styled.div`
  background-color: #ffffff;
  border-radius: 0px 0px 16px 16px;
  box-shadow: 0px 8px 8px #8163d626;
  height: 94px;
  left: 4px;
  position: relative;
  top: 314px;
  width: 414px;
`;

export const TextWrapper = styled.p`
  color: #000000;
  font-family: 'Roboto-Regular', Helvetica;
  font-size: 13px;
  font-weight: 400;
  left: 55px;
  letter-spacing: -0.17px;
  line-height: 18px;
  position: relative;
  top: 43px;
  width: 266px;
`;

export const Vector = styled.img`
  height: 15px;
  left: 22px;
  position: relative;
  top: 14px;
  width: 11px;
`;

export const StyledDiv = styled.p`
  color: #000000;
  font-family: 'Roboto-Medium', Helvetica;
  font-size: 14px;
  font-weight: 600;
  left: 22px;
  letter-spacing: -0.17px;
  line-height: 18px;
  position: relative;
  top: -42px;
  white-space: nowrap;
  width: 216px;
`;

export const MainImg = styled.img`
  left: 4px !important;
  position: relative !important;
  top: -96px !important;
  border-radius: 16px 16px 0 0;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
`;

export const ClickMe = styled.div`
  width: 81px;
  height: 39px;
  text-align: center;
  font-weight: 600;
  padding-block: 10px;
  z-index: 3;
  position: relative;
  top: calc(50% - 80px);
  left: calc(50% - 40px);
  background-color: var(--color-primary-1);
  box-shadow: 0px 0px 6px 2px var(--color-system-3);
  animation: spark 1000ms infinite alternate;
  @keyframes spark {
    0% {
      box-shadow: none;
    }
    100% {
      box-shadow: 0px 0px 6px 2px var(--color-system-3);
    }
  }
`;

export const ReviewContainer = styled.div`
  width: calc(100% - 30px);
  margin-inline: 15px;
  /* margin-top: 35px; */
  margin-bottom: 65px;
  .es-widget-title-container,
  .hide {
    display: none !important;
  }
`;
