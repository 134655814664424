import styled from "styled-components";

export const Float = styled.a`
	position:fixed;
	width:60px;
	height:60px;
	bottom:80px;
	right:20px;
	background-color:var(--color-primary-2);
	color:var(--color-neutral-2);
	border-radius:50px;
	text-align:center;
	box-shadow: 2px 2px 3px #999;
  svg {
    position: relative;
    margin-top:22px;
  }
`;
