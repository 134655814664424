import styled from 'styled-components';

export const TooltipContainer = styled.div`
  position: relative;
  display: inline-block;
`;

export const TooltipText = styled.span<{ isVisible: boolean; width: string; transform: string }>`
  visibility: ${props => props.isVisible ? 'visible' : 'hidden'};
  width: ${({width}) => width || '120px'};
  transform: ${({transform}) => transform || 'translate(0, 0)'};
  background-color: #a8a7a7;
  color: var(--color-system-1);
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -60px;
  opacity: ${props => props.isVisible ? 1 : 0};
  transition: opacity 0.3s;
`;
