import React, { useEffect, useState } from 'react';
import { db, Timestamp } from '../../firebaseConfig';
import { doc, updateDoc, arrayUnion, arrayRemove } from 'firebase/firestore';
import { formatDistanceToNow } from 'date-fns';
import { IoMdHeart, IoMdHeartEmpty } from 'react-icons/io';
import { FaComments } from 'react-icons/fa';
import Avatar from '@mui/material/Avatar';
import {
  Container,
  Name,
  StyledLikeCommentSection,
  StyledSeparator,
  StyledText,
  TextContainer,
  Time,
  Text,
  Options,
} from './styles';
import { ptBR } from 'date-fns/locale';
import { useAuth } from 'providers/AuthProvider';
import NotificationCommentsModal from 'components/NotificationComments';
import { Modal, Box, Typography, Button } from '@mui/material';

export interface NotificationProps {
  id: string;
  profileURL: string;
  name: string;
  message: string;
  creationTime: Timestamp;
  likes: string[];
  comments: string[];
  onOptionsClick: (id: string) => void;
}

const Notification: React.FC<NotificationProps> = ({ id, profileURL, name, message, creationTime, likes, comments, onOptionsClick }) => {
  const timeAgo = formatDistanceToNow(creationTime.toDate(), {locale: ptBR});
  const { currentUser } = useAuth();
  const [myLike, setMyLike] = useState(likes && Array.isArray(likes) && likes.includes(currentUser?.uid));
  const [openComments, setOpenComments] = useState(false);

  const handleLike = async () => {
    if (!myLike) {
      // Adds the current user's id to the likes array if not already liked
      try {
        const notificationRef = doc(db, 'notifications', id);
        await updateDoc(notificationRef, {
          likes: arrayUnion(currentUser.uid)
        });
        setMyLike(true);
        console.log("Liked successfully");
      } catch (error) {
        console.error("Error updating likes: ", error);
      }
    } else {
      // Removes the current user's id from the likes array if already liked
      try {
        const notificationRef = doc(db, 'notifications', id);
        await updateDoc(notificationRef, {
          likes: arrayRemove(currentUser.uid)
        });
        setMyLike(false);
        console.log("Like removed successfully");
      } catch (error) {
        console.error("Error updating likes: ", error);
      }
    }
  };

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    borderRadius: '4px',
    boxShadow: 24,
    p: 4,
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [open2, setOpen2] = React.useState(false);
  const handleOpen2 = () => setOpen2(true);
  const handleClose2 = () => setOpen2(false);

  const already = localStorage.getItem('alreadyResponded');

  const handleComment = () => {
    if (!openComments) {
      if (already === 'over') setOpenComments(true);
      else handleOpen();
    } else {
      setOpenComments(false);
    }
  };

  return (
    <>
    <Container>
      <StyledSeparator />
      <StyledLikeCommentSection>
        <div style={{ display: 'contents' }} onClick={handleLike}>
          {!myLike ?
            <IoMdHeartEmpty size="1.5em" color="var(--color-primary-2)" /> :
            <IoMdHeart size="1.5em" color="var(--color-primary-2)" />}
          <StyledText>{likes.length === 1 ? `${likes.length} Like` : `${likes.length} Likes`}</StyledText>
        </div>
        <div style={{ display: 'contents' }} onClick={handleComment}>
          <FaComments
            size="1.5em"
            color="#9f9f9f"
            style={{ marginLeft: '20px' }}
          />
          <StyledText>{comments.length} Comentários</StyledText>
        </div>
      </StyledLikeCommentSection>
      <Avatar
        alt={name}
        src={profileURL}
        style={{
          position: 'absolute',
          top: 22,
          left: 19,
          width: 50,
          height: 50,
        }} />
      <TextContainer>
        <Text>{message}</Text>
      </TextContainer>
      <Name>{name}</Name>
      <Time>{timeAgo} atrás</Time>
      <Options onClick={() => onOptionsClick(id)}>...</Options>
    </Container>
    <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" align="center" variant="h6" component="h2">
            Você tem pelo menos 13?
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Você deve ter pelo menos 13 anos para acessar os comentários!
          </Typography>
          <Button onClick={() => { handleClose(); handleOpen2(); }}>Tenho mais de 13 anos!</Button>
          <Button style={{ float: 'right' }} onClick={() => { handleClose(); }}>Sair</Button>
        </Box>
    </Modal>
    <Modal
        open={open2}
        onClose={handleClose2}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" align="center" variant="h6" component="h2">
            Lembrete de segurança on-line
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Lembre-se de estar seguro online! Evite compartilhar informações pessoais e esteja ciente dos riscos potenciais das interações online.
          </Typography>
          <Button style={{ float: 'right' }} onClick={() => { handleClose2(); localStorage.setItem('alreadyResponded', 'over'); setOpenComments(true); }}>OK</Button>
        </Box>
    </Modal>
    <NotificationCommentsModal notificationId={id} isOpen={openComments} />
    </>
  );
};
export default Notification;
