import React, { useEffect, useState } from 'react';
import { User } from 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
import {
  auth,
  createUser,
  signIn,
  signInWithGoogle,
  signInWithFacebook,
  signInWithTwitter,
  resetPassword,
  logOut,
  updateUserProfile,
  updateUserEmail,
  updateUserPassword,
  uploadUserPhoto,
  updateUserPhoneNumber,
} from '../../firebaseConfig';
import Logo from 'assets/images/Text_Logo.png';
import { FaArrowLeft, FaEye, FaEyeSlash } from "react-icons/fa";
import {
  Container,
  Content,
  Div,
  Form,
  Name,
  Overlap,
  Vector,
  SocialButton,
  SocialButtonCollection,
  Header,
  Text,
  ProfilePicture,
  Body,
  Input,
  Title,
  Button,
  ProfileForm,
  OptionText,
  Frame,
  Pill,
  TextTitle,
  TextArea,
  Text2,
} from './styles';
import { send } from 'emailjs-com';
import { BackButton, Tooltip } from 'components/index';

const OptionButton: React.FC<{ text: string, onclick: () => void }>  = ({ text, onclick }) => (
  <Pill onClick={onclick}>
    <OptionText>{text}</OptionText>
  </Pill>
);

const AuthButtons: React.FC = () => {
  const handleGoogleSignIn = event => {
    event.preventDefault();
    signInWithGoogle();
  }
  const handleFacebookSignIn = event => {
    event.preventDefault();
    signInWithFacebook();
  }
  const handleTwitterSignIn = event => {
    event.preventDefault();
    signInWithTwitter();
  }
  return (
    <SocialButtonCollection>
      <SocialButton className="google" onClick={handleGoogleSignIn}>
        <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M29.3337 13.1876L17.0766 13.187C16.5354 13.187 16.0967 13.6236 16.0967 14.1622V18.059C16.0967 18.5975 16.5354 19.0342 17.0766 19.0342H23.979C23.2232 20.9863 21.8125 22.6211 20.0127 23.6598L22.9559 28.7303C27.6771 26.0129 30.4683 21.245 30.4683 15.9077C30.4683 15.1477 30.4121 14.6045 30.2995 13.9928C30.2139 13.528 29.8085 13.1876 29.3337 13.1876Z" fill="#167EE6" /><path d="M15.4433 24.889C12.0654 24.889 9.11652 23.0523 7.53274 20.3344L2.43799 23.2568C5.03067 27.7287 9.88761 30.7401 15.4433 30.7401C18.1688 30.7401 20.7404 30.0098 22.9558 28.7371V28.7302L20.0126 23.6597C18.6663 24.4368 17.1084 24.889 15.4433 24.889Z" fill="#12B347" /><path d="M22.9558 28.7372V28.7303L20.0126 23.6598C18.6664 24.4368 17.1086 24.8891 15.4434 24.8891V30.7402C18.1688 30.7402 20.7406 30.0099 22.9558 28.7372Z" fill="#0F993E" /><path d="M6.29767 15.7874C6.29767 14.1304 6.75206 12.5802 7.53271 11.2404L2.43796 8.31799C1.15209 10.5158 0.418335 13.0681 0.418335 15.7874C0.418335 18.5067 1.15209 21.0591 2.43796 23.2568L7.53271 20.3344C6.75206 18.9946 6.29767 17.4444 6.29767 15.7874Z" fill="#FFD500" /><path d="M15.4433 6.68579C17.6461 6.68579 19.6694 7.46473 21.2498 8.76042C21.6397 9.08003 22.2063 9.05696 22.5635 8.70154L25.3378 5.94055C25.743 5.53729 25.7141 4.87721 25.2813 4.50351C22.6334 2.21743 19.1882 0.834717 15.4433 0.834717C9.88761 0.834717 5.03067 3.84611 2.43799 8.31803L7.53274 11.2405C9.11653 8.52252 12.0654 6.68579 15.4433 6.68579Z" fill="#FF4B26" /><path d="M21.2498 8.76042C21.6397 9.08004 22.2064 9.05696 22.5635 8.70155L25.3378 5.94055C25.743 5.53729 25.7141 4.87721 25.2813 4.50351C22.6334 2.21738 19.1883 0.834717 15.4434 0.834717V6.68579C17.646 6.68579 19.6694 7.46473 21.2498 8.76042Z" fill="#D93F21" /></svg>
      </SocialButton>
      <SocialButton className="facebook" onClick={handleFacebookSignIn}>
        <svg width="16" height="31" viewBox="0 0 16 31" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.0664 5.80027H15.8097V1.0453C15.3364 0.980506 13.7087 0.834717 11.813 0.834717C3.1336 0.834717 5.49502 10.6163 5.14945 12.0493H0.783447V17.365H5.1482V30.7402H10.4996V17.3662H14.6878L15.3527 12.0505H10.4983C10.7337 8.53163 9.5455 5.80027 13.0664 5.80027Z" fill="#3B5999" /></svg>
      </SocialButton>
      <SocialButton className="twitter" onClick={handleTwitterSignIn}>
        <svg width="26" height="23" viewBox="0 0 26 23" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M9.74003 12.1864L0 0.083313H7.71479L13.7272 7.56384L20.1506 0.116994H24.3995L15.7816 10.1198L26 22.8333H18.3082L11.798 14.7436L4.84765 22.8109H0.575731L9.74003 12.1864ZM19.4294 20.5908L4.73045 2.3258H6.59279L21.2732 20.5908H19.4294Z" fill="#1C1C1C"/></svg>
      </SocialButton>
      {/* <SocialButton className="phone" onClick={() => signInWithPhoneNumber}>
        <svg width="26" height="23" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#74C0FC" d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z"/></svg>
      </SocialButton> */}
    </SocialButtonCollection>
  );
};

const UserProfile: React.FC<{ user: User }> = (user) => {
  const [displayName, setDisplayName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [photo, setPhoto] = useState('');
  // const [smsCode, setSmsCode] = useState<number>();
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [newPwd, setNewPwd] = useState(false);
  const [confirmPwd, setConfirmPwd] = useState(false);

  const [selectedFile, setSelectedFile] = useState<File>();

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };
  
  useEffect(() => {
    if (user) {
      setDisplayName(user.user.displayName || '');
      setEmail(user.user.email || '');
      setPhone(user.user.phoneNumber || '');
      setPhoto(user.user.photoURL || '');
    }
  }, [user]);

  // console.log(user.user);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      if (displayName !== user.user.displayName) {
        await updateUserProfile(user.user, { displayName });
      }
      if (email !== user.user.email) {
        await updateUserEmail(user.user, email);
      }
      if (newPassword && newPassword === confirmPassword) {
        await updateUserPassword(user.user, newPassword);
      }
      if (phone !== user.user.phoneNumber) {
        await updateUserPhoneNumber(phone);
        // await handlePhoneNumberUpdate(user.user.uid, phone);
      }
      if (selectedFile && selectedFile.name !== user.user.photoURL) {
        await handlePhotoUpload(user.user.uid, selectedFile);
      }
      alert('Perfil atualizado com sucesso!');
    } catch (error) {
      console.error('Error updating profile: ', error);
    }
  };

  const handlePhotoUpload = async (id, photoFile) => {
    try {
      const downloadURL = await uploadUserPhoto(id, photoFile);
      setPhoto(downloadURL);
      await updateUserProfile(auth.currentUser, { photoURL: downloadURL });
      console.log('Photo uploaded and profile updated.');
    } catch (error) {
      console.error('Error uploading photo: ', error);
    }
  };

  return (
    <div className="user-profile">
      <Header>
        <Text>Gerenciar Perfil</Text>
        <ProfilePicture>
          <img src={photo} alt="Profile" />
        </ProfilePicture>
      </Header>
      <Body>
        <ProfileForm onSubmit={handleSubmit}>
          <Title>Nome de Usuário</Title>
          <Input
            type="text"
            placeholder="Nome de Usuário"
            value={displayName}
            onChange={(e) => setDisplayName(e.target.value)}
          />
          <Title>Email</Title>
          <Input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            disabled
          />
          <Title>Contato (ex.: +55349xxxx-xxxx)</Title>
          <Input
            type="tel"
            placeholder="Telefone Celular"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
          <div id="recaptcha-container"></div>
          <Title>Nova Senha</Title>
          <Input
            type={!newPwd ? "password" : "text"}
            placeholder="Nova Senha"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
          <i onClick={() => setNewPwd(!newPwd)}>{newPwd ? <FaEye /> : <FaEyeSlash />}</i>
          <Title>Confirme a Senha</Title>
          <Input
            type={!confirmPwd ? "password" : "text"}
            placeholder="Confirme a Senha"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <i onClick={() => setConfirmPwd(!confirmPwd)}>{confirmPwd ? <FaEye /> : <FaEyeSlash />}</i>
          <Title>Foto de Perfil</Title>
          <input style={{ color: '#000', border: '2px solid #2a2b3150', borderRadius: '5px', padding: '3px' }} type="file" accept="image/png, image/jpeg" onChange={handleFileChange} />
          <Button width='325px' type="submit">Atualizar</Button>
        </ProfileForm>
      </Body>
    </div>
  );
};

const Help: React.FC<{ user: User }> = (user) => {
  const [state, setState] = useState(false);
  const [toSend, setToSend] = useState({
    from_name: user.user.displayName,
    to_name: 'equipe-ibcu',
    message: '',
    reply_to: 'ibcudiaonline@gmail.com',
    cc_email: ''
  });

  useEffect(() => {
    setToSend({ ...toSend, cc_email: user.user.email || '' });
  }, [state]);

  const onSubmit = (e) => {
    e.preventDefault();
    alert('Aguarde um instante...');
    send('ibcu_online', 'ibcu_online', toSend, 'QRRLZnUcoZo2Uccbm')
      .then((response) => {
        alert('Seu email foi enviado com sucesso!');
        console.log('Email sent successfully!', response);
      })
      .catch((error) => {
        console.error('Error sending email:', error);
      });
  };

  const handleChange = (e) => {
    setToSend({ ...toSend, [e.target.name]: e.target.value });
  };

  return (
    <>
      <Header>
        <TextTitle>Contate os Desenvolvedores</TextTitle>
      </Header>
      <Body>
        <form onSubmit={onSubmit}>
          <Input
            type="text"
            name="from_name"
            placeholder="Seu nome"
            value={`De: ${toSend.from_name || 'Usuário'}`}
            disabled />
          <Input
            type="text"
            name="to_name"
            placeholder="Nome do remetente"
            value={`Para: ${toSend.to_name}`}
            disabled />
          <TextArea
            name="message"
            placeholder="Sua mensagem"
            value={toSend.message}
            onChange={handleChange} />
          {/* <Input
            type="email"
            name="reply_to"
            placeholder="Your Email"
            value={toSend.reply_to}
            disabled /> */}
            <div style={{ display: 'flex', justifyContent: 'space-between', paddingTop: '10px' }}>
              <Text2>
                Enviar uma cópia
                &nbsp;
                <Tooltip 
                  width="300px"
                  transform="translate(-60px, -10px)"
                  text={`Caso deseje uma resposta, envie uma cópia para seu email. Assim, podemos responder diretamente (até 2 semanas)`}
                />
              </Text2>
              <input style={{ width: '18px', height: '18px' }} type='checkbox' onChange={() => setState(!state)} />
            </div>
          <Button width='100%' type="submit">Enviar Email</Button>
        </form>
      </Body>
    </>
  );
}

const Settings: React.FC<{ user: User }> = (user) => {
  const [currentPage, setCurrentPage] = useState('list');

  switch (currentPage) {
    case 'list':
      return (
        <>
          <Header>
            <TextTitle>Opções do Aplicativo</TextTitle>
          </Header>
          <div style={{ display: 'block', width: '250px', margin: 'auto' }}>
            <OptionButton text="Privacidade" onclick={function (): void {
              setCurrentPage('privacy');
            } } />
            <OptionButton onclick={function (): void {
              setCurrentPage('terms');
            } } text="Termos de Uso" />
            <OptionButton onclick={function (): void {
              setCurrentPage('help');
            } } text="Ajuda & Suporte" />
            <OptionButton onclick={function (): void {
              setCurrentPage('profile');
            } } text="Configurações" />
            <OptionButton onclick={() => logOut()} text="Sair" />
          </div>
        </>
      );
    case 'privacy':
      return (
        <><Frame src={`${process.env.PUBLIC_URL}/policies/privacy.html`} /><BackButton goBack={() => setCurrentPage('list')} /></>
      );
    case 'terms':
      return (
        <><Frame src={`${process.env.PUBLIC_URL}/policies/terms.html`} /><BackButton goBack={() => setCurrentPage('list')} /></>
      );
    case 'help':
      return (
        <><Help user={user.user} /><BackButton goBack={() => setCurrentPage('list')} /></>
      );
    case 'profile':
      return (
        <><UserProfile user={user.user} /><BackButton goBack={() => setCurrentPage('list')} /></>
    );
    default:
      return <div>Página não encontrada</div>;
  }
}

const Config: React.FC = () => {
  const [form, setForm] = useState(false);
  const [hide, setHide] = useState<boolean[]>([true, true, true]);
  const [email, setEmail] = useState<string[]>(['', '']);
  const [password, setPassword] = useState<string[]>(['', '']);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [user] = useAuthState(auth);

  const handleForgotPasswordClick = (event) => {
    event.preventDefault();
    const email = prompt("Por favor, insira seu e-mail para redefinição de senha:");
    if (email) {
      resetPassword(email)
        .then(() => {
          alert("E-mail de redefinição de senha enviado.");
        })
        .catch((error) => {
          console.error("Error sending password reset email: ", error);
        });
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    // console.log(email, password, confirmPassword);
    if (form) {
      // Sign up
      if (password[1] === confirmPassword) {
        try {
          await createUser(email[1], password[1]);
          alert("Usuário criado com sucesso!\nVerifique sua caixa de email!");
          setForm(false);
        } catch (error) {
          alert("Erro ao registrar-se! Confira os dados novamente");
          console.error("Error signing up: ", error);
        }
      } else {
        alert("As senhas não coincidem");
        console.error("Passwords do not match");
      }
    } else {
      // Sign in
      try {
        await signIn(email[0], password[0]);
        alert("Usuário conectado com sucesso!");
      } catch (error) {
        alert("Usuário ou senha não reconhecido!");
        console.error("Error signing in: ", error);
      }
    }
  };
  const toggleForm = () => setForm(prevForm => !prevForm);
  const toggleHide = (index: number) => {
    setHide(prevHide => prevHide.map((value, i) => (i === index ? !value : value)));
  };
  const toggleUser = (type: string, index: number, value: string) => {
    // console.log(type, index, value);
    if (type === 'email') {
      setEmail(prevEmail => prevEmail.map((v, i) => (i === index ? value : v)));
    } else if (type === 'password') {
      setPassword(prevPassword => prevPassword.map((v, i) => (i === index ? value : v)));
    }
  };
  const toggleConfirm = (value: string) => setConfirmPassword(value);

  return (
    <Container>
        {
          !user ? (
          <>
          <Div>
            <Container className={form ? 'show-signup forms' : 'forms'}>
              <Form className={form ? 'signup' : 'login'}>
                <Content>
                  <header>{form ? 'Inscreva-se' : 'Login'}</header>
                  <div>
                    {form ? (
                      <>
                        <div className="field input-field">
                          <input type="email" name="email" placeholder="Email" className="input" onChange={(e) => toggleUser('email', 1, e.target.value)} />
                        </div>
                        <div className="field input-field">
                          <input type={hide[1] ? "password" : "text"} name="password" placeholder="Crie uma senha" className="password" onChange={(e) => toggleUser('password', 1, e.target.value)} pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                            title="Deve conter pelo menos um número e uma letra maiúscula e minúscula e pelo menos 8 ou mais caracteres" />
                          <i className='bx bx-hide eye-icon' onClick={() => toggleHide(1)}>
                            {hide[1] ? <FaEye /> : <FaEyeSlash />}
                          </i>
                        </div>
                        <div className="field input-field">
                          <input type={hide[2] ? "password" : "text"} name="confirmPassword" placeholder="Confirme a Senha" className="password" onChange={(e) => toggleConfirm(e.target.value)} pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                            title="Deve conter pelo menos um número e uma letra maiúscula e minúscula e pelo menos 8 ou mais caracteres" />
                          <i className='bx bx-hide eye-icon' onClick={() => toggleHide(2)}>
                            {hide[2] ? <FaEye /> : <FaEyeSlash />}
                          </i>
                        </div>
                        <div className="field button-field">
                          <button onClick={handleSubmit}>Inscreva-se</button>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="field input-field">
                          <input type="email" name="email" placeholder="Email" className="input" onChange={(e) => toggleUser('email', 0, e.target.value)} />
                        </div>
                        <div className="field input-field">
                          <input type={hide[0] ? "password" : "text"} name="password" placeholder="Senha" className="password" onChange={(e) => toggleUser('password', 0, e.target.value)} />
                          <i className='bx bx-hide eye-icon' onClick={() => toggleHide(0)}>
                            {hide[0] ? <FaEye /> : <FaEyeSlash />}
                          </i>
                        </div>
                        <div className="form-link">
                          <a className="forgot-pass" onClick={handleForgotPasswordClick}>Esqueceu a senha?</a>
                        </div>
                        <div className="field button-field">
                          <button onClick={handleSubmit}>Login</button>
                        </div>
                      </>
                    )}
                  </div>
                </Content>
                <span style={{ display: 'inline-block', color: '#2a2b31', width: '100%', textAlign: 'center', marginTop: '15px' }}>
                  {form ? 'Já tem uma conta?' : 'Não tem uma conta?'}{' '}
                  <a onClick={toggleForm}>{form ? 'Login' : 'Inscreva-se'}</a>
                </span>
                <div className="line"></div>
                <AuthButtons />
              </Form>
            </Container>
          </Div>
          <Overlap>
            <Vector src={Logo} />
            <Name>IGREJA BATISTA CENTRAL DE UBERLÂNDIA</Name>
          </Overlap>
          </>
        ) : (
          <Settings user={user} />
          // <UserProfile user={user} />
        )
      }
    </Container>
  );
};

export default Config;
