import React, { useState } from 'react';
import { FaInfoCircle } from 'react-icons/fa';
import { TooltipContainer, TooltipText } from './styles';

const Tooltip: React.FC<{ text: string; width: string; transform: string }> = ({ text, width, transform }) => {
  const [isVisible, setIsVisible] = useState(false);

  const handleMouseEnter = () => {
    setIsVisible(true);
  };

  const handleMouseLeave = () => {
    setIsVisible(false);
  };

  const handleClick = () => {
    setIsVisible(!isVisible);
  };

  return (
    <TooltipContainer onClick={handleClick} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <FaInfoCircle />
      <TooltipText isVisible={isVisible} width={width} transform={transform}>{text}</TooltipText>
    </TooltipContainer>
  );
};

export default Tooltip;
