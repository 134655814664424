import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 99999;
  height: 56px;
  width: 100%;
  /* width: calc(100% - 50px); */
  background-color: var(--color-primary-2);
  color: var(--color-neutral-2);
  text-align: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
`;

export const Options = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  top: 16px;
  z-index: 2;
  width: 100%;
  padding-inline: 25px;
  justify-content: space-between;
  .church-footer-option:hover {
    transform: scale(1.05);
    cursor: pointer;
  }
  :nth-child(3) {
    padding: 2px;
  }
`;

export const Option = styled.img`
  width: 25px;
  user-select: none;
  @media (hover: none) {
    &:active {
      transform: scale(1.05);
    }
  }
`;
