import styled, { css } from 'styled-components';

export const Container = styled.div`
  overflow: hidden;
  width: 350px;
  margin-bottom: 56px;
  position: relative;
  @media (max-width: 1024px) {
    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none; /* For Internet Explorer and Edge */
    ::-webkit-scrollbar {
      display: none; /* For Chrome, Safari and Opera */
    }
  }
`;

export const Button = styled.div<{ hide?: boolean; top?: string }>`
  display: block;
  position: relative;
  height: 33px;
  padding: 10px;
  margin-top: ${({ top }) => top || '20px'};
  margin-bottom: 20px;
  background-color: var(--color-primary-2);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 120px;
  text-align: center;
  font-weight: 600;
  font-family: 'Nunito', sans-serif;
  margin-inline: auto !important;
  z-index: 1;
  ${({ hide }) =>
    hide &&
    css`
      display: none !important;
  `}
`;

export const ElementContainer = styled.div`
  input {
    width: 236px;
    padding: 13px;
    border: 1px solid #ced4da;
    border-radius: 3px;
    color: var(--color-neutral-6);
  }
`;
