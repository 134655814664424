import React, { useEffect, useState } from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import { Puff } from 'react-loader-spinner';
import {
  Home,
  Church,
  Medias,
  Events,
  Records,
  Communion,
  Prayer,
  Bible,
  Confession,
  Maps,
  Notification,
  UnderDevelopment,
  NotFound,
  Config,
  Birthdays,
  SBS,
  SG,
  Services,
  Activities,
} from 'pages';
import { Footer } from 'components';

const Loading = () => (
  <div style={{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh' // Altere isso para a altura que você deseja
  }}>
    <Puff color="#00BFFF" height={100} width={100} />
  </div>
);

const RouteComponent = () => {
  const location = useLocation();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, [loading]);

  useEffect(() => setLoading(true), [location]);

  return (
    <TransitionGroup>
        <CSSTransition key={location.pathname} classNames="fade" timeout={1000}>
          <Routes>
            <Route path="/" element={loading ? (<Loading />) : (<Home />)} />
            <Route path="/church" element={loading ? (<Loading />) : (<Church />)} />
            <Route path="/config" element={loading ? (<Loading />) : (<Config />)} />
            <Route path="/medias" element={loading ? (<Loading />) : (<Medias />)} />
            <Route path="/events" element={loading ? (<Loading />) : (<Events />)} />
            <Route path="/records" element={loading ? (<Loading />) : (<Records />)} />
            <Route path="/communion" element={loading ? (<Loading />) : (<Communion />)} />
            <Route path="/activities" element={loading ? (<Loading />) : (<Activities />)} />
            <Route path="/services" element={loading ? (<Loading />) : (<Services />)} />
            <Route path="/birthdays" element={loading ? (<Loading />) : (<Birthdays />)} />
            <Route path="/sbs" element={loading ? (<Loading />) : (<SBS />)} />
            <Route path="/sg" element={loading ? (<Loading />) : (<SG />)} />
            <Route path="/prayer" element={loading ? (<Loading />) : (<Prayer />)} />
            <Route path="/bible" element={loading ? (<Loading />) : (<Bible />)} />
            <Route path="/confession" element={loading ? (<Loading />) : (<Confession />)} />
            <Route path="/maps" element={loading ? (<Loading />) : (<Maps />)} />
            <Route path="/notification" element={loading ? (<Loading />) : (<Notification />)} />
            <Route path="/under-development" element={<UnderDevelopment />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </CSSTransition>
    </TransitionGroup>
  );
};

const AppRoutes: React.FC = () => {
  return (
    <BrowserRouter>
      <RouteComponent />
      <Footer />
    </BrowserRouter>
  );
};

export default AppRoutes;
