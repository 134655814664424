import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: 100vh;

  /* For w: 375px; h: 667px */
  @media only screen and (max-height: 667px) {
    .signup {
      margin-top: 160px !important;
      transform: scale(0.65) !important;
    }
    .login {
      transform: scale(0.65) !important;
      margin-top: 170px !important;
    }
  }

  /* For w: 360px; h: 740px */
  @media only screen and (min-height: 668px) and (max-height: 740px) {
    .signup {
      margin-top: 160px !important;
      transform: scale(0.65) !important;
    }
    .login {
      transform: scale(0.65) !important;
      margin-top: 170px !important;
    }
  }

  /* For w: 366px; h: 766px */
  @media only screen and (min-height: 741px) and (max-height: 830px) {
    .signup {
      margin-top: 198px !important;
      transform: scale(0.8) !important;
    }
    .login {
      margin-top: 205px !important;
      transform: scale(0.8) !important;
    }
  }
`;

export const Div = styled.div`
  background-color: var(--color-neutral-5);
  height: 100vh;
  /* top: 150px; */
  /* bottom: 56px; */
  position: relative;
  width: 100%;
  /* margin-inline: 20px; */
  overflow-y: auto;
  @media (max-width: 1024px) {
    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none; /* For Internet Explorer and Edge */
    ::-webkit-scrollbar {
      display: none; /* For Chrome, Safari and Opera */
    }
  }
  header{
      font-size: 28px;
      font-weight: 600;
      color: #232836;
      text-align: center;
  }
  .field input,
  .field button{
      height: 100%;
      width: 100%;
      border: none;
      font-size: 16px;
      font-weight: 400;
      border-radius: 6px;
  }
  .field input{
      outline: none;
      padding: 0 15px;
      border: 1px solid#CACACA;
  }
  .field input:focus{
      border-bottom-width: 2px;
  }
  .eye-icon{
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
      font-size: 18px;
      color: #2a2b31;
      cursor: pointer;
      padding: 5px;
  }
  .field button{
      color: #fff;
      background-color: #0171d3;
      transition: all 0.3s ease;
      cursor: pointer;
  }
  .field button:hover{
      background-color: #016dcb;
  }
  .form-link{
      text-align: center;
      margin-top: 10px;
  }
  .form-link span,
  .form-link a{
      font-size: 14px;
      font-weight: 400;
      // color: #232836;
  }
  .line{
      position: relative;
      height: 1px;
      width: 100%;
      margin: 36px 0;
      background-color: #d4d4d4;
  }
  .line::before{
      content: 'Or';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: #FFF;
      color: #8b8b8b;
      padding: 0 15px;
  }
  .media-options a{
      display: flex;
      align-items: center;
      justify-content: center;
  }
`;

export const Overlap = styled.div`
  top: 0;
  /* margin-top: 20px; */
  left: 0;
  width: 100%;
  /* min-width: 450px; */
  /* transform: scale(1.5); */
  height: 260px;
  position: fixed;
  border-radius: 75% / 0 0 100% 100%;
  @media (min-width: 1024px) {
    height: 150px !important;
    border-radius: 50% / 0 0 100% 100% !important;
  }
  display: block;
  background-color: var(--color-neutral-2);
  opacity: 0.95;
`;

export const Vector = styled.img`
  height: 65px;
  left: calc(50% - 115px);
  position: fixed;
  top: 60px;
  width: 230px;
  @media (min-width: 1024px) {
    top: 23px !important;
  }
`;

export const Name = styled.p`
  color: #0e5ca0;
  font-family: 'Nunito Sans-BoldItalic', Helvetica;
  font-size: 14px;
  font-style: italic;
  font-weight: 700;
  height: 23px;
  /* left: calc(50% - 151px); */
  letter-spacing: 0;
  line-height: normal;
  position: fixed;
  text-align: center;
  top: 130px;
  @media (min-width: 1024px) {
    top: 93px !important;
  }
  scale: 0.75;
  width: 100%;
`;

export const Section = styled.section`
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 30px;
  background-color: #1D2024;
`;

export const Form = styled.form`
  position: fixed;
  max-width: 350px;
  width: 100%;
  padding: 30px;
  border-radius: 6px;
  background: #FFF;
  margin-top: 250px;
  scale: 0.85;
  
  @media only screen and (min-width: 1024px) {
    scale: 1 !important;
  }

  a{
    color: #0171d3;
    text-decoration: none;
  }
  form {
    margin-bottom: 10px;
  }
  .field{
    position: relative;
    height: 50px;
    width: 100%;
    margin-top: 20px;
    border-radius: 6px;
  }
`;

export const ProfileForm = styled.form`
  img {
    width: 100%;
    max-width: 331px;
    aspect-ratio: 4 / 3;
    margin-top: 15px;
    padding: 20px;
  }
  .imageInput {
    display: flex;
    input {
      max-width: 229px;
      padding: 6px;
      max-height: 40px;
      border: 1px solid #ced4da;
      border-radius: 3px;
      color: var(--color-neutral-6);
    }
    button {
      display: block;
      height: 40px;
      width: 81px;
      padding: 10px;
      margin-left: 15px;
      background-color: var(--color-primary-2);
      color: white;
      border: none;
      border-radius: 5px;
      cursor: pointer;
    }
  }
  i {
    width: fit-content;
    transform: translate(290px, -38px);
    svg {
      float: right;
      color: var(--color-neutral-6);
    }
  }
  .grecaptcha-badge {
    position: relative !important;
    bottom: 0px !important;
    right: -5px !important;
    margin-inline: auto;
  }
`;

export const Content = styled.div`
  a:hover{
      text-decoration: underline;
  }
`;

export const SocialButtonCollection = styled.div`
  width: 100%;
  padding-inline: 55px;
  .twitter {
    transform: translate(0px, -3px) !important;
  }
`;

export const SocialButton = styled.button`
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin: 5px;
  cursor: pointer;
  background-color: var(--color-neutral-2);
  border: 1px solid var(--color-neutral-3);
  svg {
    transform: translate(0px, 3px);
  }
`;

export const Header = styled.div`
  background-color: var(--color-primary-2);
  height: 136px;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2;
`;

export const Text = styled.div`
  width: 100%;
  text-align: center;
  color: var(--color-neutral-2);
  font-family: 'Popins', sans-serif;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: -0.17px;
  line-height: 18px;
  position: fixed;
  top: 21px;
`;

export const Text2 = styled.div`
  color: var(--color-neutral-6);
  font-family: 'Popins', sans-serif;
  font-size: 15px;
  font-weight: 600;
  padding-top: 2px;
  span {
    color: var(--color-neutral-2) !important;
  }
`

export const TextTitle = styled.div`
  width: 100%;
  text-align: center;
  color: var(--color-neutral-2);
  font-family: 'Popins', sans-serif;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.17px;
  line-height: 18px;
  position: relative;
  margin: 59px auto;
`;

export const ProfilePicture = styled.div`
  position: relative;
  width: 140px;
  height: 140px;
  margin-inline: auto;
  top: 47px;
  border-radius: 50%;
  background-color: var(--color-primary-3);
  img {
    width: 132px;
    height: 132px;
    border-radius: 50%;
    top: 4px;
    left: 4px;
    position: relative;
  }
`;

export const Body = styled.div`
  position: relative;
  top: 136px;
  height: calc(100vh - 192px);
  padding-inline: 25px;
  overflow: auto;
  form {
    position: relative;
    top: 60px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-radius: 8px;
    font-family: 'Nunito Sans', sans-serif !important;
  }
  @media (max-width: 1024px) {
    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none; /* For Internet Explorer and Edge */
    ::-webkit-scrollbar {
      display: none; /* For Chrome, Safari and Opera */
    }
  }
`;

export const Title = styled.div`
  color: var(--color-neutral-6);
  display: flex;
  align-items: center;
  margin: 10px 0;
  font-size: 16px;
  font-weight: 600;
`;

export const Input = styled.input`
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ced4da;
`;

export const Button = styled.button<{ width?: string }>`
  width: ${({width}) => width || '100%'};
  display: block;
  height: 33px;
  margin-block: 20px;
  background-color: var(--color-primary-2);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
`;

export const OptionText = styled.span`
  font-size: 16px;
`;

export const Frame = styled.iframe`
  width: 100%;
  height: calc(100vh - 56px);
  overflow: hidden;
`;

export const Pill = styled.button`
  position: relative;
  border: 2px solid var(--color-primary-2);
  border-radius: 500px;
  width: 250px;
  height: 48px;
  overflow: hidden;
  /* background-color: transparent; */
  text-transform: uppercase;
  color: var(--color-primary-2);
  font-size: 14px;
  font-family: "Rubik", sans-serif;
  font-weight: 700;
  margin-bottom: 10px;
` ;

export const TextArea = styled.textarea`
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ced4da;
  min-height: 240px;
  min-width: 100%;
  max-width: 100%;
  font-family: 'Nunito Sans', sans-serif !important;
  font-size: 16px;
`;
