import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getFirestore, Timestamp as ts, GeoPoint as gp } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import {
  getAuth,
  GoogleAuthProvider,
  FacebookAuthProvider,
  TwitterAuthProvider,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInWithPopup,
  updateProfile,
  EmailAuthProvider,
  updateEmail,
  updatePassword,
  sendPasswordResetEmail,
  sendEmailVerification,
  reauthenticateWithCredential,
  signOut,
  RecaptchaVerifier, 
  updatePhoneNumber,
  PhoneAuthProvider,
} from "firebase/auth";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const auth = getAuth(app);
export const Timestamp = ts;
export const GeoPoint = gp;

initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider('6LfRUCYqAAAAAF8KOLhM3d527FzLp-V2ryU4dKwI'),
  isTokenAutoRefreshEnabled: true // Optional, if you want to automatically refresh the token.
});

// Authentication related functions
export const createUser = async (email, password) => {
  const userCredential = await createUserWithEmailAndPassword(auth, email, password);
  // User is successfully created. Now, send an email verification.
  await sendEmailVerification(userCredential.user)
    .then(() => {
      console.log("Verification email sent.");
    })
    .catch((error) => {
      console.error("Error sending verification email: ", error);
    });
};

export const signIn = async (email, password) => {
  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    return userCredential;
  } catch (error) {
    console.error("Error signing in: ", error);
    if (error.code === 'auth/wrong-password') {
      alert('Senha incorreta. Por favor, tente novamente.');
    } else if (error.code === 'auth/user-not-found') {
      alert('Nenhum usuário encontrado com este e-mail.');
    } else {
      alert('Ocorreu um erro inesperado. Por favor, tente novamente mais tarde.');
    }
    throw error; // Re-throw the error after handling it
  }
};

export const signInWithGoogle = async () => {
  const provider = new GoogleAuthProvider();
  return await signInWithPopup(auth, provider);
};

export const signInWithFacebook = async () => {
  const provider = new FacebookAuthProvider();
  return await signInWithPopup(auth, provider);
};

export const signInWithTwitter = async () => {
  const provider = new TwitterAuthProvider();
  return await signInWithPopup(auth, provider);
};

export const resetPassword = async (email) => {
  return await sendPasswordResetEmail(auth, email);
};

export const logOut = async () => {
  const confirmLogout = confirm("Tem certeza de que deseja sair?");
  if (confirmLogout) {
    try {
      await signOut(auth);
      console.log("User signed out successfully.");
    } catch (error) {
      console.error("Error signing out: ", error);
      alert("Ocorreu um erro ao sair. Tente novamente.");
    }
  }
};

export const updateUserProfile = (user, data) => updateProfile(user, data);

export const updateUserEmail = (user, newEmail) => {
  const userPassword = prompt('Insira sua senha para autorizar a mudança de email:');
  const credential = EmailAuthProvider.credential(
    user.email,
    userPassword
  );
  reauthenticateWithCredential(user, credential).then(() => {
    updateEmail(user, newEmail).then(() => {
      sendEmailVerification(user);
      console.log('Email updated successfully');
    })
    .catch((error) => {
      console.error('Error updating email:', error);
    });
  }).catch((error) => {
    console.error('Error updating email:', error);
  });
}

export async function updateUserPassword(user, newPassword) {
  try {
      const enteredPassword = prompt("Introduza a sua palavra-passe atual:");
      const credential = EmailAuthProvider.credential(user.email, enteredPassword);

      reauthenticateWithCredential(user, credential).then(() => {
        updatePassword(user, newPassword).then(() => {
          console.log("Password updated successfully.");
        })
        .catch((error) => {
          console.error("Error updating password: ", error);
        });
      }).catch((error) => {
        console.error("Error during re-authentication: ", error);
      });
      console.log("Reauthentication successful.");
  } catch (error) {
      console.error("Error during re-authentication: ", error.message);
  }
}

export const uploadUserPhoto = async (id, photoFile) => {
  const photoRef = ref(storage, `users/${id}/${photoFile.name}`);
  await uploadBytes(photoRef, photoFile);
  return getDownloadURL(photoRef);
};

export const updateUserPhoneNumber = async (phoneNumber) => {
  try {
      // Initialize Firebase authentication (make sure auth is properly set up)
      const recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
        size: 'normal',
        callback: () => {
          console.log('reCAPTCHA solved, allow:', response);
        }
      });

      recaptchaVerifier.verify();

      // Verify phone number
      const provider = new PhoneAuthProvider(auth);
      const verificationId = await provider.verifyPhoneNumber(phoneNumber, recaptchaVerifier);

      recaptchaVerifier.clear();

      // Prompt user to enter the verification code
      const userEnteredCode = prompt('Insira o código de verificação recebido');

      // Create phone credential
      const credential = PhoneAuthProvider.credential(verificationId, userEnteredCode);

      // Update user's phone number
      await updatePhoneNumber(auth.currentUser, credential);

      console.log('Phone number updated successfully.');
  } catch (error) {
      console.error('Error updating phone number:', error.message);
  }
};

// const getUserByPhoneNumber = async (phoneNumber) => {
//   try {
//     const userRecord = await queryUserDatabase(phoneNumber);
//     if (userRecord) {
//       const email = userRecord.email;
//       return { email, phoneNumber };
//     } else {
//       console.log('User not found for the provided phone number.');
//       return null;
//     }
//   } catch (error) {
//     console.error('Error retrieving user by phone number:', error);
//     return null;
//   }
// };

// export const signInWithPhoneNumber = () => {
//   getUserByPhoneNumber(phoneNumber)
//     .then(async (user) => {
//       if (user) {
//         return await signInWithEmailAndPassword(auth, user.email, user.password);
//       } else {
//         console.log('User not found for the provided phone number.');
//       }
//     });
// }