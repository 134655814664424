import React from 'react';
import { Container, Frame } from './styles';

const Bible: React.FC = () => {
  // Construct the path to the HTML file
  const bibleUrl = `${process.env.PUBLIC_URL}/bibles/bible.html`;

  return (
    <Container>
      <Frame src={bibleUrl} title="Bible Content" />
    </Container>
  );
};

export default Bible;
