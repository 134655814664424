import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from "../../firebaseConfig";
import { Box, Tabs, Tab, Typography, Grid, TextField, IconButton, Modal } from '@mui/material';
import { BackButton, Pill, VideoPage } from 'components';
import { Container, ContentContainer, Header, MenuContent, Name, Text, Button } from './styles';
import { useAuth } from 'providers/AuthProvider';
import { v4 as uuidv4 } from "uuid";
import { FaTrash } from 'react-icons/fa';

interface Class {
  id: string;
  name: string;
  description: string;
  teachers: string;
  leasons: string;
  classes: string;
}

interface School {
  time: string;
  classes: Class[];
}

const initialClass: Class = {
  id: uuidv4(),
  name: "",
  description: "",
  teachers: "",
  leasons: "",
  classes: ""
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{ height: 'calc(100% - 68px)' }}
    >
      {value === index && (
        <Box style={{ height: '100%' }}>
          <Typography style={{ fontFamily: 'Nunito, sans-serif', height: '100%' }}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const SBS: React.FC = () => {
  const { isAdmin } = useAuth();
  const navigate = useNavigate();
  
  const location = useLocation();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    navigate(`?tab=${newValue}`);
  };

  const [modalOpen, setModalOpen] = useState(false);
  const [currentClass, setCurrentClass] = useState<any>();

  const handleOpen = (c) => {
    setModalOpen(true);
    setCurrentClass(c);
  };

  const handleClose = () => {
    setModalOpen(false);
    setCurrentClass('');
  };

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    padding: '16px',
    width: '350px',
    outline: 'none',
    borderRadius: '8px',
  };
  
  const [school, setSchool] = useState<School>({ time: "", classes: [initialClass] });

  useEffect(() => {
    const fetchSchoolData = async () => {
      const schoolDoc = doc(db, "schools", "yDwOFQyTu8ODNUGR71wM");
      const docSnap = await getDoc(schoolDoc);

      if (docSnap.exists()) {
        const data = docSnap.data();
        setSchool(data as School);
      }
    };

    fetchSchoolData();
  }, []);

  const handleInputChange = (e, idx, field) => {
    const { value } = e.target;
    const updatedSchool = { ...school };
    if (field === "time") {
      updatedSchool.time = value;
    } else {
      updatedSchool.classes[idx][field] = value;
    }
    setSchool(updatedSchool);
  };

  const handleAddClass = () => {
    const updatedSchool = { ...school };
    updatedSchool.classes.push({ ...initialClass, id: uuidv4() }); // Adding a new class
    setSchool(updatedSchool);
  };

  const handleDeleteClass = (idx) => {
    const updatedSchool = { ...school };
    updatedSchool.classes.splice(idx, 1); // Removing a class
    setSchool(updatedSchool);
  };

  const handleSubmit = async () => {
    if (!school) return;
    const schoolDoc = doc(db, "schools", "yDwOFQyTu8ODNUGR71wM");
    await updateDoc(schoolDoc, { time: school.time, classes: school.classes });
    alert("EBD atualizada com sucesso!");
  };

  const IFrame = () => {
    const [isLoading, setIsLoading] = useState(true);
    
    return (
      <>
        {
          isLoading && 
          <Typography style={{ position: 'absolute', top: '50%', left: 'calc(50% - 160px)', textAlign: 'center', fontFamily: 'Nunito, sans-serif' }}>
            Carregando...
            <br />
            Se demorar de mais pode acessar <a href="https://fliphtml5.com/bookcase/undew" target="_blank">por aqui!</a>
          </Typography>
        }
        <iframe
          src="https://fliphtml5.com/bookcase/undew"
          seamless
          scrolling="no"
          frameBorder="0"
          allowTransparency
          allowFullScreen
          onLoad={() => setIsLoading(false)}
        />
      </>
    );
  };

  const style = {
    position: 'relative',
    height: 'calc(100% - 56px)',
    overflow: 'auto',
  };

  useEffect(() => {
    const currentTab = new URLSearchParams(location.search).get("tab");
    if (currentTab && parseInt(currentTab, 10) !== value) {
      setValue(parseInt(currentTab, 10));
    }
  }, [location.search, value]);

  return (
    <Container>
      <Header>
        <Text>Escola Discipuladora</Text>
      </Header>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', marginTop: '68px' }}>
        <Tabs value={value} onChange={handleChange} centered={!isAdmin} variant={isAdmin ? "scrollable" : "fullWidth"}>
          <Tab label="Turmas" {...a11yProps(0)} />
          <Tab label="Formação" {...a11yProps(1)} />
          <Tab label="Biblioteca" {...a11yProps(2)} />
          {isAdmin && <Tab label="Detalhes" {...a11yProps(3)} />}
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <Name><div>Horário: </div><div>{school.time}</div></Name>
        <ContentContainer>
          <MenuContent>
          {school && (
            school.classes.map((c, index) => (
              <div onClick={() => handleOpen(c)}>
                <Pill
                  key={index} // Add a unique key for each item
                  disableLink
                  mode2
                  imageKey="EBD"
                  title={c.name}
                  text={c.description}
                />
              </div>
            ))
          )}
          </MenuContent>
          <Modal
            open={modalOpen}
            onClose={handleClose}
            aria-labelledby="class-info-title"
            aria-describedby="class-info-description"
          >
            <Box sx={modalStyle}>
              {currentClass && (
                <div>
                  <Typography style={{ fontFamily: 'Nunito, sans-serif' }} variant="h6" id="class-info-title">
                    <b>{currentClass.name}</b>
                  </Typography>
                  <Typography style={{ fontFamily: 'Nunito, sans-serif' }}>
                    <b>Professores:</b> {currentClass.teachers}
                  </Typography>
                  <Typography style={{ fontFamily: 'Nunito, sans-serif' }}>
                    <b>Estudo do quadrimestre:</b> {currentClass.leasons}
                  </Typography>
                  <Typography style={{ fontFamily: 'Nunito, sans-serif' }}>
                    <b>Lição da semana:</b> {currentClass.classes}
                  </Typography>
                </div>
              )}
            </Box>
          </Modal>
        </ContentContainer>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <VideoPage />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <IFrame />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        <Box sx={style}>
          <Typography variant="h5" style={{ width: '350px', marginInline: 'auto', marginTop: '15px', fontFamily: 'Nunito, sans-serif' }}>Editar Dados da EBD</Typography>
          {school ? (
            <Box sx={{ width: '350px', margin: 'auto' }}>
              <br />
              <Grid style={{ maxWidth: '100%' }} item xs={12} md={4}>
                <TextField
                  fullWidth
                  label="Horário"
                  value={school.time}
                  onChange={(e) => handleInputChange(e, '', "time")}
                  />
              </Grid>
              <br />
              {school.classes.map((cls, idx) => (
                  <div style={{ marginBlock: '15px' }}>
                    <Grid container spacing={2} style={{ display: 'block' }}>
                      <Grid style={{ maxWidth: '100%' }} item xs={12} md={4}>
                        <TextField
                          fullWidth
                          label="Turma"
                          value={cls.name}
                          onChange={(e) => handleInputChange(e, idx, "name")}
                        />
                      </Grid>
                      <Grid style={{ maxWidth: '100%' }} item xs={12} md={4}>
                        <TextField
                          fullWidth
                          label="Faixa Etária"
                          value={cls.description}
                          onChange={(e) => handleInputChange(e, idx, "description")}
                        />
                      </Grid>
                      <Grid style={{ maxWidth: '100%' }} item xs={12} md={4}>
                        <TextField
                          fullWidth
                          label="Professores"
                          value={cls.teachers}
                          onChange={(e) => handleInputChange(e, idx, "teachers")}
                        />
                      </Grid>
                      <Grid style={{ maxWidth: '100%' }} item xs={12} md={4}>
                        <TextField
                          fullWidth
                          label="Estudo (quadrimeste)"
                          value={cls.leasons}
                          onChange={(e) => handleInputChange(e, idx, "leasons")}
                        />
                      </Grid>
                      <Grid style={{ maxWidth: '100%' }} item xs={12} md={4}>
                        <TextField
                          fullWidth
                          label="Lição (semanal)"
                          value={cls.classes}
                          onChange={(e) => handleInputChange(e, idx, "classes")}
                        />
                      </Grid>
                      <Grid style={{ maxWidth: '100%' }} item xs={12} md={4}>
                        <IconButton color="error" onClick={() => handleDeleteClass(idx)}>
                          <FaTrash color='#C11E0F' size='24px' />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </div>
              ))}
              <Button onClick={handleAddClass}>
                Adicionar Turma
              </Button>
              <Button onClick={handleSubmit}>
                Salvar Mudanças
              </Button>
            </Box>
          ) : (
            <Typography style={{ fontFamily: 'Nunito, sans-serif' }}>Carregando...</Typography>
          )}
        </Box>
      </CustomTabPanel>
      <BackButton goBack={() =>  navigate(-1)} />
    </Container>
  );
};

export default SBS;
