import React, { useState } from 'react';
import { Modal, Box, Typography, IconButton } from '@mui/material';
import { IoIosClose } from "react-icons/io";
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

interface MarkdownModalProps {
  open: boolean;
  handleClose: () => void;
  text: string;
}

const MarkdownModal: React.FC<MarkdownModalProps> = ({ open, handleClose, text }) => {
  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={{ 
        position: 'absolute', 
        top: '50%', 
        left: '50%', 
        transform: 'translate(-50%, -50%)', 
        borderRadius: 4,
        width: 350, 
        height: 500,
        overflow: 'auto',
        bgcolor: 'background.paper', 
        boxShadow: 24, 
        p: 4
      }}>
        <IconButton 
          aria-label="close" 
          onClick={handleClose} 
          sx={{ position: 'absolute', right: 8, top: 8 }}
        >
          <IoIosClose />
        </IconButton>
        <Typography component="div" variant="body1">
          <ReactMarkdown rehypePlugins={[rehypeRaw]}>{text}</ReactMarkdown>
        </Typography>
      </Box>
    </Modal>
  );
};

export default MarkdownModal;
